import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {MainComponent} from '@modules/main/main.component';
import {BlankComponent} from '@pages/blank/blank.component';
import {LoginComponent} from '@modules/login/login.component';
import {ProfileComponent} from '@pages/profile/profile.component';
import {RegisterComponent} from '@modules/register/register.component';
import {DashboardComponent} from '@pages/dashboard/dashboard.component';
import {AuthGuard} from '@guards/auth.guard';
import {NonAuthGuard} from '@guards/non-auth.guard';
import {ForgotPasswordComponent} from '@modules/forgot-password/forgot-password.component';
import {RecoverPasswordComponent} from '@modules/recover-password/recover-password.component';
import {SubMenuComponent} from '@pages/main-menu/sub-menu/sub-menu.component';
import { CreateInteractSessionsComponent } from '@modules/create-interact-sessions/create-interact-sessions.component';
import {AttendeesRegisterComponent} from "@modules/attendees-register/attendees-register.component";
import { HomeComponent } from '@modules/home/home.component';
import {AttendeesScreensComponent} from "@modules/attendees-screens/attendees-screens.component";
import {SessionComponent} from "@modules/session/session.component";
import {ProjectorDisplayComponent} from "@modules/projector-display/projector-display.component";
import {SessionTableComponent} from "@modules/session-table/session-table.component";
import { HomePageComponent } from '@modules/home_page/home-page.component';
import { TermsComponent } from '@modules/terms-and-conditions/terms.component';
import { PrivacyPolicyComponent } from '@modules/privacy-policy/privacy-policy.component';
import { AppUseCaseComponent } from '@modules/app-use-case/app-use-case.component';

const routes: Routes = [
  {
    path: 'main',
    component: MainComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: 'profile',
        component: ProfileComponent
      },
      {
        path: 'blank',
        component: BlankComponent
      },
      {
        path: 'sub-menu-1',
        component: SubMenuComponent
      },
      {
        path: 'sub-menu-2',
        component: BlankComponent
      },
      {
        path: 'dashboard',
        component: DashboardComponent
      },
      {
        path: 'new-interact-session',
        component: CreateInteractSessionsComponent,
      },
      {
        path: 'session',
        component: SessionComponent
      },
      {
        path: 'session-table',
        component: SessionTableComponent
      }
    ]
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [NonAuthGuard]
  },
  {
    path: 'register',
    component: RegisterComponent,
    canActivate: [NonAuthGuard]
  },
  {
    path: 'audience-register',
    component: AttendeesRegisterComponent,
    canActivate:[NonAuthGuard]
  },
  {
    path: 'attendees-screen',
    component: AttendeesScreensComponent,
    canActivate:[NonAuthGuard]
  },
  {
    path: 'projector-display',
    component: ProjectorDisplayComponent,
    canActivate:[NonAuthGuard]
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
    canActivate: [NonAuthGuard]
  },
  {
    path: 'recover-password',
    component: RecoverPasswordComponent,
    canActivate: [NonAuthGuard]
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [NonAuthGuard]
  },
  {
    path: 'home-page',
    component: HomePageComponent,
    canActivate: [NonAuthGuard]
  },
  {
    path: 'app-usecase',
    component: AppUseCaseComponent,
    canActivate: [NonAuthGuard]
  },
  {
    path: 'terms',
    component: TermsComponent,
    canActivate: [NonAuthGuard]
  },
  {
    path: 'privacy',
    component: PrivacyPolicyComponent,
    canActivate: [NonAuthGuard]
  },
  {path: '**', redirectTo: 'home-page'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
