<nav class="navbar navbar-expand-lg navbar-light" style="background-color:rgb(228, 226, 226)">
    <div class="container-fluid">
      <a class="navbar-brand"><h4>Interact</h4></a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse mx-4 topnav" id="navbarSupportedContent" >
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <a class="nav-link" routerLink="" style="color: rgb(77, 144, 245);">Features</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="" style="color: rgb(77, 144, 245);">pricing</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="" style="color: rgb(77, 144, 245);">community</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="" style="color: rgb(77, 144, 245);">Support</a>
          </li>
          <!-- <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              Dropdown
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <li><a class="dropdown-item" href="#">Action</a></li>
              <li><a class="dropdown-item" href="#">Another action</a></li>
              <li><hr class="dropdown-divider"></li>
              <li><a class="dropdown-item" href="#">Something else here</a></li>
            </ul>
          </li> -->
        </ul>
        <form class="d-flex">
            <button type="button" class="btn btn-outline-primary me-md-3" (click)="login()">Login</button>
            <button class="btn btn-primary" type="button" (click)="register()"> Register </button>
        </form>
      </div>
    </div>
  </nav>
 
  <div class="container-fluid mt-4">
    <div class="card" style="height: 500px; background-color: gray;">
        <div class="row mt-5"></div>
        <div class="row mt-5"></div>
        <div class="row mt-5"></div>
        <div class="text-center">
            <img src="../../../assets/img/logo.png" class="rounded" alt="...">
          </div>
          <div class="text-center mt-4">
            <button type="button" class="btn-primary btn-lg">Join Test</button>
    </div>
    </div>
    <div class="row mt-4 col-md-12">
        <div class="col-md-6 ms-3">
            <h1><b>snap photo and share like <br> never before</b></h1>
        </div>
    </div>
      <div id="carouselExampleControls" class="carousel slide carousel-fade" data-bs-ride="carousel">
          <div class="carousel-indicators">
              <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
              <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
              <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
          </div>
          <div class="carousel-inner">
              <div class="carousel-item active">
                  <img src="./assets/img/slider4.jpg" class="d-block w-100" alt="...">
              </div>
              <div class="carousel-item">
                  <img src="./assets/img/slider2.jpeg" class="d-block w-100" alt="...">
              </div>
              <div class="carousel-item">
                  <img src="./assets/img/slider3.jpeg" class="d-block w-100" alt="...">
              </div>
          </div>
          <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Previous</span>
          </button>
          <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Next</span>
          </button>
      </div>
    <div class="row mt-4">
        <div class="col-md-5 ms-4">
            <h4><b>Sed ut perspiciatis</b></h4>
            <p class="mt-2">Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit,<br>
                 sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. <br>
                 Neque porro quisquam est.</p>
                 <button type="button" class="btn btn-outline-primary me-md-3">Learn more</button>
        </div>
        <div class="col-md-1"></div>
        <div class="col-md-5 ms-4">
            <h4><b>Sed ut perspiciatis</b></h4>
            <p class="mt-2">Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit,<br>
                 sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. <br>
                 Neque porro quisquam est.</p>
            <button type="button" class="btn btn-outline-primary me-md-3">Learn more</button>
        </div>
    </div>
    <div class="row mt-4">
        <div class="col-md-5 ms-4">
            <h4><b>Sed ut perspiciatis</b></h4>
            <p class="mt-2">Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit,<br>
                 sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. <br>
                 Neque porro quisquam est.</p>
                 <button type="button" class="btn btn-outline-primary me-md-3">Learn more</button>
        </div>
        <div class="col-md-1"></div>
        <div class="col-md-5 ms-4">
            <h4><b>Sed ut perspiciatis</b></h4>
            <p class="mt-2">Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit,<br>
                 sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. <br>
                 Neque porro quisquam est.</p>
                 <button type="button" class="btn btn-outline-primary me-md-3">Learn more</button>
        </div>
    </div>
    <div class="row mt-4"></div>
    <div class="row mt-5">
        <div class="col-md-5 ms-2">
          <div class="card">
              <img src="/assets/img/slider2.jpeg" class="img-fluid">
          </div>
        </div>
        <div class="col-md-1"></div>
        <div class="col-md-5 ms-5">
            <h4 class="mt-5"><b>Sed ut perspiciatis</b></h4>
            <p class="mt-2">Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit,<br>
                 sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. <br>
                 Neque porro quisquam est.</p>
                 <button type="button" class="btn btn-outline-primary me-md-3">Learn more</button>
        </div>
    </div>
    <div class="row mt-4"></div>
    <div class="row mt-5">
    <div class="container-fluid" style="background-color:#F5F5F5">
        <div class="row mt-5">
            <div class="col-md-5 ms-4">
                <h4 class="mt-5"><b>Sed ut perspiciatis</b></h4>
                <p class="mt-2">Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit,<br>
                     sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. <br>
                     Neque porro quisquam est.</p>
                     <button type="button" class="btn btn-outline-primary me-md-3">Learn more</button>
            </div>
            <div class="col-md-1"></div>
            
            <div class="col-md-5 ms-2 mt-1">
                <div class="card">
                    <img src="/assets/img/slider4.jpg" class="img-fluid">
                </div>
              </div>
        </div>
    </div>
        </div>
        <div class="row mt-4"></div>
    <div class="row text-center mt-5">
        <h3>You’re in good company</h3>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor <br>
            incididunt ut labore</p>
        
        <!-- <div class="row mt-3">
            <div class="col-md-2"></div>
            <div class="col-md-8">
                    <button type="button" class="btn btn-secondary btn-lg ms-3">Large button</button>
                    <button type="button" class="btn btn-secondary btn-lg ms-3">Large button</button>
                    <button type="button" class="btn btn-secondary btn-lg ms-3">Large button</button>
                    <button type="button" class="btn btn-secondary btn-lg ms-3">Large button</button>
            </div>
        </div> -->
        <div class="row mt-3">
            <div class="col-md-3"></div>
            <div class="col-md-8 ms-3">
                <div class="row">
                    <div class="card col-md-2 ms-3">
                        <img src="../../../assets/img/logo.png" style="height: 40px; width: 20px;">
                    </div>
                    <div class="card col-md-2 ms-3" >
                        <img src="../../../assets/img/logo.png" style="height: 40px; width: 20px;">
                    </div>
                    <div class="card col-md-2 ms-3">
                        <img src="../../../assets/img/logo.png" style="height: 40px; width: 20px;">
                    </div>
                    <div class="card col-md-2 ms-3">
                        <img src="../../../assets/img/logo.png" style="height: 40px; width: 20px;">
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-md-3"></div>
            <div class="col-md-8 ms-3">
                <div class="row">
                    <div class="card col-md-2 ms-3">
                        <img src="../../../assets/img/logo.png" style="height: 40px; width: 20px;">
                    </div>
                    <div class="card col-md-2 ms-3" >
                        <img src="../../../assets/img/logo.png" style="height: 40px; width: 20px;">
                    </div>
                    <div class="card col-md-2 ms-3">
                        <img src="../../../assets/img/logo.png" style="height: 40px; width: 20px;">
                    </div>
                    <div class="card col-md-2 ms-3">
                        <img src="../../../assets/img/logo.png" style="height: 40px; width: 20px;">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-4"></div>
    <div class="row mt-5">
    <div class="container-fluid" style="background-color:#F5F5F5">
        <div class="row mt-4">
            <div class="col-md-1"></div>
            <div class="col-md-6 ms-4">
                <h2 class="mt-5 ms-3"><b>Lorem ipsum dolor sit amet, consectetur adipiscing <br>elit, sed do eiusmod tempor incididunt ut labore</b></h2>
                
            </div>
            <div class="col-md-4 mt-5">
                <button type="button" class="btn btn-primary btn-lg ms-3">Join Today</button>
                    <button type="button" class="btn btn-outline-primary btn-lg ms-3">Contact Us</button>
            </div>
        </div>
    </div>
        </div>
        <div class="row mt-4"></div>
  </div>



  <!-- <div class="row mt-4 col-md-12">
    <div class="col-md-1"></div>
    <div class="col-md-10">
    <div class="col-md-6">
        <h1><b>snap photo and share like <br> never before</b></h1>
    </div>
<div class="row mt-4">
    <div class="col-md-6">
        <h4><b>Sed ut perspiciatis</b></h4>
        <p class="mt-2">Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit,<br>
             sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. <br>
             Neque porro quisquam est.</p>
    </div>
    <div class="col-md-6">
        <h4><b>Sed ut perspiciatis</b></h4>
        <p class="mt-2">Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit,<br>
             sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. <br>
             Neque porro quisquam est.</p>
    </div>
</div>
</div>
<div class="col-md-1"></div> -->