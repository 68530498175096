import {Component, HostBinding, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {AppService} from '@services/app.service';
import {ToastrService} from 'ngx-toastr';
import {Subject, takeUntil} from 'rxjs';
import {data} from "browserslist";
import {NgxSpinnerService} from "ngx-spinner";
import {Register} from "@modules/modal/register";
import {ActivatedRoute, Router} from "@angular/router";
import {AudienceRegister} from "@modules/modal/audienceRegister";

@Component({
    selector: 'app-attendees-register',
    templateUrl: './attendees-register.component.html',
    styleUrls: ['./attendees-register.component.scss']
})
export class AttendeesRegisterComponent implements OnInit, OnDestroy {
    @HostBinding('class') class = 'register-box';
    destroy$: Subject<boolean> = new Subject<boolean>();
    password: string;
    isPasswordVisible: boolean = false;
    audienceRegister: AudienceRegister | undefined;
    sessionName: string = "";
    sessionId: number = 0;
    emailModal = false;
    emailVerified = false;
    phoneNoModal = false;
    phoneNoVerified = false;
    firstValue: string = '';
    secondValue: string = '';
    thirdValue: string = '';
    fourthValue: string = '';
    firstValue1: string = '';
    secondValue2: string = '';
    thirdValue3: string = '';
    fourthValue4: string = '';
    email: string = '';
    phoneNo: string = '';

    constructor(
        private renderer: Renderer2,
        private toastr: ToastrService,
        private appService: AppService,
        private router: Router,
        private spinner: NgxSpinnerService,
        private route: ActivatedRoute
    ) {
    }

    ngOnInit() {
        this.route.queryParams.subscribe(params => {
            const sessionDetails = params["session"]
            this.sessionName = sessionDetails.split("_", 1)
            localStorage.setItem('sessionName', this.sessionName)
            const id = sessionDetails.split("_", 2)
            this.sessionId = id[1]
            localStorage.setItem("sessionId", String(this.sessionId))
            this.attendeesRegisterForm.controls["interactSessionId"].setValue(Number(this.sessionId))
            console.log("session name :", this.sessionId)
        })
        this.renderer.addClass(
            document.querySelector('app-root'),
            'register-page'
        );
    }

    attendeesRegisterForm = new UntypedFormGroup({
        audienceName: new UntypedFormControl(null, Validators.required),
        audienceEmailAddress: new UntypedFormControl(null, [Validators.required,Validators.nullValidator, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
        audiencePhoneNumber: new UntypedFormControl(null, Validators.required),
        interactSessionId: new UntypedFormControl(null, Validators.required),
        countryCode: new UntypedFormControl(null, [Validators.required]),
    });

    emailOtp = new UntypedFormGroup({
        email: new UntypedFormControl(null, Validators.required),
    });

    public attendeesRegisterFormErrors = {
        audienceName: "", audienceEmailAddress: "", audiencePhoneNumber: "",countryCode: ""
    }

    onSubmit() {
        this.formValidation();
    }

    formValidation() {
        this.attendeesRegisterFormErrors.audienceName = "";
        this.attendeesRegisterFormErrors.audienceEmailAddress = "";
        this.attendeesRegisterFormErrors.audiencePhoneNumber = "";
        let hasError = false;
        if (this.attendeesRegisterForm.get("audienceName").invalid) {
            this.attendeesRegisterFormErrors.audienceName = "Name is Required";
            hasError = true;
        }
        if (this.attendeesRegisterForm.get("audienceEmailAddress").invalid) {
            this.attendeesRegisterFormErrors.audienceEmailAddress = "Email ID is Required";
            hasError = true;
        }
        if (this.attendeesRegisterForm.get("audiencePhoneNumber").invalid) {
            this.attendeesRegisterFormErrors.audiencePhoneNumber = "Phone Number is Required";
            hasError = true;
        }
        if (this.attendeesRegisterForm.get("audienceEmailAddress")?.invalid) {
            if(this.attendeesRegisterForm.get('audienceEmailAddress').errors.required)
              this.attendeesRegisterFormErrors.audienceEmailAddress = "*Email ID is Required";
              hasError = true
            if (this.attendeesRegisterForm.get('audienceEmailAddress').errors.pattern) {
              this.attendeesRegisterFormErrors.audienceEmailAddress = "*Enter Valid Email.(Ex:example@gmail.com)";
              hasError = true;
          }
          }
       /* if (this.attendeesRegisterForm.get("countryCode").invalid) {
            this.attendeesRegisterFormErrors.countryCode = "Country Code is Required";
            hasError = true;
        }*/

        if (!hasError) {
            this.attendeesRegisterDetails();
        }

    }

    //Only Enter Number Input box Event
    OnlyNumbers($event) {
        let regex: RegExp = new RegExp(/^[0-9]{1,}$/g);
        let specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'ArrowRight', 'ArrowLeft'];
        if (specialKeys.indexOf($event.key) !== -1) {
            return;
        } else {
            if (regex.test($event.key)) {
                return true;
            } else {
                return false;
            }
        }
    }

    attendeesRegisterDetails() {
        if (this.emailVerified) {
            this.spinner.show();
            this.appService
                .saveAudienceRegister(this.attendeesRegisterForm.value)
                .pipe(takeUntil(this.destroy$))
                .subscribe(data => {
                        console.log("Audience Details::", data);
                        this.audienceRegister = data;
                        localStorage.setItem('audienceNo', String(this.audienceRegister.audiencePhoneNumber))
                        this.router.navigate(["attendees-screen"])
                        this.spinner.hide();
                        this.toastr.success("Registered Successfully");
                    },
                    (err: any) => {
                        this.spinner.hide();
                        this.toastr.error("Something went wrong, Please try Again")
                    },
                    () => console.log("HTTP request completed.")
                )
        } else if (!this.emailVerified) {
            this.toastr.error("Email is Not Verified");
        }
    }

    ngOnDestroy() {
        this.renderer.removeClass(
            document.querySelector('app-root'),
            'register-page'
        );
    }

    emailOTP(event) {
        this.email = this.attendeesRegisterForm.get("audienceEmailAddress").value;
        this.attendeesRegisterFormErrors.audienceEmailAddress = '';
        let hasError = false;

        if (this.attendeesRegisterForm.get("audienceEmailAddress")?.invalid) {
            if(this.attendeesRegisterForm.get('audienceEmailAddress').errors.required)
              this.attendeesRegisterFormErrors.audienceEmailAddress = "*Email Address is required";
            hasError = true
            if (this.attendeesRegisterForm.get('audienceEmailAddress').errors.pattern) {
              this.attendeesRegisterFormErrors.audienceEmailAddress = "*Enter Valid Email.(Ex:example@gmail.com)";
              hasError = true;
            }
          }
        if (!hasError) {
            this.emailOtp.controls['email'].setValue(this.attendeesRegisterForm.get('audienceEmailAddress').value)
            this.emailModal = true;
            return this.appService.sendEmailOtp(this.emailOtp.value)
                .pipe(takeUntil(this.destroy$))
                .subscribe(data => {
                    console.log("send otp :", data)
                }, (err: any) => {
                    console.log("something went wrong :", err)
                })
        }
    }

    phoneNoOTP(event) {
        this.phoneNo = this.attendeesRegisterForm.get("audiencePhoneNumber").value;
        this.attendeesRegisterFormErrors.audiencePhoneNumber = '';
        let hasError = false;

        if (this.attendeesRegisterForm.get("audiencePhoneNumber")?.invalid) {
            this.attendeesRegisterFormErrors.audiencePhoneNumber = "Enter Your Valid Phone Number";
            hasError = true;
        }

        if (this.attendeesRegisterForm.get("audiencePhoneNumber")?.value.length != 10) {
            this.attendeesRegisterFormErrors.audiencePhoneNumber = "Enter Your Valid Phone Number";
            hasError = true;
        }

        if (!hasError) {
            this.phoneNoModal = true;
            return this.appService.sendPhoneNoOtp(this.attendeesRegisterForm.value)
                .pipe(takeUntil(this.destroy$))
                .subscribe(data => {
                    console.log("send otp :", data)
                }, (err: any) => {
                    console.log("something went wrong :", err)
                })
        }
    }

    moveFocus(nextInput: string) {
        const nextInputField = document.getElementById(nextInput) as HTMLInputElement;
        if (nextInputField) {
            nextInputField.focus();
        }
    }

    emailOtpVerify() {

        let hasError = false;

        if (this.firstValue == "") {
            this.toastr.error("Please enter OTP")
            hasError = true;
        }
        if (this.secondValue == "") {
            this.toastr.error("Please enter OTP")
            hasError = true;
        }
        if (this.thirdValue == "") {
            this.toastr.error("Please enter OTP")
            hasError = true;
        }
        if (this.fourthValue == "") {
            this.toastr.error("Please enter OTP")
            hasError = true;
        }
        if (!hasError) {
            this.spinner.show();
            const otp = this.firstValue + this.secondValue + this.thirdValue + this.fourthValue
            console.log("otp verified..!", otp)
            return this.appService.verifyEmailOtp(this.attendeesRegisterForm.get("audienceEmailAddress").value, otp)
                .pipe(takeUntil(this.destroy$))
                .subscribe(data => {
                    console.log("send otp :", data)
                    this.spinner.hide()
                    if (data.statusCode == 200) {
                        this.emailModal = false;
                        this.emailVerified = true;
                        this.toastr.success("OTP Verified..!")
                    } else if (data.statusCode == 500) {
                        this.toastr.error("OTP Not Matched")
                    }
                }, (err: any) => {
                    this.spinner.hide()
                    console.log("something went wrong :", err)
                })
        }
    }

    phoneNoOtpVerify() {
        let hasError = false;
        if (this.firstValue1 == "") {
            this.toastr.error("Please enter OTP")
            hasError = true;
        }
        if (this.secondValue2 == "") {
            this.toastr.error("Please enter OTP")
            hasError = true;
        }
        if (this.thirdValue3 == "") {
            this.toastr.error("Please enter OTP")
            hasError = true;
        }
        if (this.fourthValue4 == "") {
            this.toastr.error("Please enter OTP")
            hasError = true;
        }
        if (!hasError) {
            this.spinner.show();
            const otp = this.firstValue1 + this.secondValue2 + this.thirdValue3 + this.fourthValue4
            console.log("otp verified..!", otp)
            return this.appService.verifyPhoneNoOtp(this.attendeesRegisterForm.get("audiencePhoneNumber").value, otp)
                .pipe(takeUntil(this.destroy$))
                .subscribe(data => {
                    console.log("send otp :", data)
                    this.spinner.hide()
                    if (data.statusCode == 200) {
                        this.phoneNoModal = false;
                        this.phoneNoVerified = true;
                        this.toastr.success("OTP Verified..!")
                    } else if (data.statusCode == 500) {
                        this.toastr.error("OTP Not Matched")
                    }
                }, (err: any) => {
                    this.spinner.hide()
                    console.log("something went wrong :", err)
                })
        }
    }
}
