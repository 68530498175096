export class AddQuestion {
    sessionId: number
    questionSeq: number
    questionName: string
    questionType: string
    option1: string
    option2: string
    option3: string
    option4: string
    answer: string
    questionState: string
    sessionDate: number
    createdAt: number
    createdBy: string
    updatedAt: number
    updatedBy: string
    optionType: string
}