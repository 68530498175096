import {Component, OnInit} from '@angular/core';
import {AppService} from "@services/app.service";
import {Subject, takeUntil} from "rxjs";
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {InteractSession} from "@modules/modal/interactSession";
import {ToastrService} from "ngx-toastr";
import {Router} from "@angular/router";
import {DatePipe} from "@angular/common";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
   
  constructor(private router:Router){

  }

  ngOnInit() {
  }

  login(){
    this.router.navigate(['/login'])
  }
  register(){
    this.router.navigate(['/register'])
  }
  
}