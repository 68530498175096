<div class="container-fluid">
<div class="card card-outline card-primary">
  <div class="card-header text-center">
    <img src="assets/img/interact%203.png" style="height: 50px" class="img-fluid bg-light">
  </div>
  <div class="card-body">
    <h5 class="login-box-msg text-uppercase font-weight-bold">Create Account</h5>

    <form (ngSubmit)="onSubmit()" [formGroup]="registerForm"  autocomplete="off">

        <div class="row mt-3">
          <div class="input-group">
            <input class="form-control" formControlName="name" placeholder="Name" type="text" autocomplete="off">
            <div class="input-group-append">
              <div class="input-group-text">
                <span class="fas fa-user"></span>
              </div>
            </div>
          </div>
        </div>
      <div class="row">
        <span class="text-danger col-12" *ngIf="registerFormErrors.name">{{registerFormErrors.name}}</span>
      </div>
      <div class="row mt-3">
        <div class="input-group">
          <input
                  class="form-control"
                  formControlName="email"
                  placeholder="Email"
                  type="email" (change)="emailOTP($event)" autocomplete="cc-number"
          />
          <div class="input-group-append">
            <div class="input-group-text">
              <span class="fas fa-envelope"></span>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <span class="text-danger col-12" *ngIf="registerFormErrors.email">{{registerFormErrors.email}}</span>
      </div>
      <div class="row mt-3">
        <div class="input-group col-md-4">
          <select class="form-control" formControlName="countryCode" autocomplete="cc-number">
            <option selected value="+91">(+91) India</option>
            <option value="+1">(+1) United States</option>
            <option value="+93">(+93) Afghanistan</option>
            <option value="+355">(+355) Albania (+355)</option>
            <option value="+213">(+213) Algeria</option>
            <option value="+1">(+1)American Samoa</option>
            <option value="+376">(+376) Andorra</option>
            <option value="+244">(+244) Angola</option>
            <option value="+1">(+1) Anguilla</option>
            <option value="+1">(+1) Antigua and Barbuda</option>
            <option value="+54">(+54) Argentina</option>
            <option value="+374">(+374) Armenia</option>
            <option value="+297">(+297) Aruba</option>
            <option value="+247">(+247) Ascension Island</option>
            <option value="+61">(+61) Australia</option>
            <option value="+672">(+672) Australian Antarctic Territory</option>
            <option value="+43">(+43) Austria</option>
            <option value="+994">(+994) Azerbaijan</option>
            <option value="+1">(+1) Bahamas</option>
            <option value="+973">(+973) Bahrain</option>
            <option value="+880">(+880) Bangladesh</option>
            <option value="+1">(+1) Barbados</option>
            <option value="+375">(+375) Belarus</option>
            <option value="+32">(+32) Belgium</option>
            <option value="+501">(+501) Belize</option>
            <option value="+229">(+229) Benin</option>
            <option value="+1">(+1) Bermuda</option>
            <option value="+975">(+975) Bhutan</option>
            <option value="+591">(+591) Bolivia</option>
            <option value="+387">(+387) Bosnia and Herzegovina</option>
            <option value="+267">(+267) Botswana</option>
            <option value="+55">(+55) Brazil</option>
            <option value="+246">(+246) British Indian Ocean Territory</option>
            <option value="+1">(+1) British Virgin Islands</option>
            <option value="+673">(+673) Brunei</option>
            <option value="+359">(+359) Bulgaria</option>
            <option value="+226">(+226) Burkina Faso</option>
            <option value="+257">(+257) Burundi</option>
            <option value="+855">(+855) Cambodia</option>
            <option value="+237">(+237) Cameroon</option>
            <option value="+1">(+1) Canada</option>
            <option value="+238">(+238) Cape Verde</option>
            <option value="+1">(+1) Caribbean Netherlands</option>
            <option value="+1">(+1) Cayman Islands</option>
            <option value="+236">(+236)C entral African Republic</option>
            <option value="+235">(+235) Chad</option>
            <option value="+56">(+56) Chile</option>
            <option value="+86">(+86) China</option>
            <option value="+61">(+61) Christmas Island</option>
            <option value="+61">(+61) Cocos [Keeling] Islands</option>
            <option value="+57">(+57) Colombia</option>
            <option value="+269">(+269) Comoros</option>
            <option value="+682">(+682) Cook Islands</option>
            <option value="+506">(+506) Costa Rica</option>
            <option value="+385">(+385) Croatia</option>
            <option value="+53">(+53) Cuba</option>
            <option value="+599">(+599) Curaçao</option>
            <option value="+357">(+357) Cyprus</option>
            <option value="+420">(+420) Czechia</option>
            <option value="+243">(+243) Democratic Republic of the Congo</option>
            <option value="+45">(+45) Denmark</option>
            <option value="+246">(+246) Diego Garcia</option>
            <option value="+253">(+253) Djibouti</option>
            <option value="+1">(+1) Dominica</option>
            <option value="+1">(+1) Dominican Republic</option>
            <option value="+593">(+593) Ecuador</option>
            <option value="+20">(+20) Egypt</option>
            <option value="+503">(+503) El Salvador</option>
            <option value="+240">(+240) Equatorial Guinea</option>
            <option value="+291">(+291) Eritrea</option>
            <option value="+372">(+372) Estonia</option>
            <option value="+251">(+251) Ethiopia</option>
            <option value="+500">(+500) Falkland Islands</option>
            <option value="+298">(+298) Faroe Islands</option>
            <option value="+679">(+679) Fiji</option>
            <option value="+358">(+358) Finland</option>
            <option value="+33">(+33) France</option>
            <option value="+594">(+594) French Guiana</option>
            <option value="+689">(+689) French Polynesia</option>
            <option value="+241">(+241) Gabon</option>
            <option value="+220">(+220) Gambia</option>
            <option value="+995">(+995) Georgia</option>
            <option value="+49">(+49) Germany</option>
            <option value="+233">(+233) Ghana</option>
            <option value="+350">(+350) Gibraltar</option>
            <option value="+30">(+30) Greece</option>
            <option value="+299">(+299) Greenland</option>
            <option value="+1">(+1) Grenada</option>
            <option value="+590">(+590) Guadeloupe</option>
            <option value="+1">(+1)Guam</option>
            <option value="+502">(+502) Guatemala</option>
            <option value="+44">(+44) Guernsey</option>
            <option value="+224">(+224) Guinea</option>
            <option value="+245">(+245) Guinea-Bissau</option>
            <option value="+592">(+592) Guyana</option>
            <option value="+509">(+509) Haiti</option>
            <option value="+504">(+504) Honduras</option>
            <option value="+852">(+852) Hong Kong</option>
            <option value="+36">(+36) Hungary</option>
            <option value="+354">(+354) Iceland</option>
            <option value="+62">(+62) Indonesia</option>
            <option value="+98">(+98) Iran</option>
            <option value="+964">(+964) Iraq</option>
            <option value="+353">(+353) Ireland</option>
            <option value="+44">(+44) Isle of Man</option>
            <option value="+972">(+972) Israel</option>
            <option value="+39">(+39) Italy</option>
            <option value="+225">(+225) Ivory Coast</option>
            <option value="+1">(+1) Jamaica</option>
            <option value="+81">(+81) Japan</option>
            <option value="+44">(+44) Jersey</option>
            <option value="+962">(+962) Jordan</option>
            <option value="+7">(+7) Kazakhstan</option>
            <option value="+254">(+254) Kenya</option>
            <option value="+686">(+686) Kiribati</option>
            <option value="+383">(+383) Kosovo</option>
            <option value="+965">(+965) Kuwait</option>
            <option value="+996">(+996) Kyrgyzstan</option>
            <option value="+856">(+856) Laos</option>
            <option value="+371">(+371) Latvia</option>
            <option value="+961">(+961) Lebanon</option>
            <option value="+266">(+266) Lesotho</option>
            <option value="+231">(+231) Liberia</option>
            <option value="+218">(+218) Libya</option>
            <option value="+423">(+423) Liechtenstein</option>
            <option value="+370">(+370) Lithuania</option>
            <option value="+352">(+352) Luxembourg</option>
            <option value="+853">(+853) Macau</option>
            <option value="+389">(+389) Macedonia</option>
            <option value="+261">(+261) Madagascar</option>
            <option value="+265">(+265) Malawi</option>
            <option value="+60">(+60) Malaysia</option>
            <option value="+960">(+960) Maldives</option>
            <option value="+223">(+223) Mali</option>
            <option value="+356">(+356) Malta</option>
            <option value="+692">(+692) Marshall Islands</option>
            <option value="+596">(+596) Martinique</option>
            <option value="+222">(+222) Mauritania</option>
            <option value="+230">(+230) Mauritius</option>
            <option value="+262">Mayotte</option>
            <option value="+52">(+262) Mexico (+52)</option>
            <option value="+691">(+691) Micronesia</option>
            <option value="+373">(+373) Moldova</option>
            <option value="+377">(+377) Monaco</option>
            <option value="+976">(+976) Mongolia</option>
            <option value="+382">(+382) Montenegro</option>
            <option value="+1">(+1) Montserrat</option>
            <option value="+212">(+212) Morocco</option>
            <option value="+258">(+258) Mozambique</option>
            <option value="+95">(+95) Myanmar [Burma]</option>
            <option value="+264">(+264) Namibia</option>
            <option value="+674">(+674)Nauru</option>
            <option value="+977">(+977) Nepal</option>
            <option value="+31">(+31) Netherlands</option>
            <option value="+599">(+599) Netherlands Antilles</option>
            <option value="+687">(+687) New Caledonia</option>
            <option value="+64">(+64) New Zealand</option>
            <option value="+505">(+505) Nicaragua</option>
            <option value="+227">(+227) Niger</option>
            <option value="+234">(+234)Nigeria</option>
            <option value="+683">(+683) Niue</option>
            <option value="+672">(+672) Norfolk Island</option>
            <option value="+850">(+850) North Korea</option>
            <option value="+1">(+1) Northern Mariana Islands</option>
            <option value="+47">(+47) Norway</option>
            <option value="+968">(+968) Oman</option>
            <option value="+92">(+92) Pakistan</option>
            <option value="+680">(+680) Palau</option>
            <option value="+970">(+970) Palestine</option>
            <option value="+507">(+507) Panama</option>
            <option value="+675">(+675) Papua New Guinea</option>
            <option value="+595">(+595) Paraguay</option>
            <option value="+51">(+51) Peru</option>
            <option value="+63">(+63) Philippines</option>
            <option value="+64">(+64) Pitcairn Islands</option>
            <option value="+48">(+48) Poland</option>
            <option value="+351">(+351) Portugal</option>
            <option value="+1">(+1) Puerto Rico</option>
            <option value="+974">(+974) Qatar</option>
            <option value="+242">(+242) Republic of the Congo</option>
            <option value="+262">(+262) Réunion</option>
            <option value="+40">(+40) Romania</option>
            <option value="+7">(+7) Russia</option>
            <option value="+250">(+250) Rwanda</option>
            <option value="+590">(+590) Saint Barthélemy</option>
            <option value="+290">(+290) Saint Helena</option>
            <option value="+1">(+1) Saint Kitts and Nevis</option>
            <option value="+1">(+1) Saint Lucia</option>
            <option value="+590">(+590) Saint Martin</option>
            <option value="+508">(+508) Saint Pierre and Miquelon</option>
            <option value="+1">(+1) Saint Vincent and the Grenadines</option>
            <option value="+685">(+685) Samoa</option>
            <option value="+378">(+378) San Marino</option>
            <option value="+239">(+239) São Tomé and Príncipe</option>
            <option value="+966">(+966) Saudi Arabia</option>
            <option value="+221">(+221) Senegal</option>
            <option value="+381">(+381) Serbia</option>
            <option value="+248">(+248) Seychelles</option>
            <option value="+232">(+232) Sierra Leone</option>
            <option value="+65">(+65) Singapore</option>
            <option value="+1">(+1) Sint Maarten</option>
            <option value="+421">(+421) Slovakia</option>
            <option value="+386">(+386) Slovenia</option>
            <option value="+677">(+677) Solomon Islands</option>
            <option value="+252">(+252) Somalia</option>
            <option value="+27">(+27) South Africa</option>
            <option value="+82">(+82) South Korea</option>
            <option value="+211">(+211)South Sudan</option>
            <option value="+34">(+34) Spain</option>
            <option value="+94">(+94) Sri Lanka</option>
            <option value="+249">(+249) Sudan</option>
            <option value="+597">(+597) Suriname</option>
            <option value="+47">(+47) Svalbard and Jan Mayen</option>
            <option value="+268">(+268) Swaziland</option>
            <option value="+46">(+46) Sweden</option>
            <option value="+41">(+41) Switzerland</option>
            <option value="+963">(+963) Syria</option>
            <option value="+886">(+886) Taiwan</option>
            <option value="+992">(+992) Tajikistan</option>
            <option value="+255">(+255) Tanzania</option>
            <option value="+66">(+66) Thailand</option>
            <option value="+670">(+670) Timor-Leste</option>
            <option value="+228">(+228) Togo</option>
            <option value="+690">(+690) Tokelau</option>
            <option value="+676">(+676) Tonga</option>
            <option value="+1">(+1) Trinidad and Tobago</option>
            <option value="+216">(+216) Tunisia</option>
            <option value="+90">(+90) Turkey</option>
            <option value="+993">(+993) Turkmenistan</option>
            <option value="+1">(+1) Turks and Caicos Islands</option>
            <option value="+688">(+688) Tuvalu</option>
            <option value="+1">(+1) U.S. Virgin Islands</option>
            <option value="+256">(+256) Uganda</option>
            <option value="+380">(+380) Ukraine</option>
            <option value="+971">(+971) United Arab Emirates</option>
            <option value="+44">(+44) United Kingdom</option>
            <option value="+598">(+598) Uruguay</option>
            <option value="+998">(+998) Uzbekistan</option>
            <option value="+678">(+678) Vanuatu</option>
            <option value="+39">(+39) Vatican City</option>
            <option value="+58">(+58) Venezuela</option>
            <option value="+84">(+84) Vietnam</option>
            <option value="+681">(+681) Wallis and Futuna</option>
            <option value="+967">(+967) Yemen</option>
            <option value="+260">(+260) Zambia</option>
            <option value="+263">(+263) Zimbabwe</option>
          </select>
        </div>
        <div class="col-md-8 input-group">
          <input type="text" class="form-control" formControlName="phoneNumber" id="phoneNumber" placeholder="Phone Number" maxlength="10"
                 (change)="phoneNoOTP($event)" (keypress)="OnlyNumbers($event)" autocomplete="cc-number">
          <div class="input-group-append">
            <div class="input-group-text">
              <span class="fas fa-phone"></span>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <span class="text-danger col-12" *ngIf="registerFormErrors.phoneNumber">{{registerFormErrors.phoneNumber}}</span>
        <span class="text-danger col-12" *ngIf="registerFormErrors.countryCode">{{registerFormErrors.countryCode}}</span>
      </div>
      <div class="row mt-3">
        <div class="input-group">
          <input
                  formControlName="password" id="password" [(ngModel)]="password"
                  type="password"
                  class="form-control"
                  placeholder="Password" autocomplete="cc-number"
          />
          <div class="input-group-append">
            <div class="input-group-text">
              <i class="toggle-password fa fa-lock-open" data-toggle="tooltip" data-placement="top" title="Click to Show Password" (click)="togglePasswordVisibility()" [ngClass]="{'fa-lock-open': isPasswordVisible, 'fa-lock': !isPasswordVisible}" aria-hidden="true"></i>
            </div>
          </div>
        </div>
      </div>
    <div class="row">
      <span class="text-danger col-12" *ngIf="registerFormErrors.password">{{registerFormErrors.password}}</span>
    </div>
      <!--<div class="row mt-3">
        <div class="input-group">
          <select class="form-control" formControlName="industry">
            <option selected disabled value="">Select Industry</option>
            <option value="Textile">Textile</option>
            <option value="Medicine">Medicine</option>
            <option value="Retail">Retail</option>
          </select>
        </div>
      </div>
      <div class="row">
        <span class="text-danger col-12" *ngIf="registerFormErrors.industry">{{registerFormErrors.industry}}</span>
      </div>-->
      <div class="row mt-3">
        <div class="col-12">
          <div class="icheck-primary">
            <input
              id="agreeTerms"
              name="terms"
              type="checkbox"
              value="agree" autocomplete="off"
            />
            <label for="agreeTerms">
              I agree to the <a href="#">Terms & Conditions</a>
            </label>
          </div>
        </div>
        <!-- /.col -->
        <div class="col-12">
          <pf-button
            [block]="true"
            [disabled]="isFacebookLoading || isGoogleLoading"
            [loading]="isAuthLoading"
            [type]="'submit'"
          >
            Register
          </pf-button>
        </div>
        <!-- /.col -->
      </div>
    </form>
    <p class="text-muted mt-3 mb-0">Have already an account?
      <a class=" font-weight-bold text-success" type="button" [routerLink]="['/login']">Login</a>
    </p>
  </div>
</div>
</div>

<div [style.display]="emailModal ? 'block' : 'none'" class="modal" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <section>
      <svg width="250" height="200" viewBox="0 0 292 208" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_1_45)">
          <path d="M152.106 208C201.536 208 241.606 167.93 241.606 118.5C241.606 69.0706 201.536 29 152.106 29C102.676 29 62.6058 69.0706 62.6058 118.5C62.6058 167.93 102.676 208 152.106 208Z" fill="#C5FFFF" />
          <path d="M117.144 64.4241C113.81 64.4241 111.108 67.1261 111.108 70.46V167.057C111.108 170.391 113.81 173.093 117.144 173.093H186.572C189.906 173.093 192.608 170.391 192.608 167.057V92.382L163.507 64.4241H117.144Z" fill="#91E4FF" />
          <path d="M192.608 92.382H169.544C166.21 92.382 163.508 89.68 163.508 86.3461V64.4241L192.608 92.382Z" fill="#0CB4EA" />
          <path d="M162.304 131.646C162.304 135.494 159.185 138.613 155.339 138.613H104.483C100.635 138.613 97.5186 135.494 97.5186 131.646V110.363C97.5186 106.515 100.635 103.397 104.483 103.397H155.339C159.185 103.397 162.304 106.515 162.304 110.363V131.646Z" fill="#0CB4EA" />
          <path d="M117.094 114.409C118.563 114.409 119.825 114.707 120.876 115.302C121.93 115.897 122.728 116.745 123.267 117.843C123.807 118.941 124.079 120.23 124.079 121.712C124.079 122.808 123.932 123.803 123.635 124.697C123.338 125.592 122.894 126.369 122.302 127.025C121.71 127.681 120.981 128.184 120.119 128.532C119.257 128.879 118.266 129.053 117.153 129.053C116.044 129.053 115.054 128.875 114.178 128.518C113.302 128.16 112.571 127.657 111.985 127.005C111.398 126.354 110.956 125.572 110.656 124.658C110.358 123.744 110.208 122.755 110.208 121.692C110.208 120.604 110.364 119.604 110.676 118.697C110.99 117.788 111.442 117.017 112.034 116.378C112.627 115.739 113.349 115.253 114.198 114.914C115.047 114.574 116.012 114.409 117.094 114.409ZM121.17 121.692C121.17 120.655 121.003 119.756 120.669 118.997C120.334 118.238 119.856 117.663 119.233 117.273C118.612 116.883 117.899 116.688 117.093 116.688C116.521 116.688 115.991 116.795 115.504 117.012C115.017 117.228 114.599 117.542 114.247 117.954C113.897 118.367 113.621 118.893 113.416 119.534C113.214 120.176 113.113 120.895 113.113 121.694C113.113 122.499 113.214 123.226 113.416 123.877C113.621 124.527 113.907 125.067 114.277 125.495C114.647 125.923 115.073 126.244 115.552 126.456C116.031 126.668 116.558 126.775 117.131 126.775C117.866 126.775 118.54 126.592 119.154 126.224C119.77 125.857 120.259 125.29 120.623 124.524C120.988 123.757 121.17 122.813 121.17 121.692Z" fill="white" />
          <path d="M134.976 117.018H131.846V127.306C131.846 127.898 131.713 128.338 131.45 128.625C131.187 128.912 130.844 129.054 130.425 129.054C130 129.054 129.654 128.909 129.388 128.619C129.121 128.33 128.987 127.892 128.987 127.305V117.017H125.856C125.366 117.017 125.003 116.909 124.765 116.693C124.528 116.477 124.408 116.192 124.408 115.838C124.408 115.47 124.532 115.181 124.779 114.969C125.028 114.757 125.387 114.649 125.858 114.649H134.977C135.473 114.649 135.842 114.76 136.082 114.977C136.326 115.196 136.446 115.483 136.446 115.836C136.446 116.189 136.323 116.475 136.078 116.691C135.834 116.907 135.466 117.018 134.976 117.018Z" fill="white" />
          <path d="M143.642 123.297H141.015V127.306C141.015 127.879 140.879 128.313 140.609 128.61C140.339 128.907 139.997 129.054 139.584 129.054C139.152 129.054 138.804 128.907 138.542 128.614C138.279 128.322 138.146 127.891 138.146 127.324V116.409C138.146 115.777 138.291 115.326 138.581 115.056C138.871 114.786 139.331 114.65 139.963 114.65H143.643C144.733 114.65 145.568 114.734 146.154 114.902C146.734 115.063 147.235 115.33 147.657 115.703C148.079 116.077 148.399 116.534 148.619 117.076C148.84 117.617 148.947 118.224 148.947 118.901C148.947 120.344 148.503 121.437 147.615 122.182C146.726 122.926 145.4 123.297 143.642 123.297ZM142.945 116.804H141.014V121.133H142.945C143.622 121.133 144.188 121.062 144.64 120.921C145.095 120.78 145.44 120.548 145.678 120.226C145.917 119.904 146.036 119.483 146.036 118.959C146.036 118.335 145.853 117.826 145.485 117.433C145.074 117.013 144.228 116.804 142.945 116.804Z" fill="white" />
          <rect x="233.582" y="79" width="10" height="10" rx="1" transform="rotate(27.2727 233.582 79)" fill="#91A3FF" />
          <circle cx="74" cy="139" r="5" fill="#FF91B9" />
          <circle cx="79" cy="43" r="5" fill="#91E5FF" />
          <circle cx="188" cy="203" r="5" fill="#FF9191" />
        </g>
        <circle cx="220" cy="15" r="5" fill="#FFC691" />
        <circle cx="119.606" cy="5" r="5" fill="#91FFAF" />
        <rect x="250.606" y="163" width="10" height="10" rx="1" fill="#E991FF" />
        <rect x="274" y="47.0925" width="10" height="10" rx="1" transform="rotate(-24.1576 274 47.0925)" fill="#FF9191" />
        <rect y="68.5666" width="10" height="10" rx="1" transform="rotate(-27.1716 0 68.5666)" fill="#91A3FF" />
        <path d="M33.0121 175.265L40.7499 180.821L32.0689 184.744L33.0121 175.265Z" fill="#FF9191" />
        <path d="M15.077 128.971L16.567 138.38L7.67356 134.966L15.077 128.971Z" fill="#FD91FF" />
        <path d="M286.447 120.204L287.505 129.672L278.777 125.854L286.447 120.204Z" fill="#FF91BF" />
        <defs>
          <clipPath id="clip0_1_45">
            <rect width="179" height="179" fill="white" transform="translate(62.6058 29)" />
          </clipPath>
        </defs>
      </svg>
      <div class="title">Verification Code</div>
      <!--<p class="para">We have sent a verification code to your email address</p>-->
      <div class="modal-body">
        <div class="text-center"><span>A code has been sent to {{email}} </span></div>
        <div id="emailOtp" class="inputs d-flex flex-row justify-content-center mt-2" (keypress)="OnlyNumbers($event)"><input
                class="m-2 text-center  otpInput" type="tel" id="first" maxlength="1"
                [(ngModel)]="firstValue" (ngModelChange)="moveFocus('second')"/> <input
                class="m-2 text-center  otpInput" type="tel" id="second" maxlength="1"
                [(ngModel)]="secondValue" (ngModelChange)="moveFocus('third')"/> <input
                class="m-2 text-center  otpInput" type="tel" id="third" maxlength="1"
                [(ngModel)]="thirdValue" (ngModelChange)="moveFocus('fourth')"/> <input
                class="m-2 text-center  otpInput" type="tel" id="fourth" maxlength="1" [(ngModel)]="fourthValue"/></div>
          <div class="d-flex justify-content-center col-md-12">
          <button type="button" class="btn btn-primary otpButton" (click)="emailOtpVerify()">Verify OTP</button></div>
      </div>
      </section>
    </div>
  </div>
</div>

<div [style.display]="phoneNoModal ? 'block' : 'none'" class="modal" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <section>
        <svg width="250" height="200" viewBox="0 0 292 208" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_1_45)">
            <path d="M152.106 208C201.536 208 241.606 167.93 241.606 118.5C241.606 69.0706 201.536 29 152.106 29C102.676 29 62.6058 69.0706 62.6058 118.5C62.6058 167.93 102.676 208 152.106 208Z" fill="#C5FFFF" />
            <path d="M117.144 64.4241C113.81 64.4241 111.108 67.1261 111.108 70.46V167.057C111.108 170.391 113.81 173.093 117.144 173.093H186.572C189.906 173.093 192.608 170.391 192.608 167.057V92.382L163.507 64.4241H117.144Z" fill="#91E4FF" />
            <path d="M192.608 92.382H169.544C166.21 92.382 163.508 89.68 163.508 86.3461V64.4241L192.608 92.382Z" fill="#0CB4EA" />
            <path d="M162.304 131.646C162.304 135.494 159.185 138.613 155.339 138.613H104.483C100.635 138.613 97.5186 135.494 97.5186 131.646V110.363C97.5186 106.515 100.635 103.397 104.483 103.397H155.339C159.185 103.397 162.304 106.515 162.304 110.363V131.646Z" fill="#0CB4EA" />
            <path d="M117.094 114.409C118.563 114.409 119.825 114.707 120.876 115.302C121.93 115.897 122.728 116.745 123.267 117.843C123.807 118.941 124.079 120.23 124.079 121.712C124.079 122.808 123.932 123.803 123.635 124.697C123.338 125.592 122.894 126.369 122.302 127.025C121.71 127.681 120.981 128.184 120.119 128.532C119.257 128.879 118.266 129.053 117.153 129.053C116.044 129.053 115.054 128.875 114.178 128.518C113.302 128.16 112.571 127.657 111.985 127.005C111.398 126.354 110.956 125.572 110.656 124.658C110.358 123.744 110.208 122.755 110.208 121.692C110.208 120.604 110.364 119.604 110.676 118.697C110.99 117.788 111.442 117.017 112.034 116.378C112.627 115.739 113.349 115.253 114.198 114.914C115.047 114.574 116.012 114.409 117.094 114.409ZM121.17 121.692C121.17 120.655 121.003 119.756 120.669 118.997C120.334 118.238 119.856 117.663 119.233 117.273C118.612 116.883 117.899 116.688 117.093 116.688C116.521 116.688 115.991 116.795 115.504 117.012C115.017 117.228 114.599 117.542 114.247 117.954C113.897 118.367 113.621 118.893 113.416 119.534C113.214 120.176 113.113 120.895 113.113 121.694C113.113 122.499 113.214 123.226 113.416 123.877C113.621 124.527 113.907 125.067 114.277 125.495C114.647 125.923 115.073 126.244 115.552 126.456C116.031 126.668 116.558 126.775 117.131 126.775C117.866 126.775 118.54 126.592 119.154 126.224C119.77 125.857 120.259 125.29 120.623 124.524C120.988 123.757 121.17 122.813 121.17 121.692Z" fill="white" />
            <path d="M134.976 117.018H131.846V127.306C131.846 127.898 131.713 128.338 131.45 128.625C131.187 128.912 130.844 129.054 130.425 129.054C130 129.054 129.654 128.909 129.388 128.619C129.121 128.33 128.987 127.892 128.987 127.305V117.017H125.856C125.366 117.017 125.003 116.909 124.765 116.693C124.528 116.477 124.408 116.192 124.408 115.838C124.408 115.47 124.532 115.181 124.779 114.969C125.028 114.757 125.387 114.649 125.858 114.649H134.977C135.473 114.649 135.842 114.76 136.082 114.977C136.326 115.196 136.446 115.483 136.446 115.836C136.446 116.189 136.323 116.475 136.078 116.691C135.834 116.907 135.466 117.018 134.976 117.018Z" fill="white" />
            <path d="M143.642 123.297H141.015V127.306C141.015 127.879 140.879 128.313 140.609 128.61C140.339 128.907 139.997 129.054 139.584 129.054C139.152 129.054 138.804 128.907 138.542 128.614C138.279 128.322 138.146 127.891 138.146 127.324V116.409C138.146 115.777 138.291 115.326 138.581 115.056C138.871 114.786 139.331 114.65 139.963 114.65H143.643C144.733 114.65 145.568 114.734 146.154 114.902C146.734 115.063 147.235 115.33 147.657 115.703C148.079 116.077 148.399 116.534 148.619 117.076C148.84 117.617 148.947 118.224 148.947 118.901C148.947 120.344 148.503 121.437 147.615 122.182C146.726 122.926 145.4 123.297 143.642 123.297ZM142.945 116.804H141.014V121.133H142.945C143.622 121.133 144.188 121.062 144.64 120.921C145.095 120.78 145.44 120.548 145.678 120.226C145.917 119.904 146.036 119.483 146.036 118.959C146.036 118.335 145.853 117.826 145.485 117.433C145.074 117.013 144.228 116.804 142.945 116.804Z" fill="white" />
            <rect x="233.582" y="79" width="10" height="10" rx="1" transform="rotate(27.2727 233.582 79)" fill="#91A3FF" />
            <circle cx="74" cy="139" r="5" fill="#FF91B9" />
            <circle cx="79" cy="43" r="5" fill="#91E5FF" />
            <circle cx="188" cy="203" r="5" fill="#FF9191" />
          </g>
          <circle cx="220" cy="15" r="5" fill="#FFC691" />
          <circle cx="119.606" cy="5" r="5" fill="#91FFAF" />
          <rect x="250.606" y="163" width="10" height="10" rx="1" fill="#E991FF" />
          <rect x="274" y="47.0925" width="10" height="10" rx="1" transform="rotate(-24.1576 274 47.0925)" fill="#FF9191" />
          <rect y="68.5666" width="10" height="10" rx="1" transform="rotate(-27.1716 0 68.5666)" fill="#91A3FF" />
          <path d="M33.0121 175.265L40.7499 180.821L32.0689 184.744L33.0121 175.265Z" fill="#FF9191" />
          <path d="M15.077 128.971L16.567 138.38L7.67356 134.966L15.077 128.971Z" fill="#FD91FF" />
          <path d="M286.447 120.204L287.505 129.672L278.777 125.854L286.447 120.204Z" fill="#FF91BF" />
          <defs>
            <clipPath id="clip0_1_45">
              <rect width="179" height="179" fill="white" transform="translate(62.6058 29)" />
            </clipPath>
          </defs>
        </svg>
        <div class="title">Verification Code</div>
        <!--<p class="para">We have sent a verification code to your mobile number</p>-->
        <div class="modal-body">
          <div class="text-center"><span>A code has been sent to {{phoneNo}} </span></div>
          <div id="phoneNoOtp" class="inputs d-flex flex-row justify-content-center mt-2" (keypress)="OnlyNumbers($event)"><input
                  class="m-2 text-center otpInput" type="tel" id="first1" maxlength="1"
                  [(ngModel)]="firstValue1" (ngModelChange)="moveFocus('second1')"/> <input
                  class="m-2 text-center  otpInput" type="tel" id="second1" maxlength="1"
                  [(ngModel)]="secondValue2" (ngModelChange)="moveFocus('third1')"/> <input
                  class="m-2 text-center  otpInput" type="tel" id="third1" maxlength="1"
                  [(ngModel)]="thirdValue3" (ngModelChange)="moveFocus('fourth1')"/> <input
                  class="m-2 text-center  otpInput" type="tel" id="fourth1" maxlength="1" [(ngModel)]="fourthValue4"/></div>

          <div class="d-flex justify-content-center col-md-12">
            <button type="button" class="btn btn-primary otpButton" (click)="phoneNoOtpVerify()">Verify OTP</button></div>
        </div>
      </section>
    </div>
  </div>
</div>
