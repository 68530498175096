<!--<div class="float-right d-none d-sm-block"><b>Version</b> {{ appVersion }}</div>-->
<div class="d-flex justify-content-center">
<strong>
  <span>&copy; {{ currentYear }} </span>
  <a
    href="https://varnikcloud.com"
    rel="noopener noreferrer"
    style="margin: 0"
    target="_blank" class=""
  >VarNik Systems Services Private Limited</a
  >
  <span>.</span>
</strong>
<span> All rights reserved.</span>
</div>
