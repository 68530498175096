import {Component, OnInit} from "@angular/core";
import {AppService} from "@services/app.service";
import {Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {InteractSession} from "@modules/modal/interactSession";
import {interval, Subject, Subscription, takeUntil} from 'rxjs';
import * as QRCode from 'qrcode';
import {DatePipe} from "@angular/common";
import {NgxSpinnerService} from "ngx-spinner";


@Component({
    selector: 'app-session-table',
    templateUrl: './session-table.component.html',
    styleUrls: ['./session-table.component.scss']
})

export class SessionTableComponent implements OnInit {

    getSessionDetails : InteractSession[] = [];
    sessionStatus: InteractSession[] = [];
    destroy$: Subject<boolean> = new Subject<boolean>();
    selectedCheckbox: any;
    qrCodeData: string = 'http://interact.varnik.cloud/#/audience-register?session=';
    sessionQrData: string = '';
    showShareOptions = false;
    sharedMessage = 'Check out this awesome content!';
    buttonShow = false;
    sideBarSessionId: string;
    sessionState: string;
    private intervalSubscription: Subscription;

    constructor(private appService: AppService,private router:Router,
                private toaster: ToastrService, private datePipe: DatePipe,
                private spinner: NgxSpinnerService){

    }


    ngOnInit(): void {
        this.getSession();
        this.sideBarSessionId = localStorage.getItem('sideBarSessionId');
        this.sessionState = localStorage.getItem('sessionState');
        this.intervalSubscription = interval(1000).subscribe(() => {
            if (this.sideBarSessionId != localStorage.getItem('sideBarSessionId') ||
                this.sessionState != localStorage.getItem('sessionState')) {
                this.sideBarSessionId = localStorage.getItem('sideBarSessionId');
                this.sessionState = localStorage.getItem('sessionState');
                this.getSession();
            }
        });
    }

    ngOnDestroy(): void {
        localStorage.removeItem('sessionState')
        localStorage.removeItem('sideBarSessionId')
        this.intervalSubscription.unsubscribe()
    }
    generateQRCode() {
        const qrCanvas = document.getElementById('qrCanvas') as HTMLCanvasElement;
        const finalQrCodeData = this.qrCodeData + this.sessionQrData;
        QRCode.toCanvas(qrCanvas, finalQrCodeData, (error) => {
            if (error) {
                console.error('QR code generation failed:', error);
            }
        });
    }

    copyURL(id: number) {
        const currentDate = this.datePipe.transform(new Date().getTime(), "yyyy-MM-dd");
        const currentTime = this.datePipe.transform(new Date().getTime(), "HH:mm");
        for(let qr of this.getSessionDetails) {
            if (id == qr.sessionId) {
               /* const date = new Date().getTime()*/
                const startDate = this.datePipe.transform(qr.sessionStartDate, "yyyy-MM-dd");
                const endDate = this.datePipe.transform(qr.sessionEndDate, "yyyy-MM-dd");
                /*if (startDate < currentDate && endDate > currentDate || startDate == currentDate && endDate == currentDate
                    && qr.sessionStartTime < currentTime && qr.sessionEndTime > currentTime) {
                    this.sessionQrData = qr.sessionQrCode;
                    const url = this.qrCodeData + this.sessionQrData;
                    const textArea = document.createElement('textarea');
                    textArea.value = url;
                    document.body.appendChild(textArea);
                    textArea.select();
                    document.execCommand('copy');
                    document.body.removeChild(textArea);
                    this.toaster.success("URL Copied")
                } else {
                    this.toaster.error("Session Date is Ended")
                    console.log("session date is ended")
                }*/
                if (endDate < currentDate || endDate == currentDate && qr.sessionEndTime < currentTime) {
                    this.toaster.error("Session Date is Ended")
                    console.log("session date is ended")
                } else {
                    this.sessionQrData = qr.sessionQrCode;
                    const url = this.qrCodeData + this.sessionQrData;
                    const textArea = document.createElement('textarea');
                    textArea.value = url;
                    document.body.appendChild(textArea);
                    textArea.select();
                    document.execCommand('copy');
                    document.body.removeChild(textArea);
                    this.toaster.success("URL Copied")
                }
            }
        }
    }

    getSession() {
        this.spinner.show();
        this.appService.getSessionDetails()
            .pipe(takeUntil(this.destroy$))
            .subscribe(data => {
                console.log("getSessionDetails::", data)
                const filterByUser = data.filter(user => user.createdBy == localStorage.getItem('userId'))
                this.getSessionDetails = filterByUser;
                const sessionStatus: InteractSession[] = [];
                const currentDate = this.datePipe.transform(new Date().getTime(), "yyyy-MM-dd");
                const currentTime = this.datePipe.transform(new Date().getTime(), "HH:mm");
                for (let sort of this.getSessionDetails) {
                    const startDate = this.datePipe.transform(sort.sessionStartDate, "yyyy-MM-dd");
                    const endDate = this.datePipe.transform(sort.sessionEndDate, "yyyy-MM-dd");
                    if(localStorage.getItem("sideBarSessionId") != null) {
                        if (Number(localStorage.getItem("sideBarSessionId")) == sort.sessionId) {
                            if (endDate < currentDate || endDate == currentDate && sort.sessionEndTime < currentTime) {
                                sessionStatus.push(sort)
                                this.buttonShow = false;
                            } else {
                                sessionStatus.push(sort)
                                this.buttonShow = true;
                            }
                        }
                    } else if (localStorage.getItem("sessionState") == "Active") {
                        if (startDate < currentDate && endDate > currentDate || startDate == currentDate && endDate == currentDate
                            && sort.sessionStartTime < currentTime && sort.sessionEndTime > currentTime ||
                            startDate == currentDate && sort.sessionStartTime < currentTime || endDate == currentDate
                            && sort.sessionEndTime > currentTime) {
                            sessionStatus.push(sort)
                            this.buttonShow = true;
                        }
                    } else if (localStorage.getItem("sessionState") == "Upcoming") {
                        if (startDate > currentDate || startDate == currentDate && sort.sessionStartTime > currentTime) {
                            sessionStatus.push(sort)
                            this.buttonShow = true;
                        }
                    } else if (localStorage.getItem("sessionState") == "Expired") {
                        if (endDate < currentDate || endDate == currentDate && sort.sessionEndTime < currentTime) {
                            sessionStatus.push(sort)
                            this.buttonShow = false;
                        }
                    } /*else if(localStorage.getItem("sideBarSessionId") != null) {
                        if (Number(localStorage.getItem("sideBarSessionId")) == sort.sessionId) {
                            if (endDate < currentDate || endDate == currentDate && sort.sessionEndTime < currentTime) {
                                sessionStatus.push(sort)
                                this.buttonShow = false;
                            } else {
                                sessionStatus.push(sort)
                                this.buttonShow = true;
                            }
                        }
                    }*/
                }
                this.sessionStatus = sessionStatus;
                this.spinner.hide();
            }, (err: any) => {
                this.spinner.hide();
                console.log("error:", err)
            })
    }

    formatDateFromEpoch(epochTime) {
        const date = new Date(epochTime);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();

        return `${day}-${month}-${year}`;
    }

    /*newSession(){
        this.router.navigate(['/main/new-interact-session']);
    }*/

    selectCheckbox(id: number, isChecked: boolean) {
        console.log("userIdQrCode::", id);
        console.log("checked::", isChecked);
        for(let qr of this.getSessionDetails) {
            if(id == qr.sessionId) {
                localStorage.setItem('sessionId',String(id));
                this.sessionQrData = qr.sessionQrCode;
                // this.generateQRCode();
            }
        }
        if (isChecked) {
            this.getSessionDetails.forEach((event) => {
                if (event.sessionId != id) {
                    event.isChecked = false;
                }else{
                    event.isChecked = true;
                }
            });
        }
    }

    startSession(sessionId: number) {
        console.log("sessionData::", sessionId)
        const currentDate = this.datePipe.transform(new Date().getTime(), "yyyy-MM-dd");
        const currentTime = this.datePipe.transform(new Date().getTime(), "HH:mm")
        for(let qr of this.getSessionDetails) {
            if (sessionId == qr.sessionId) {
                console.log("session end date::", qr.sessionEndDate)
               /* const date = new Date().getTime()*/
               /* const date = Math.floor(new Date().getTime() / 1000);*/
               /* console.log("current date::", date)*/
                const startDate = this.datePipe.transform(qr.sessionStartDate, "yyyy-MM-dd");
                const endDate = this.datePipe.transform(qr.sessionEndDate, "yyyy-MM-dd");
                /*if (startDate == currentDate && endDate == currentDate ||
                    startDate < currentDate && endDate > currentDate ||
                    startDate == currentDate && endDate > currentDate
                    && qr.sessionStartTime < currentTime && qr.sessionEndTime > currentTime ||
                    startDate == currentDate && qr.sessionStartTime < currentTime ||
                    endDate == currentDate && qr.sessionEndTime > currentTime) {
                    localStorage.setItem('sessionId', String(sessionId));
                    window.open("http://interact.varnik.cloud/#/projector-display?id=" + sessionId, "_blank");
                    this.router.navigate(['/main/session']);
                } else {
                    this.toaster.error("Session Date is Ended")
                    console.log("session date is ended")
                }*/
                if (endDate < currentDate || endDate == currentDate && qr.sessionEndTime < currentTime) {
                    this.toaster.error("Session Date is Ended")
                    console.log("session date is ended")
                } else {
                    localStorage.setItem('sessionId', String(sessionId));
                    window.open("http://interact.varnik.cloud/#/projector-display?id=" + sessionId, "_blank");
                    this.router.navigate(['/main/session']);
                }
            }
        }
    }


    shareOnFacebook() {
        const shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(this.qrCodeData + this.sessionQrData)}`;
        window.open(shareUrl, '_blank');
    }

    shareOnTwitter() {
        const shareUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(this.sharedMessage)}&url=${encodeURIComponent(this.qrCodeData + this.sessionQrData)}`;
        window.open(shareUrl, '_blank');
    }

    shareOnLinkedIn() {
        const shareUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(this.qrCodeData + this.sessionQrData)}&title=${encodeURIComponent(this.sharedMessage)}`;
        window.open(shareUrl, '_blank');
    }

    shareOnWhatsApp() {
        console.log("share url :", this.sessionQrData)
        const shareUrl = `https://wa.me/?text=${encodeURIComponent(`${this.sharedMessage} ${this.qrCodeData + this.sessionQrData}`)}`;
        window.open(shareUrl, '_blank');
    }

    toggleShareOptions(id: number) {
        for(let qr of this.getSessionDetails) {
            if(id == qr.sessionId) {
                const date = new Date().getTime()
                /*if (qr.sessionEndDate >= date) {
                    this.sessionQrData = qr.sessionQrCode;
                    this.showShareOptions = !this.showShareOptions;
                } else {
                    this.toaster.error("Session Date is Ended")
                    console.log("session date is ended")
                }*/
                const currentDate = this.datePipe.transform(new Date().getTime(), "yyyy-MM-dd");
                const currentTime = this.datePipe.transform(new Date().getTime(), "HH:mm");
                const endDate = this.datePipe.transform(qr.sessionEndDate, "yyyy-MM-dd");
                if (endDate < currentDate || endDate == currentDate && qr.sessionEndTime < currentTime) {
                    this.toaster.error("Session Date is Ended")
                    console.log("session date is ended")
                } else {
                    this.sessionQrData = qr.sessionQrCode;
                    this.showShareOptions = !this.showShareOptions;
                }
            }
        }
    }

    close(){
        this.showShareOptions = false
    }

    editSession(id: number) {
        localStorage.setItem('sessionId', String(id))
        localStorage.setItem('editFunction', "true")
        this.router.navigate(["/main/new-interact-session"])
    }

}