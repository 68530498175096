import {Component, HostBinding, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {AppService} from '@services/app.service';
import {ToastrService} from 'ngx-toastr';
import {Subject, takeUntil} from 'rxjs';
import {NgxSpinnerService} from "ngx-spinner";
import {Register} from "@modules/modal/register";
import {Router} from "@angular/router";

@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit, OnDestroy {
    @HostBinding('class') class = 'register-box';
    destroy$: Subject<boolean> = new Subject<boolean>();
    public isAuthLoading = false;
    public isGoogleLoading = false;
    public isFacebookLoading = false;
    password: string;
    isPasswordVisible: boolean = false;
    newRegisterDetails: Register | undefined;
    emailModal = false;
    emailVerified = false;
    phoneNoModal = false;
    phoneNoVerified = false;
    firstValue: string = '';
    secondValue: string = '';
    thirdValue: string = '';
    fourthValue: string = '';
    firstValue1: string = '';
    secondValue2: string = '';
    thirdValue3: string = '';
    fourthValue4: string = '';
    email: string = '';
    phoneNo: string = '';

    constructor(
        private renderer: Renderer2,
        private toastr: ToastrService,
        private appService: AppService,
        private router: Router,
        private spinner: NgxSpinnerService
    ) {
    }

    ngOnInit() {
        this.renderer.addClass(
            document.querySelector('app-root'),
            'register-page'
        );
    }

    registerForm = new UntypedFormGroup({
        name: new UntypedFormControl(null, Validators.required),
        email: new UntypedFormControl(null, Validators.required),
        countryCode: new UntypedFormControl(null, Validators.required),
        phoneNumber: new UntypedFormControl(null, Validators.required),
        password: new UntypedFormControl(null, [Validators.required]),
        /* userName: new UntypedFormControl(null, [Validators.required]),*/
        industry: new UntypedFormControl(null, Validators.required),
    });

    public registerFormErrors = {
        name: "", email: "", phoneNumber: "", password: "", industry: "", countryCode: ""
    }

    onSubmit() {
        this.formValidation();
    }

    formValidation() {
        this.registerFormErrors.name = "";
        this.registerFormErrors.email = "";
        this.registerFormErrors.phoneNumber = "";
        this.registerFormErrors.countryCode = "";
        this.registerFormErrors.password = "";
        this.registerFormErrors.industry = "";
        let hasError = false;
        if (this.registerForm.get("countryCode").invalid) {
            this.registerFormErrors.countryCode = "Country code is Required";
            hasError = true;
        }
        if (this.registerForm.get("name").invalid) {
            this.registerFormErrors.name = "Name is Required";
            hasError = true;
        }
        if (this.registerForm.get("email").invalid) {
            this.registerFormErrors.email = "Email ID is Required";
            hasError = true;
        }
        if (this.registerForm.get("phoneNumber").invalid) {
            this.registerFormErrors.phoneNumber = "Phone Number is Required";
            hasError = true;
        }
        if (this.registerForm.get("phoneNumber")?.value.length != 10) {
            this.registerFormErrors.phoneNumber = "Enter Your Valid Phone Number";
            hasError = true;
        }
        if (this.registerForm.get("password").invalid) {
            this.registerFormErrors.password = "Password is Required";
            hasError = true;
        }
        /*if(this.registerForm.get("industry").invalid) {
          this.registerFormErrors.industry = "Select any one Industry";
          hasError = true;
        }*/

        if (!hasError) {
            this.registerDetails();
        }

    }

    //Only Enter Number Input box Event
    OnlyNumbers($event) {
        let regex: RegExp = new RegExp(/^[0-9]{1,}$/g);
        let specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'ArrowRight', 'ArrowLeft'];
        if (specialKeys.indexOf($event.key) !== -1) {
            return;
        } else {
            if (regex.test($event.key)) {
                return true;
            } else {
                return false;
            }
        }
    }

    registerDetails() {
        if (this.emailVerified) {
            if (this.phoneNoVerified) {
                this.registerForm.controls["industry"].setValue("")
                this.spinner.show();
                this.appService
                    .saveUserDetails(this.registerForm.value)
                    .pipe(takeUntil(this.destroy$))
                    .subscribe((data) => {
                            console.log("Register Details::", data);
                            // @ts-ignore
                            this.newRegisterDetails = data;
                            this.spinner.hide();
                            this.toastr.success("Registered Successfully");
                            this.router.navigate(['/login']);
                        },
                        (err: any) => {
                            this.spinner.hide();
                            this.toastr.error("Something went wrong, Please try Again")
                        },
                        () => console.log("HTTP request completed.")
                    );
            } else {
                this.toastr.error("Phone Number Not Verified..!")
            }
        } else {
            this.toastr.error("Email Not Verified..!")
        }
    }

    async registerByGoogle() {
        this.isGoogleLoading = true;
        await this.appService.registerByGoogle();
        this.isGoogleLoading = false;
    }

    async registerByFacebook() {
        this.isFacebookLoading = true;
        await this.appService.registerByFacebook();
        this.isFacebookLoading = false;
    }

    ngOnDestroy() {
        this.renderer.removeClass(
            document.querySelector('app-root'),
            'register-page'
        );
    }

    togglePasswordVisibility() {
        const passwordShow = document.getElementById('password') as HTMLInputElement;
        if ('password' == passwordShow.type) {
            passwordShow.type = 'text';
        } else {
            passwordShow.type = 'password';
        }
        this.isPasswordVisible = !this.isPasswordVisible;
    }


    emailOTP(event) {
        this.email = this.registerForm.get("email").value;
        this.registerFormErrors.email = '';
        let hasError = false;

        if (this.registerForm.get("email")?.invalid) {
            this.registerFormErrors.email = "Enter Your Valid Email Address";
            hasError = true;
        }

        if (!hasError) {
            this.emailModal = true;
            return this.appService.sendEmailOtp(this.registerForm.value)
                .pipe(takeUntil(this.destroy$))
                .subscribe(data => {
                    console.log("send otp :", data)
                }, (err: any) => {
                    console.log("something went wrong :", err)
                })
        }
    }

    phoneNoOTP(event) {
        this.phoneNo = this.registerForm.get("phoneNumber").value;
        this.registerFormErrors.phoneNumber = '';
        let hasError = false;

        if (this.registerForm.get("phoneNumber")?.invalid) {
            this.registerFormErrors.phoneNumber = "Enter Your Valid Phone Number";
            hasError = true;
        }

        if (this.registerForm.get("phoneNumber")?.value.length != 10) {
            this.registerFormErrors.phoneNumber = "Enter Your Valid Phone Number";
            hasError = true;
        }

        if (!hasError) {
            this.phoneNoModal = true;
            return this.appService.sendPhoneNoOtp(this.registerForm.value)
                .pipe(takeUntil(this.destroy$))
                .subscribe(data => {
                    console.log("send otp :", data)
                }, (err: any) => {
                    console.log("something went wrong :", err)
                })
        }
    }

    moveFocus(nextInput: string) {
        const nextInputField = document.getElementById(nextInput) as HTMLInputElement;
        if (nextInputField) {
            nextInputField.focus();
        }
    }

    emailOtpVerify() {

        let hasError = false;

        if (this.firstValue == "") {
            this.toastr.error("Please enter OTP")
            hasError = true;
        }
        if (this.secondValue == "") {
            this.toastr.error("Please enter OTP")
            hasError = true;
        }
        if (this.thirdValue == "") {
            this.toastr.error("Please enter OTP")
            hasError = true;
        }
        if (this.fourthValue == "") {
            this.toastr.error("Please enter OTP")
            hasError = true;
        }
        if (!hasError) {
            this.spinner.show();
            const otp = this.firstValue + this.secondValue + this.thirdValue + this.fourthValue
            console.log("otp verified..!", otp)
            return this.appService.verifyEmailOtp(this.registerForm.get("email").value, otp)
                .pipe(takeUntil(this.destroy$))
                .subscribe(data => {
                    console.log("send otp :", data)
                    this.spinner.hide()
                    if (data.statusCode == 200) {
                        this.emailModal = false;
                        this.emailVerified = true;
                        this.toastr.success("OTP Verified..!")
                    } else if (data.statusCode == 500) {
                        this.toastr.error("OTP Not Matched")
                    }
                }, (err: any) => {
                    this.spinner.hide()
                    console.log("something went wrong :", err)
                })
        }
    }

    phoneNoOtpVerify() {
        let hasError = false;
        if (this.firstValue1 == "") {
            this.toastr.error("Please enter OTP")
            hasError = true;
        }
        if (this.secondValue2 == "") {
            this.toastr.error("Please enter OTP")
            hasError = true;
        }
        if (this.thirdValue3 == "") {
            this.toastr.error("Please enter OTP")
            hasError = true;
        }
        if (this.fourthValue4 == "") {
            this.toastr.error("Please enter OTP")
            hasError = true;
        }
        if (!hasError) {
            this.spinner.show();
            const otp = this.firstValue1 + this.secondValue2 + this.thirdValue3 + this.fourthValue4
            console.log("otp verified..!", otp)
            return this.appService.verifyPhoneNoOtp(this.registerForm.get("phoneNumber").value, otp)
                .pipe(takeUntil(this.destroy$))
                .subscribe(data => {
                    console.log("send otp :", data)
                    this.spinner.hide()
                    if (data.statusCode == 200) {
                        this.phoneNoModal = false;
                        this.phoneNoVerified = true;
                        this.toastr.success("OTP Verified..!")
                    } else if (data.statusCode == 500) {
                        this.toastr.error("OTP Not Matched")
                    }
                }, (err: any) => {
                    this.spinner.hide()
                    console.log("something went wrong :", err)
                })
        }
    }
}
