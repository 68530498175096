import { Component } from '@angular/core';
import { DateTime } from 'luxon';
import AOS from "aos";


@Component({
  selector: 'app-app-use-case',
  templateUrl: './app-use-case.component.html',
  styleUrls: ['./app-use-case.component.scss']
})


export class AppUseCaseComponent {
  public currentYear: string = DateTime.now().toFormat('y');
  currentImage: string = '../../../assets/img/home/create account.png'; // Default image

  updateImage(imageSource: string): void {
    console.log("IMAGE ::", imageSource)
    this.currentImage = imageSource;
    this.updateAOSAnimation()

  }

  updateAOSAnimation(): void {
    // Assuming you have a reference to the DOM element
    const imageContainer = document.getElementById('imageId');

    // Update AOS attribute

    imageContainer.setAttribute('data-aos', 'zoom-in');
    // Trigger AOS refresh to apply changes
    AOS.init();

  }



  ngOnInit() {
    AOS.init();
  }


}
