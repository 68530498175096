import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {Gatekeeper} from 'gatekeeper-client-sdk';
import {Form, FormGroup} from '@angular/forms';
import {HttpClient} from "@angular/common/http";
import { Register } from '@modules/modal/register';
import {InteractSession} from "@modules/modal/interactSession";
import {LoginResponse} from "@services/loginResponse";
import {LocalDataService} from "@services/localDataService";
import {AudienceRegister} from "@modules/modal/audienceRegister";
import {InteractQuestions} from "@modules/modal/interactQuestions";
import {AudienceAnswer} from "@modules/modal/audienceAnswer";
import {State} from "@modules/modal/state";
import {NewQuestions} from "@modules/modal/newQuestions";
import {OtpResponse} from "@modules/modal/otpResponse";


@Injectable({
  providedIn: 'root'
})
export class AppService {
  public user: any = null;

  /*endPoint= "http://136.233.82.212:8080/interact-ws";*/
  endPoint = "https://tomcat3.varnik.cloud:8443/interact-ws";
  // endPoint= "http://localhost:8084";

  constructor(private router: Router, private toastr: ToastrService,
    private http : HttpClient,private localData: LocalDataService) {
  }

  async loginByAuth({email, password}) {
    try {
      const token = await Gatekeeper.loginByAuth(email, password);
      localStorage.setItem('token', token);
      await this.getProfile();
      this.router.navigate(['/']);
      this.toastr.success('Login success');
    } catch (error) {
      this.toastr.error(error.message);
    }
  }

  async registerByAuth({email, password}) {
    try {
      const token = await Gatekeeper.registerByAuth(email, password);
      localStorage.setItem('token', token);
      await this.getProfile();
      this.router.navigate(['/']);
      this.toastr.success('Register success');
    } catch (error) {
      this.toastr.error(error.message);
    }
  }

  async loginByGoogle() {
    try {
      const token = await Gatekeeper.loginByGoogle();
      localStorage.setItem('token', token);
      await this.getProfile();
      this.router.navigate(['/']);
      this.toastr.success('Login success');
    } catch (error) {
      this.toastr.error(error.message);
    }
  }

  async registerByGoogle() {
    try {
      const token = await Gatekeeper.registerByGoogle();
      localStorage.setItem('token', token);
      await this.getProfile();
      this.router.navigate(['/']);
      this.toastr.success('Register success');
    } catch (error) {
      this.toastr.error(error.message);
    }
  }

  async loginByFacebook() {
    try {
      const token = await Gatekeeper.loginByFacebook();
      localStorage.setItem('token', token);
      await this.getProfile();
      this.router.navigate(['/']);
      this.toastr.success('Login success');
    } catch (error) {
      this.toastr.error(error.message);
    }
  }

  async registerByFacebook() {
    try {
      const token = await Gatekeeper.registerByFacebook();
      localStorage.setItem('token', token);
      await this.getProfile();
      this.router.navigate(['/']);
      this.toastr.success('Register success');
    } catch (error) {
      this.toastr.error(error.message);
    }
  }

  async getProfile() {
      // this.user = await Gatekeeper.getProfile();
    try {
      const token = localStorage.getItem('authToken');

      if (token == '') {
        this.user = null;
        this.router.navigate(['/login']);
      }else{
        this.user=token

      }
    } catch (error) {
      this.logout();
      throw error;
    }
  }

  logout() {
    localStorage.clear();
    this.user = null;
    this.router.navigate(['/login']);
  }

  /* User Register Save API */
  saveUserDetails(registerForm : FormGroup){
    return this.http.post<Register>(
      this.endPoint+"/api/registerUser",registerForm
    )
  }

  //Save Audience Registration
  saveAudienceRegister(audienceForm: FormGroup) {
    return this.http.post<AudienceRegister>(
        this.endPoint+"/api/audience/registration",audienceForm
    )
  }

  // Save New Interact sessions
  saveNewInteractSessions(formData: FormGroup) {
    return this.http.post<InteractSession>(
        this.endPoint + "/api/session/create", formData,
        {headers: this.localData.getHeaders()}
    )
  }

  // Update Exist Session
  updateExistSession(formData: FormGroup) {
    return this.http.put<InteractSession>(
        this.endPoint + "/api/session/update", formData,
        {headers: this.localData.getHeaders()}
    )
  }

  getSessionsByState(state: string) {
    return this.http.get<InteractSession[]>(
        this.endPoint + "/api/session/get/" + state,
        {headers: this.localData.getHeaders()}
    )
  }

  // login request check
  login(loginForm: FormGroup) {
    return this.http.post<LoginResponse>(
        this.endPoint + "/api/login", loginForm
    );
  }


  //Get all sessions details

  getSessionDetails(){
    return this.http.get<InteractSession[]>(
      this.endPoint +"/api/session/getAll",
      {headers: this.localData.getHeaders()}
    )
  }

  // Get Active Question
  getQuestion(sessionId: number) {
    return this.http.get<InteractQuestions>(
        this.endPoint + "/api/question/get/active/" + sessionId,
        // {headers: this.localData.getHeaders()}
    )
  }

  // Get Questions by Session
  getAllQuestions(sessionId: number) {
    return this.http.get<InteractQuestions[]>(
        this.endPoint + "/api/questions/get/" + sessionId,
        {headers: this.localData.getHeaders()}
    )
  }

  // Save Answer
  saveAnswer(formData: FormGroup) {
    return this.http.post<AudienceAnswer>(
        this.endPoint + "/api/audience/answer", formData,
        // {headers: this.localData.getHeaders()}
    )
  }

  startSessionStatus(formData: FormGroup) {
    return this.http.post<State>(
        this.endPoint + "/api/state", formData,
        {headers: this.localData.getHeaders()}
    )
  }

  // Create Questions
  saveQuestions(formData: any[]) {
    return this.http.post<NewQuestions[]>(
        this.endPoint + "/api/questions/create",formData,
        {headers: this.localData.getHeaders()}
    )
  }

  // Update Exist Question
  updateExistQuestion(formData: FormGroup) {
    return this.http.put<NewQuestions>(
        this.endPoint + "/api/questions/update", formData,
        {headers: this.localData.getHeaders()}
    )
  }

  // Get Registered persons details
  getRegisteredDetails(sessionId: number) {
    return this.http.get<AudienceRegister[]>(
        this.endPoint + "/api/audience/getBySessionId/" + sessionId,
        {headers: this.localData.getHeaders()}
    )
  }

  // Get attendees answers based on session id
  getAttendeesAnswer(sessionId: number) {
    return this.http.get<AudienceAnswer[]>(
        this.endPoint + "/api/audience/answerBySessionId/" + sessionId,
        {headers: this.localData.getHeaders()}
    )
  }

  // Send Email OTP
  sendEmailOtp(formData: FormGroup) {
    return this.http.post(
        this.endPoint + "/auth/email/send-otp", formData,
    )
  }

  // Email OTP Verify
  verifyEmailOtp(email: string, otp: string) {
    return this.http.post<OtpResponse>(
        this.endPoint + "/auth/email/verify-otp?email=" + email + "&otp=" + otp, ''
    )
  }

  // Send Phone number OTP
  sendPhoneNoOtp(formData: FormGroup) {
    return this.http.post(
        this.endPoint + "/auth/phone/otp", formData,
    )
  }

  // Phone Number OTP Verify
  verifyPhoneNoOtp(phoneNo: string, otp: string) {
    return this.http.post<OtpResponse>(
        this.endPoint + "/auth/phone/validate/otp?phoneNumber=" + phoneNo + "&otp=" + otp, ''
    )
  }

  //File Upload
  fileUpload(formData: FormData, questionId: number, option: string) {
    return this.http.post<String[]>(
        this.endPoint + "/fileUpload/upload?questionId=" + questionId + "&option=" + option, formData
    )
  }

  //File Get
  getFile(questionId: string, option: string) {
    return this.http.get(
        this.endPoint + "/api/interact/getOptionPic/" + questionId + "/" + option,
        {responseType: "blob"}
    )
  }

  //whatsapp otp sent
  sendWhatsappOtp(phoneNo: number) {
    return this.http.post(
        this.endPoint + "/api/whatsapp/send/" + phoneNo, ''
    )
  }

}
