<div class="container-fluid">
<div class="container-fluid">
    <div class="row">
        <div class="row" [style.display]="isHide ? 'block' : 'none'">
            <div class="col-md-6 d-flex align-items-start">
                <h4 class="mt-2"><i class="bi bi-calendar-event"></i><span class="ml-3 font-italic">
                    {{titleEdit ? "Edit Session" : "Create Session"}}</span>
                    </h4>
            </div>
        </div>
        <div class="row" [style.display]="isManage ? 'block' : 'none'">
            <div class="col-md-6 d-flex align-items-start">
                <h4 class="mt-2"><i class="bi bi-calendar-event"></i><span class="ml-3 font-italic">Manage Session</span>
                </h4>
            </div>
        </div>
    </div>
    <form (ngSubmit)="onSubmit()" [formGroup]="interactSessions" class="mt-1">
        <div class="row">
            <div class="col-md-3 mt-1">
                <div class="form-floating">
                    <input type="text" class="form-control text-capitalize" placeholder="Session Name"
                           formControlName="sessionName">
                    <label class="form-label">Session Name<span class="text-danger">*</span></label>
                    <span *ngIf="sessionErrors.sessionName" class="text-danger">{{sessionErrors.sessionName}}</span>
                </div>
            </div>
            <div class="col-md-2 mt-1">
                <div class="form-floating">
                    <input type="date" class="form-control" formControlName="sessionStartDate" [min]="minDate">
                    <label>Start Date<span class="text-danger">*</span></label>
                    <span *ngIf="sessionErrors.sessionStartDate"
                          class="text-danger">{{sessionErrors.sessionStartDate}}</span>
                </div>
            </div>
            <div class="col-md-2 mt-1">
                <div class="form-floating">
                    <input type="time" class="form-control" formControlName="sessionStartTime">
                    <label>Start Time<span class="text-danger">*</span></label>
                    <span *ngIf="sessionErrors.sessionStartTime"
                          class="text-danger">{{sessionErrors.sessionStartTime}}</span>
                </div>
            </div>
            <div class="col-md-2 mt-1">
                <div class="form-floating">
                    <input type="date" class="form-control" formControlName="sessionEndDate" [min]="minDate">
                    <label>End Date<span class="text-danger">*</span></label>
                    <span *ngIf="sessionErrors.sessionEndDate"
                          class="text-danger">{{sessionErrors.sessionEndDate}}</span>
                </div>
            </div>
            <div class="col-md-2 mt-1">
                <div class="form-floating">
                    <input type="time" class="form-control" formControlName="sessionEndTime">
                    <label>End Time<span class="text-danger">*</span></label>
                    <span *ngIf="sessionErrors.sessionEndTime"
                          class="text-danger">{{sessionErrors.sessionEndTime}}</span>
                </div>
            </div>
        </div>
        <div class="row mt-3" style="text-align-last: center">
            <div class="col-md-12">
                <button *ngIf="sessionCreate" type="submit" class="btn btn-primary">
                    {{isUpdate ? "Update Session" : "Create Session"}}</button>
            </div>
        </div>
    </form>
</div>
    <div *ngIf="questionBoxShow && isUpdate" class="row mt-1 ml-4">
        <p class="text-danger mb-1">Note: Click the Added Questions to edit</p>
    </div>
<div *ngIf="questionBoxShow" class="row justify-content-center">
    <div class="card container-fluid ml-4 col-lg-7 col-12"
         style="background-color:rgba(242,242,242,0.5);border: 1px solid #d2cfcf; height: 380px">
        <form [formGroup]="newQuestions">
            <div class="row mt-1">
                <div class="col-md-8">
                    <h6 class="font-italic text-bold mb-3 mt-2">Add Your Questions</h6>
                </div>
                <div *ngIf="isUpdate" class="col-md-4">
                    <button type="button" class="btn btn-primary btn-sm ml-5" (click)="addUpdateQuestions()">Add Questions
                    </button>
                </div>
            </div>
            <div class="row">
                <div class="col-md-8">
                    <div class="form-floating mb-3">
                        <input type="text" class="form-control" placeholder="Question Title"
                               formControlName="questionName">
                        <label>Question Title</label>
                        <span *ngIf="questionErrors.questionName"
                              class="text-danger">{{questionErrors.questionName}}</span>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-floating">
                        <select class="form-select" aria-label="Floating label select example"
                                formControlName="questionType" (change)="showTwoInputText($event)">
                            <option selected disabled>Select Your Option Type</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                            <option value="4">Four</option>
                        </select>
                        <label>Select Option</label>
                        <span *ngIf="questionErrors.questionType"
                              class="text-danger">{{questionErrors.questionType}}</span>
                    </div>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-md-4">
                    <div class="form-floating mb-3">
                        <input type="text" class="form-control" placeholder="Option 1" formControlName="option1"
                               (change)="addOptionsAnswer($event)">
                        <label>Option 1</label>
                        <span *ngIf="questionErrors.option1" class="text-danger">{{questionErrors.option1}}</span>
                    </div>
                </div>
                <div class="col-md-4" *ngIf="showTwoOptions">
                    <div class="form-floating mb-3">
                        <input type="text" class="form-control" placeholder="Option 2" formControlName="option2"
                               (change)="addOptionsAnswer($event)">
                        <label>Option 2</label>
                        <span *ngIf="questionErrors.option2" class="text-danger">{{questionErrors.option2}}</span>
                    </div>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-md-4" *ngIf="showThreeOptions">
                    <div class="form-floating mb-3">
                        <input type="text" class="form-control" placeholder="Option 3" formControlName="option3"
                               (change)="addOptionsAnswer($event)">
                        <label>Option 3</label>
                        <span *ngIf="questionErrors.option3" class="text-danger">{{questionErrors.option3}}</span>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-floating mb-3" *ngIf="showFourOptions">
                        <input type="text" class="form-control" placeholder="Option 4" formControlName="option4"
                               (change)="addOptionsAnswer($event)">
                        <label>Option 4</label>
                        <span *ngIf="questionErrors.option4" class="text-danger">{{questionErrors.option4}}</span>
                    </div>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-md-4">
                    <div class="form-floating">
                        <select class="form-select" aria-label="Floating label select example" formControlName="answer"
                        (change)="getAnswer($event)">
                            <option selected hidden>Select Your Correct Answer</option>
                            <option *ngFor="let option of options" [value]="option">{{ option }}</option>
                        </select>
                        <label>Correct Answer</label>
                        <span *ngIf="questionErrors.answer" class="text-danger">{{questionErrors.answer}}</span>

                    </div>
                </div>
                <div class="col-md-4">
                    <!--<h3 *ngIf="addIcon" class="font-weight-bold mt-3"><i class="bi bi-plus-square-fill" style="color: #ce0808"
                                                         (click)="QuestionValidation();"></i></h3>-->
                    <button *ngIf="addIcon" type="button" class="btn btn-primary mt-3" (click)="QuestionValidation()">
                        Save to Proceed</button>
                </div>
                <div class="col-md-4 text-center">
                    <button type="button" class="btn btn-success mt-3" *ngIf="buttonShow"
                            (click)="addNewQuestions();">Update</button>
                </div>
            </div>
        </form>
    </div>

    <div class="col-lg-4 col-12">
        <div class="card">
            <div class="card-header">
                <h6 class="font-italic font-weight-bold">Added Questions</h6>
            </div>
            <div class="card-body">
                <div class="row">
                    <div style="overflow-y: auto; height: 290px;" class="added-qn">
                        <div class="container bg-light" type="button" *ngFor="let qus of showQuestionsData">
                            <div class="" (click)="fetchQuestion(qus.questionSeq)">
                                <div class="row">
                                    <h6 class="text-blue mt-3">{{qus.questionName}}</h6>
                                </div>
                                <div class="row">
                                    <p class="col">1. {{qus.option1}}</p>
                                    <p class="col">2. {{qus.option2}}</p>
                                </div>
                                <div class="row">
                                    <p class="col" *ngIf="qus.option3 != ''">3. {{qus.option3}}</p>
                                    <p class="col" *ngIf="qus.option4 != ''">4. {{qus.option4}}</p>
                                </div>
                                <p class="text-green">Answer: {{qus.answer}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

    <div *ngIf="pictureBoxShow" class="row justify-content-center">
        <div class="card container-fluid ml-4 col-lg-7 col-12"
             style="background-color:rgba(242,242,242,0.5);border: 1px solid #d2cfcf; height: 450px">
            <form [formGroup]="newQuestions">
                <div class="row mt-1">
                    <div class="col-md-8">
                        <h6 class="font-italic text-bold mb-3 mt-2">Add Your Questions</h6>
                    </div>
                    <div *ngIf="isUpdate" class="col-md-4">
                        <button type="button" class="btn btn-primary btn-sm ml-5" (click)="addUpdateQuestions()">Add Questions
                        </button>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-8">
                        <div class="form-floating mb-3">
                            <input type="text" class="form-control" placeholder="Question Title"
                                   formControlName="questionName">
                            <label>Question Title</label>
                            <span *ngIf="questionErrors.questionName"
                                  class="text-danger">{{questionErrors.questionName}}</span>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-floating">
                            <select class="form-select" aria-label="Floating label select example"
                                    formControlName="questionType" (change)="showTwoInputImage($event)">
                                <option selected disabled>Select Your Option Type</option>
                                <option value="2">Two</option>
                                <option value="3">Three</option>
                                <option value="4">Four</option>
                            </select>
                            <label>Select Option</label>
                            <span *ngIf="questionErrors.questionType"
                                  class="text-danger">{{questionErrors.questionType}}</span>
                        </div>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-md-4">
                        <div class="form-floating mb-3">
                            <input type="file" class="form-control" placeholder="Option 1" formControlName="option1"
                                   (change)="onFileChangeOption1($event)">
                            <label>Option 1</label>
                            <span *ngIf="questionErrors.option1" class="text-danger">{{questionErrors.option1}}</span>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="card" style="width: 100px">
                            <div style="width: 100px; height: 100px;">
                                <img class="image-preview" [src]="option1" width="100" height="100">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4" *ngIf="showTwoOptions">
                        <div class="form-floating mb-3">
                            <input type="file" class="form-control" placeholder="Option 2" formControlName="option2"
                                   (change)="onFileChangeOption2($event)">
                            <label>Option 2</label>
                            <span *ngIf="questionErrors.option2" class="text-danger">{{questionErrors.option2}}</span>
                        </div>
                    </div>
                    <div class="col-md-2" *ngIf="showTwoOptions">
                        <div class="card" style="width: 100px">
                            <div style="width: 100px; height: 100px;">
                                <img class="image-preview" [src]="option2" width="100" height="100">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-md-4" *ngIf="showThreeOptions">
                        <div class="form-floating mb-3">
                            <input type="file" class="form-control" placeholder="Option 3" formControlName="option3"
                                   (change)="onFileChangeOption3($event)">
                            <label>Option 3</label>
                            <span *ngIf="questionErrors.option3" class="text-danger">{{questionErrors.option3}}</span>
                        </div>
                    </div>
                    <div class="col-md-2" *ngIf="showThreeOptions">
                        <div class="card" style="width: 100px">
                            <div style="width: 100px; height: 100px;">
                                <img class="image-preview" [src]="option3" width="100" height="100">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4" *ngIf="showFourOptions">
                        <div class="form-floating mb-3">
                            <input type="file" class="form-control" placeholder="Option 4" formControlName="option4"
                                   (change)="onFileChangeOption4($event)">
                            <label>Option 4</label>
                            <span *ngIf="questionErrors.option4" class="text-danger">{{questionErrors.option4}}</span>
                        </div>
                    </div>
                    <div class="col-md-2" *ngIf="showFourOptions">
                        <div class="card" style="width: 100px">
                            <div style="width: 100px; height: 100px;">
                                <img class="image-preview" [src]="option4" width="100" height="100">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-md-4">
                        <div class="form-floating">
                            <select class="form-select" aria-label="Floating label select example" formControlName="answer"
                                    (change)="getAnswer($event)">
                                <option selected hidden>Select Your Correct Answer</option>
                                <option *ngFor="let option of options" [value]="option">{{ option }}</option>
                            </select>
                            <label>Correct Answer</label>
                            <span *ngIf="questionErrors.answer" class="text-danger">{{questionErrors.answer}}</span>

                        </div>
                    </div>
                    <div class="col-md-4">
                        <!--<h3 *ngIf="addIcon" class="font-weight-bold mt-3"><i class="bi bi-plus-square-fill" style="color: #ce0808"
                                                             (click)="QuestionValidation();"></i></h3>-->
                        <button *ngIf="addIcon" type="button" class="btn btn-primary mt-3" (click)="QuestionValidation()">
                            Save</button>
                    </div>
                    <div class="col-md-4 text-center">
                        <button type="button" class="btn btn-success mt-3" *ngIf="buttonShow"
                                (click)="savePicQuestion();">Update</button>
                    </div>
                </div>
            </form>
        </div>

        <div class="col-lg-4 col-12">
            <div class="card">
                <div class="card-header">
                    <h6 class="font-italic font-weight-bold">Added Questions</h6>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div style="overflow-y: auto; height: 355px;" class="added-qn">
                            <div class="container bg-light" type="button" *ngFor="let qus of showQuestionsData">
                                <div class="" (click)="fetchQuestion(qus.questionSeq)">
                                    <div class="row">
                                        <h6 class="text-blue mt-3">{{qus.questionName}}</h6>
                                    </div>
                                    <!--<div class="row">
                                        <img class="image-preview circle" [src]="option1" width="100" height="100">
                                        <img class="image-preview circle" [src]="option2" width="100" height="100">
                                    </div>
                                    <div class="row">
                                        <img *ngIf="qus.option3 != ''" class="image-preview circle" [src]="option3" width="100" height="100">
                                        <img *ngIf="qus.option4 != ''" class="image-preview circle" [src]="option4" width="100" height="100">
                                        &lt;!&ndash;<p class="col" *ngIf="qus.option3 != ''">3. {{qus.option3}}</p>
                                        <p class="col" *ngIf="qus.option4 != ''">4. {{qus.option4}}</p>&ndash;&gt;
                                    </div>-->
                                    <p class="text-green">Answer: {{qus.answer}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal " role="option" [style.display]="optionTypePop ? 'block' : 'none'">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="model-tittle">Select Option Type</h4>
            </div>
            <div class="modal-body">
                <!--<div class="col-md-12">
                    <div class="form-floating">
                    </div>
                </div>-->
                <form [formGroup]="newQuestions">
                <input type="radio" role="button" value="Text Option" formControlName="optionType">
                    <label class="ml-1">Text Option</label>
                <input class="ml-3" type="radio" role="button" value="Image Option" formControlName="optionType">
                    <label class="ml-1">Image Option</label>
                </form>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="close()"><b>Ok</b>
                </button>
            </div>
        </div>
    </div>
</div>

<!--<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel"><b>Questions List</b></h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div *ngFor="let qus of addQuestionData; let i = index">
                    <label class="">{{i + 1}}. {{qus.questionName}}</label><br>
                    <input class="ml-4" type="radio"><label class="ml-2">{{qus.option1}}</label>
                    <input class="ml-4" type="radio"><label class="ml-2">{{qus.option2}}</label>
                    <input *ngIf="qus.option3 != ''" class="ml-4" type="radio"><label
                        class="ml-2">{{qus.option3}}</label>
                    <input *ngIf="qus.option4 != ''" class="ml-4" type="radio"><label
                        class="ml-2">{{qus.option4}}</label>
                    <label class="ml-4">Answer: {{qus.answer}}</label>
                </div>
            </div>
            &lt;!&ndash;<div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <button type="button" class="btn btn-primary">Save changes</button>
            </div>&ndash;&gt;
        </div>
    </div>
</div>-->
<ngx-spinner bdColor="rgba(255, 255, 255, 1)" size="large" color="#FFFFFF"
             [fullScreen]="true">
    <div class="loader"></div>
</ngx-spinner>