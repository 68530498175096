<head>
  <!-- Other meta tags and stylesheets -->
  <link href="https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;600;700;800&display=swap"
    rel="stylesheet">
  <!-- Your existing stylesheets and scripts -->
</head>


<nav class="navbar navbar-expand-lg bg-light fixed-top">
  <div class="container-fluid">
    <a class="navbar-brand mr-auto" href="#">
      <img src="../../../assets/img/interact 3.png" height="50" alt="">
    </a>
    <button type="button" class="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
      <span class="navbar-toggler-icon"><i class="fa fa-bars" aria-hidden="true"></i></span>
    </button>
    <div class="collapse navbar-collapse justify-content-lg-end" id="navbarCollapse">
      <div class="navbar-nav text-bold">
        <a class="nav-link mx-2" href="#app-usecase">Use Cases</a>

        <a class="nav-link mx-2" href="#features">Features</a>
        <a class="nav-link mx-2" href="#how">Workflow</a>
        <a class="nav-link mx-2" href="#faq">FAQ</a>
      </div>
    </div>
  </div>
</nav>




<div class="container-fluid" style="background-color:  #ecf2ff;">
  <section class="slider-main">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 col-xs-12 col-sm-12 col-md-7">
          <div class="text">
            <!-- <span class="text-white text-bold ml-3">Make an interactive quiz for your meeting</span> -->
            <div class="heading">
              <h2 class="mt-3">Make an Interactive Quiz </h2>
            </div>
            <div class="h4 mt-3">
              <p>Run more engaging trainings and energize your participants with our quiz maker.</p>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4 col-12 mt-3">

              <button type="button" class="buttonReg" [routerLink]="['/register']">Register</button>
            </div>
            <div class="col-lg-4 col-12 mt-3">
              <button type="button" class="buttonLogin" [routerLink]="['/login']">Login</button>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-xs-12 col-sm-12 col-md-7">
          <div class="mobiles-section finite bounce delay-9s">
            <img src="../../../assets/img/home/home.png" class="img-fluid animated" alt="">
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="mt-5" id="features">
    <div class="container mt-3" data-aos="flip-right" data-aos-duration="1000">
      <div class="row text-center ">
        <h2 class="text-bold  mt-4"><span style="color:#407BFF">Awesome</span> Features</h2>
      </div>
    </div>
    <div class="container mt-3">
      <div data-aos="zoom-in-down" data-aos-duration="1000">
        <div class="row">
          <div class="col-lg-3 col-xs-12 col-md-3 col-sm-2">
            <div class="card-container text-center">
              <img src="../../../assets/img/snap.png" class="img-fluid  mt-3" alt="">
              <h4 class="mt-3 feature-heading">Easy</h4>

              <p class="p-3">Creating a quiz just takes a few minutes. Use the links to edit, delete, or start a quiz
                session. The audience can join the session by flashing the QRCode</p>

            </div>
          </div>
          <div class="col-lg-3 col-xs-12 col-md-3 col-sm-2">
            <div class="card-container text-center">
              <img src="../../../assets/img/interactive.png" alt="image" class="img-fluid mt-3">
              <h4 class="mt-3 feature-heading">Interactive</h4>
              <p class="p-3">Once a session starts, the audience has 30s to answer (that delay is customizable). Then
                you can show the results and directly interact with them.</p>

            </div>
          </div>
          <div class="col-lg-3 col-xs-12 col-md-3 col-sm-2">
            <div class="card-container text-center">
              <img src="../../../assets/img/prize.png" alt="image" class="img-fluid mt-3">
              <h4 class="mt-4 feature-heading">Free</h4>
              <p class="p-3">Interact requires no payment. If you would like to, you can provide an email
                address to receive reports and analytics.</p>

            </div>
          </div>
          <div class="col-lg-3 col-xs-12 col-md-3 col-sm-2">
            <div class="card-container text-center">
              <img src="../../../assets/img/question-mark.jpg" alt="image" class="img-fluid mt-3">
              <h4 class="mt-1 feature-heading">What For ?</h4>
              <p class="p-3 mt-4">Explore the deeper purpose and meaning behind your goals, values, and daily decisions. Uncover insights and gain a better understanding of "what for" in just minutes.</p>

            </div>
          </div>
        </div>
      </div>
    </div>
  </section>


  <section class="mt-5">

    <div class="container mt-3" data-aos="flip-right" data-aos-duration="1000">
      <div class="row text-center ">
        <h2 class="text-bold  mt-4"><span style="color:#407BFF">Bring a New Twist To</span> Formative Assessment</h2>
      </div>

      <div class="text mt-3 text-center">
        <p>Explore our unique feature set designed to boost engagement and increase learning.</p>
      </div>
    </div>


    <div class="row p-4 ">
      <div class="col-md-4 feature animate__animated animate__fadeIn p-3">
        <h4  class="text-bold">Unlimited Sessions
        </h4>
        <p class="small">
          Unlock limitless possibilities with our platform's unlimited session creation feature. Break free from constraints and host as many sessions as you need, empowering you to connect, collaborate, and engage without limits. Experience the freedom of endless sessions, giving you the flexibility to maximize your productivity and achieve your goals effortlessly.  
        </p>
      </div>
      <div class="col-md-4 feature animate__animated animate__fadeIn p-3">
        <h4  class="text-bold"> User Friendly
        </h4>
        <p class="small">
          Discover simplicity at its finest with our user-friendly platform. Navigate effortlessly, enjoy intuitive interfaces, and streamline your experience with ease. Our user-friendly design ensures a seamless journey, making every interaction a breeze.
        </p>
      </div>
      <div class="col-md-4 feature animate__animated animate__fadeIn p-3">
        <h4 class="text-bold"> Self-paced
        </h4>
        <p class="small">
          Empower your learning journey with our self-paced platform. Take control of your education, progress at your own speed, and achieve success on your terms. Enjoy the flexibility to learn when it suits you, making education a personalized and convenient experience.         </p>
      </div>
      <div class="col-md-4 feature animate__animated animate__fadeIn p-3">
        <h4  class="text-bold">Extensive Question types
        </h4>
        <p class="small">
          Elevate your assessments with our platform's extensive question types. From multiple-choice to interactive simulations, we offer a diverse range of assessment formats to suit every learning style. Engage your audience with dynamic quizzes, surveys, and beyond, ensuring a comprehensive evaluation experience </p>
      </div>
      <div class="col-md-4 feature animate__animated animate__fadeIn p-3">
        <h4  class="text-bold"> Unlimited Access

        </h4>
        <p class="small">
          Welcome to a platform where growth knows no bounds. With unlimited users, empower your entire team or community to thrive together. No constraints, no restrictions—just seamless collaboration on a grand scale. Embrace inclusivity, foster engagement, and let your community or workforce expand limitlessly. </p>
      </div>
      <div class="col-md-4 feature animate__animated animate__fadeIn p-3">
        <h4  class="text-bold">
          Instant Results
        </h4>
        <p class="small">
          Experience the power of instant knowledge assessment with our platform's quick quiz results. No more waiting—get immediate insights into performance and comprehension. Accelerate learning outcomes and adapt your strategy in real-time. Elevate the learning experience with instant results, providing timely feedback and paving the way for continuous improvement.</p>
      </div>
    </div>
  </section>




  <section id="how">
    <div class="row  text-center">
      <h1 class="text-bold " style="color:#5f5f5f;"><span style="color:#407BFF">How It</span> Works ?
      </h1>
    </div>
    <div class="container ">
      <div data-aos="zoom-in-down" data-aos-duration="1000">
        <div class="row ">
          <div class="col-lg-6 col-xs-12 col-sm-6 col-md-6">

            <div class="row mt-5">
              <div class="accordion" id="accordionExample">
                <div class="accordion-item mb-3 " (click)="updateImage('../../../assets/img/home/create account.png')">
                  <h2 class="accordion-header bg-white accordion-button collapsed" id="headingOne" type="button"
                    data-bs-toggle="collapse" data-bs-target="#collapseOneOne" aria-expanded="false"
                    aria-controls="collapseOneOne">
                    Create Account
                  </h2>
                  <div id="collapseOneOne" class="accordion-collapse collapse" aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <p class="text-muted">
                        Embark on your journey by creating your account today. Join Interact and unlock a world of possibilities. It's quick, easy, and the gateway to personalized experiences awaits. Seize the opportunity—create your account now and start exploring a realm where your preferences, progress, and potential converge seamlessly. Your adventure begins with a simple click. Let's get started!
                      </p>
                    </div>
                  </div>
                </div>
                <div class="accordion-item mb-3 " (click)="updateImage('../../../assets/img/home/manage session.png')">
                  <h2 class="accordion-header bg-white accordion-button collapsed" id="headingTwo" type="button"
                    data-bs-toggle="collapse" data-bs-target="#collapseTwoTwo" aria-expanded="false"
                    aria-controls="collapseTwoTwo">
                    Create Interact Sessions 
                  </h2>
                  <div id="collapseTwoTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                    data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <p class="text-muted">Tailor your learning experience with our customizable quiz platform. From branding to question types, take control and make it yours. Personalize quizzes to suit your unique needs, ensuring engagement and relevance. With our user-friendly customization tools, craft an educational environment that reflects your style and objectives.</p>
                    </div>
                  </div>
                </div>
                <div class="accordion-item mb-3 " (click)="updateImage('../../../assets/img/home/Reach audience.png')">
                  <h2 class="accordion-header bg-white accordion-button collapsed" id="headingThree" type="button"
                    data-bs-toggle="collapse" data-bs-target="#collapseThreeThree" aria-expanded="false"
                    aria-controls="collapseThreeThree">
                    Reach Audience 
                  </h2>
                  <div id="collapseThreeThree" class="accordion-collapse collapse" aria-labelledby="headingThree"
                    data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <p class="text-muted">
                        Expand your impact and extend your influence with our platform's unparalleled audience reach. Seamlessly connect with a global audience, breaking down geographical barriers. Whether you're sharing content, hosting events, or spreading your message, our platform ensures that your reach knows no bounds. 
                      </p>
                    </div>
                  </div>
                </div>
                <div class="accordion-item mb-3" (click)="updateImage('../../../assets/img/home/share results.png')">
                  <h2 class="accordion-header bg-white accordion-button collapsed" type="button"
                    data-bs-toggle="collapse" data-bs-target="#collapseFourFour" aria-expanded="false"
                    aria-controls="collapseFourFour" id="headingFour">
                    Share Results
                  </h2>
                  <div id="collapseFourFour" class="accordion-collapse collapse" aria-labelledby="headingFour"
                    data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <p class="text-muted">
                        Our platform makes it simple to showcase achievements, insights, and progress with just a click. From interactive reports to social media integration, amplify your impact and foster engagement effortlessly. 
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-xs-12 col-sm-6 col-md-6 mt-5">
            <div class="section " id="imageId" data-aos="zoom-in" data-aos-duration="1000">
              <img [src]="currentImage" alt="image" class="img-fluid " data-aos-duration="1000">
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>



  <section class="mt-4" id="faq">
    <div class="row text-center" style="align-items: flex-start;">
      <h1 class="text-bold mt-4" style="color:#5f5f5f;"><span style="color:#407BFF">Frequently</span> Asked
        Question
      </h1>
    </div>
    <div class="container mt-5">
      <div data-aos="zoom-in-down" data-aos-duration="1000">
        <div class="row mt-5">
          <div class="col-lg-6 col-xs-12 col-sm-6 col-md-6">

            <div class="row">
              <div class="accordion" id="accordionExample">
                <div class="accordion-item mb-3">
                  <h2 class="accordion-header bg-white accordion-button collapsed" id="headingOne" type="button"
                    data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false"
                    aria-controls="collapseOne">
                    What Is My Interact?
                  </h2>
                  <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <p class="text-muted">
                        Varnik Interact, or Interact quiz, simplified online quiz hosting platform. Create and customize
                        them on
                        any device. They're eco-friendly and cost-effective. Share your details effortlessly, anytime.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="accordion-item mb-3">
                  <h2 class="accordion-header bg-white accordion-button collapsed" id="headingTwo" type="button"
                    data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false"
                    aria-controls="collapseTwo">
                    Do I Need Any Coding Knowledge To Create My Interact quiz?
                  </h2>
                  <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                    data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <p class="text-muted">Nope! You don't need to learn coding or hire a web developer to use
                        Interact.</p>
                    </div>
                  </div>
                </div>
                <div class="accordion-item mb-3">
                  <h2 class="accordion-header bg-white accordion-button collapsed" id="headingThree" type="button"
                    data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false"
                    aria-controls="collapseThree">
                    Is My Digital Interact Mobile-Friendly?
                  </h2>
                  <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree"
                    data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <p class="text-muted">
                        Absolutely! Every interact quiz you create is mobile-responsive, working perfectly on all
                        devices, not
                        just
                        mobile phones. We understand that your audience is on the move, so we've made sure our digital
                        cards
                        provide an excellent experience on mobile. Our platform handles most of the mobile optimization
                        for
                        you, but you can also preview your card on a mobile screen while editing to ensure it looks just
                        right. This design feature helps you create an attractive card, generate leads, and bring in
                        more
                        visitors.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="accordion-item mb-3">
                  <h2 class="accordion-header bg-white accordion-button collapsed" type="button"
                    data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false"
                    aria-controls="collapseFour" id="headingFour">
                    How Do I Create A Digital Interact With interact.varnik.cloud?
                  </h2>
                  <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour"
                    data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <p class="text-muted">
                        Creating your interact quiz is a breeze! Just sign up at interact.varnik.cloud, customize
                        the
                        design, and add your contact info, social media links, and more. When you're ready, save and
                        share
                        your interact quiz instantly.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="accordion-item mb-3">
                  <h2 class="accordion-header bg-white accordion-button collapsed" type="button"
                    data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false"
                    aria-controls="collapseSix" id="headingSix">
                    Does interact.varNik.cloud Require PAID Subscription?
                  </h2>
                  <div id="collapseSix" class="accordion-collapse collapse" aria-labelledby="headingSix"
                    data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <p class="text-muted">
                        No need to worry about subscriptions here! interact.varNik.cloud is completely free to use. We
                        believe
                        in providing an accessible and user-friendly platform for everyone to create their own
                        interact
                        quizes without any hidden costs or commitments. Enjoy the benefits of interact without spending
                        a
                        time!
                      </p>
                    </div>
                  </div>
                </div>
                <div class="accordion-item mb-3">
                  <h2 class="accordion-header bg-white accordion-button collapsed" type="button"
                    data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false"
                    aria-controls="collapseFive" id="headingFive">
                    Do I Need Any Apps To Access My Interact?
                  </h2>
                  <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive"
                    data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <p class="text-muted">
                        Accessing your interact is a breeze! No need to clutter your phone with extra apps. eCard is
                        accessible
                        through your web browser on both desktop and mobile devices. You can effortlessly create,
                        customize,
                        and update your digital business card directly from your browser. It's all online, so you can
                        manage and host
                        your quiz from any device with internet access, no app required!
                      </p>
                    </div>
                  </div>
                </div>
                <div class="accordion-item mb-3">
                  <h2 class="accordion-header bg-white accordion-button collapsed" type="button"
                    data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false"
                    aria-controls="collapseSeven" id="headingSeven">
                    How Many Interact I Can Create?
                  </h2>
                  <div id="collapseSeven" class="accordion-collapse collapse" aria-labelledby="headingSeven"
                    data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <p class="text-muted">With a free account, you can create<b> n number of quizes </b>. If you
                        need more, consider upgrading to our additional plans. For more information, contact us at +91
                        9791
                        266 218</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-xs-12 col-sm-6 col-md-6 ">
            <div class="section ">
              <img src="../../../assets/img/FAQs.png" alt="image" class="img-fluid animated">
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="container-fluid mt-5" style="background-color: #ecf2ff;">
    <div class="row mb-5">
      <div class="col-md-6 col-12 text-center mt-5">
        <div style="padding:10px;">
          <h1 class="mt-5" style="color:#407BFF">Testimonial</h1>
          <h2 class="client-heading text-bold mt-3" >What our Client Say</h2>
          <p class="text-muted p-3">We pride ourselves on providing an exceptional service to
            our clients, but you don't just have to take our word for it. Read what our clients have to say about working with us.</p>
        </div>
      </div>
      <div class="col-md-6 col-12 mt-5 mb-5">
        <div class="image-container bg-white">
          <img src="../../../assets/img/testimonial/testimonal-suresh-kumar-aftc.png" alt="client-1"
            class="img-fluid" />
          <img src="../../../assets/img/testimonial/testi-bmp.png" class="img-fluid" alt="client-2" />
        </div>
      </div>
    </div>
    <div class="container-fluid row mt-5 mb-5" style="color: #ecf2ff; background-color: #3498db;"></div>
    <div class="container-fluid row mt-5 mb-5" style="color: #ecf2ff; background-color: #3498db;"></div>
    
    <div class="container-fluid row mt-5 mb-5" style="color: #ecf2ff; background-color: #3498db;"></div>
    <div class="container-fluid row mt-5 mb-5" style="color: #ecf2ff; background-color: #3498db;"></div>
    <div class="container-fluid row mt-5 mb-5" style="color: #ecf2ff; background-color: #3498db;"></div>
    <div class="container-fluid row mt-5 " style="color: #ecf2ff; background-color: #3498db;"></div>

  </section>
</div>


<div class="container-fluid p-0 " style="background-color: #ecf2ff;">

  <footer class="text-center text-white" style="background-color: #0046AA;">
    <!-- Grid container -->
    <div class="text-center text-dark p-3" style="background-color: #0046AA;">
      <span style="color: white;">Copyright &copy; {{ currentYear }} </span>
      <a href="https://varnikcloud.com/" style="color: white;margin: 0; text-decoration: none" target="_blank"><span
          class="text-light"> VarNik Systems Services Pvt. Ltd</span></a>
      <span>. </span>
      <span style="color: white;"> All rights reserved.</span>
    </div>
    <div class="text-center" style="background-color: #0046AA;">
      <span class="text-black mr-2 text-end" style="color: white;" type="button" [routerLink]="['/terms']">Terms &
        Conditions <span>|</span>
        <span class="text-black ml-1 text-end" type="button" style="color: white;" [routerLink]="['/privacy']">Privacy
          Policy</span></span>
    </div>
  </footer>
