import {Injectable} from "@angular/core";
import {HttpHeaders} from "@angular/common/http";

@Injectable({
    providedIn: 'root'
})
export class LocalDataService {

    //Authorization: Basic c3RyaW5nOnN0cmluZw==
    getHeaders() {
        return new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+ this.getToken(),
            'Access-Control-Allow-Origin': '*'
        });
    }


    signInHeaders() {
        return new HttpHeaders({
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        });
    }

    getToken() {
        return localStorage.getItem("authToken")
    }
}