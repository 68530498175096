<div class="container-fluid">
    <div class="row">
        <div class="col-md-6 d-flex align-items-start">
            <h4 class="mt-3"><i class="bi bi-house"></i> <span class="ml-3 font-italic">Dashboard</span></h4>
        </div>
        <div class="col-md-6 d-flex justify-content-end">
            <button class="mt-3 btn btn-primary" (click)="newSession()"><i class="bi bi-plus-circle-fill"></i>
                <span class="ml-3" style="font-family: 'Open Sans'">Create Session</span></button>
        </div>
    </div>
    <div class="container-fluid mt-4">
        <div class="row">
            <div class="col-lg-3 col-12">

                <div class="small-box bg-gradient-success">
                    <div class="inner">
                        <h6 class="font-italic text-bold text-white">Active Sessions</h6>
                        <circle-progress class="text-center"
                                         [percent]="activeSessions.length"
                                         [titleColor] = "'black'"
                                         [radius]="50"
                                         [showUnits]="false"
                                         [showSubtitle]="false"
                                         [responsive]=""
                                         [outerStrokeWidth]="8"
                                         [innerStrokeWidth]="4"
                                         [outerStrokeColor]="'#3d8608'"
                                         [innerStrokeColor]="'white'"
                                         [animation]="true"
                                         [animationDuration]="1000"
                        ></circle-progress>
                    </div>
                    <div class="icon">
                        <i class="ion ion-stats-bars text-white"></i>
                    </div>
                    <a (click)="navigate('Active')" role="button" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></a>
                </div>
            </div>


            <div class="col-lg-3 col-12">

                <div class="small-box bg-gradient-yellow">
                    <div class="inner">
                        <h6 class="font-italic text-bold text-white">Upcoming Sessions</h6>
                        <circle-progress class="text-center"
                                         [percent]="upcomingSessions.length"
                                         [titleColor] = "'black'"
                                         [radius]="50"
                                         [showUnits]="false"
                                         [showSubtitle]="false"
                                         [responsive]=""
                                         [outerStrokeWidth]="8"
                                         [innerStrokeWidth]="4"
                                         [outerStrokeColor]="'#99751b'"
                                         [innerStrokeColor]="'white'"
                                         [animation]="true"
                                         [animationDuration]="1000"
                        ></circle-progress>
                    </div>
                    <div class="icon">
                        <i class="ion ion-stats-bars text-white"></i>
                    </div>
                    <a (click)="navigate('Upcoming')" role="button" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></a>
                </div>
            </div>


            <div class="col-lg-3 col-12">

                <div class="small-box bg-gradient-danger">
                    <div class="inner">
                        <h6 class="font-italic text-bold text-white">Expired Sessions</h6>
                        <circle-progress class="text-center"
                                         [percent]="expiredSessions.length"
                                         [titleColor] = "'black'"
                                         [radius]="50"
                                         [showUnits]="false"
                                         [showSubtitle]="false"
                                         [responsive]=""
                                         [outerStrokeWidth]="8"
                                         [innerStrokeWidth]="4"
                                         [outerStrokeColor]="'#950416'"
                                         [innerStrokeColor]="'white'"
                                         [animation]="true"
                                         [animationDuration]="1000"
                        ></circle-progress>
                    </div>
                    <div class="icon">
                        <i class="ion ion-stats-bars text-white"></i>
                    </div>
                    <a (click)="navigate('Expired')" role="button" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></a>
                </div>
            </div>
        </div>
        <!--<div class="row d-flex justify-content-around">
            <div class="card col-md-2" role="button" (click)="navigate('Active')">
                <div class="row mt-2">
                    <h5 class="font-weight-bold" style="color: #6ec668;text-shadow: 2px 2px 5px #a8f6be;">Active</h5>
                </div>
                <circle-progress class="text-center"
                                 [percent]="activeSessions.length"
                                 [radius]="50"
                                 [showUnits]="false"
                                 [showSubtitle]="false"
                                 [responsive]="true"
                                 [outerStrokeWidth]="8"
                                 [innerStrokeWidth]="4"
                                 [outerStrokeColor]="'#78C000'"
                                 [innerStrokeColor]="'#C7E596'"
                                 [animation]="true"
                                 [animationDuration]="1000"
                ></circle-progress>
            </div>
            <div class="card col-md-2" role="button" (click)="navigate('Upcoming')">
                <div class="row mt-2">
                    <h5 class="font-weight-bold" style="color: #e8d03a;text-shadow: 2px 2px 5px #ffff99;">Upcoming</h5>
                </div>
                <circle-progress class="text-center"
                                 [percent]="upcomingSessions.length"
                                 [radius]="50"
                                 [showUnits]="false"
                                 [showSubtitle]="false"
                                 [responsive]="true"
                                 [outerStrokeWidth]="8"
                                 [innerStrokeWidth]="4"
                                 [outerStrokeColor]="'#eec606'"
                                 [innerStrokeColor]="'#efdf8a'"
                                 [animation]="true"
                                 [animationDuration]="1000"
                ></circle-progress>
            </div>
            <div class="card col-md-2" role="button" (click)="navigate('Expired')">
                <div class="row mt-2">
                    <h5 class="font-weight-bold" style="color: #ff3737;text-shadow: 2px 2px 5px #ff8484;">Expired</h5>
                </div>
                <circle-progress class="text-center"
                                 [percent]="expiredSessions.length"
                                 [radius]="50"
                                 [showUnits]="false"
                                 [showSubtitle]="false"
                                 [responsive]="true"
                                 [outerStrokeWidth]="8"
                                 [innerStrokeWidth]="4"
                                 [outerStrokeColor]="'#f61717'"
                                 [innerStrokeColor]="'#f39c9c'"
                                 [animation]="true"
                                 [animationDuration]="1000"
                ></circle-progress>
            </div>
        </div>-->
        <!--<div class="row d-flex justify-content-around">
            <div class="card col-md-2 bg-gradient-green" role="button" (click)="navigate('Active')">
                <div class="row mt-2">
                    <h5 class="font-weight-bold" style="color: black">Active</h5>
                </div>
                <circle-progress class="text-center"
                                 [percent]="activeSessions.length"
                                 [radius]="50"
                                 [showUnits]="false"
                                 [showSubtitle]="false"
                                 [responsive]="true"
                                 [outerStrokeWidth]="8"
                                 [innerStrokeWidth]="4"
                                 [outerStrokeColor]="'#f1f1f1'"
                                 [innerStrokeColor]="'#ffffff'"
                                 [animation]="true"
                                 [animationDuration]="1000"
                ></circle-progress>
            </div>
            <div class="card col-md-2 bg-gradient-yellow" role="button" (click)="navigate('Upcoming')">
                <div class="row mt-2">
                    <h5 class="font-weight-bold">Upcoming</h5>
                </div>
                <circle-progress class="text-center"
                                 [percent]="upcomingSessions.length"
                                 [radius]="50"
                                 [showUnits]="false"
                                 [showSubtitle]="false"
                                 [responsive]="true"
                                 [outerStrokeWidth]="8"
                                 [innerStrokeWidth]="4"
                                 [outerStrokeColor]="'#ffffff'"
                                 [innerStrokeColor]="'#ffffff'"
                                 [animation]="true"
                                 [animationDuration]="1000"
                ></circle-progress>
            </div>
            <div class="card col-md-2 bg-gradient-red" role="button" (click)="navigate('Expired')">
                <div class="row mt-2">
                    <h5 class="font-weight-bold" style="color: black">Expired</h5>
                </div>
                <circle-progress class="text-center"
                                 [percent]="expiredSessions.length"
                                 [radius]="50"
                                 [showUnits]="false"
                                 [showSubtitle]="false"
                                 [responsive]="true"
                                 [outerStrokeWidth]="8"
                                 [innerStrokeWidth]="4"
                                 [outerStrokeColor]="'#ffffff'"
                                 [innerStrokeColor]="'#ffffff'"
                                 [animation]="true"
                                 [animationDuration]="1000"
                ></circle-progress>
            </div>
        </div>-->
        <div class="row ml-0">
            <div class="card container col-lg-6 col-12 mt-4" style="background-color: #abc8f2">
                <div class="col-12 mb-4">
                    <canvas id="myChart">{{chart}}</canvas>
                </div>
            </div>
            <div class="col-lg-6 col-12"></div>
        </div>
        <div class="row mb-5"></div>
    </div>
</div>