

<!--<div *ngIf="waitForSession" class="container-fluid pt-3">
    <div class="p-3" style="background-color: #c8c8c9">
        <h2 class="text-center">Welcome to<br>{{sessionName}}</h2>
    </div>
    <div class="d-flex justify-content-center align-items-center mt-2" style="height: 500px">
        <h4 class="text-center">Please wait until<br>{{sessionName}} starts</h4>
    </div>
</div>-->


<!--Start State-->

<!--<div *ngIf="questionsShow" class="container-fluid pt-3">
    <form (ngSubmit)="onSubmit()" [formGroup]="sessionQuestions">
        <div class="p-3" style="background-color: #c8c8c9">
            <h2 class="text-center" style="background-color: #c8c8c9">{{sessionName}}</h2>
        </div>
        <div class="mt-4 ml-4">
            <h4 class="mb-3" formControlName="questionName">{{questionsData.questionName}}</h4>
            <div class="d-flex align-items-center ml-4">
                <input type="radio" formControlName="answer" [value]="questionsData.option1" class="mr-2 mb-2">
                <h5 class="mb-2">{{questionsData.option1}}</h5>
            </div>
            <div class="d-flex align-items-center ml-4">
                <input type="radio" formControlName="answer" [value]="questionsData.option2" class="mr-2 mb-2">
                <h5 class="mb-2">{{questionsData.option2}}</h5>
            </div>
            <div *ngIf="option3" class="d-flex align-items-center ml-4">
                <input type="radio" formControlName="answer" [value]="questionsData.option3" class="mr-2 mb-2">
                <h5 class="mb-2">{{questionsData.option3}}</h5>
            </div>
            <div *ngIf="option4" class="d-flex align-items-center ml-4">
                <input type="radio" formControlName="answer" [value]="questionsData.option4" class="mr-2 mb-2">
                <h5 class="mb-2">{{questionsData.option4}}</h5>
            </div>
        </div>
        <div class="mt-4 d-flex justify-content-center align-items-center">
            <button type="submit" class="btn btn-success">Submit</button>
        </div>
    </form>
</div>-->

<div class="container-fluid fixed-content" style="overflow-y: scroll">
    <div class="container mt-3 white border bg-light">
           <div class="header text-center">
               <img src="assets/img/interact%203.png" style="height: 60px" class="img-fluid mt-3">
           </div>


        <!--Init state-->
        <section class="section" *ngIf="waitForSession">
            <div class="row mt-3">
                <h5 class="text-center text-uppercase text-bold">Welcome to {{sessionName}}</h5>
            </div>
            <div class="row mt-3">
                <h6 class="text-center text-bold">Please wait until <span class="text-uppercase text-bold">{{sessionName}}</span> starts</h6>
            </div>
        </section>


        <!--Start state-->
        <section class="section" *ngIf="questionsShow">
            <form (ngSubmit)="onSubmit()" [formGroup]="sessionQuestions">
                <div class="row mt-2">
                    <h5 class="text-bold text-center text-uppercase">{{sessionName}}</h5>
                </div>
                <div class="row mt-2 ml-5">
                    <h4 class="mb-3" formControlName="question">{{questionsData.questionName}}</h4>
                </div>
                <div class="ml-5">
                    <div class="d-flex align-items-center ml-4">
                        <input type="radio" formControlName="answer" [value]="questionsData.option1" class="mr-2 mb-2 form-check-input">
                        <h5 class="mb-2">{{questionsData.option1}}</h5>
                    </div>
                    <div class="d-flex align-items-center ml-4">
                        <input type="radio" formControlName="answer" [value]="questionsData.option2" class="mr-2 mb-2 form-check-input">
                        <h5 class="mb-2">{{questionsData.option2}}</h5>
                    </div>
                    <div *ngIf="option3" class="d-flex align-items-center ml-4">
                        <input type="radio" formControlName="answer" [value]="questionsData.option3" class="mr-2 mb-2 form-check-input">
                        <h5 class="mb-2">{{questionsData.option3}}</h5>
                    </div>
                    <div *ngIf="option4" class="d-flex align-items-center ml-4">
                        <input type="radio" formControlName="answer" [value]="questionsData.option4" class="mr-2 mb-2 form-check-input">
                        <h5 class="mb-2">{{questionsData.option4}}</h5>
                    </div>
                </div>
                <div class="mt-4 d-flex justify-content-center align-items-center">
                    <button type="submit" class="btn btn-success">Submit</button>
                </div>
            </form>
        </section>

        <section class="section" *ngIf="picQuestionsShow">
            <form (ngSubmit)="onSubmit()" [formGroup]="sessionQuestions">
                <div class="row mt-2">
                    <h5 class="text-bold text-center text-uppercase">{{sessionName}}</h5>
                </div>
                <div class="row mt-2 ml-5">
                    <h4 class="mb-3" formControlName="question">{{questionsData.questionName}}</h4>
                </div>
                <div class="ml-5 card bg-light shadow-none" style="height: 330px; overflow-y: scroll; border: 1px">
                    <div class="d-flex align-items-center ml-4">
                        <input type="radio" formControlName="answer" value="Option 1" class="mr-2 mb-2 form-check-input">
<!--                        <h5 class="mb-2">{{questionsData.option1}}</h5>-->
                        <div class="card col-md-4" style="width: 132px">
                            <img [src]="option1Image" style="max-height: 100px; max-width: 100px" alt="option 1 image">
                        </div>
                    </div>
                    <div class="d-flex align-items-center ml-4">
                        <input type="radio" formControlName="answer" value="Option 2" class="mr-2 mb-2 form-check-input">
<!--                        <h5 class="mb-2">{{questionsData.option2}}</h5>-->
                        <div class="card col-md-4" style="width: 132px">
                            <img [src]="option2Image" style="max-height: 100px; max-width: 100px" alt="option 2 image">
                        </div>
                    </div>
                    <div *ngIf="option3" class="d-flex align-items-center ml-4">
                        <input type="radio" formControlName="answer" value="Option 3" class="mr-2 mb-2 form-check-input">
<!--                        <h5 class="mb-2">{{questionsData.option3}}</h5>-->
                        <div class="card col-md-4" style="width: 132px">
                            <img [src]="option3Image" style="max-height: 100px; max-width: 100px" alt="option 3 image">
                        </div>
                    </div>
                    <div *ngIf="option4" class="d-flex align-items-center ml-4">
                        <input type="radio" formControlName="answer" value="Option 4" class="mr-2 mb-2 form-check-input">
                        <!--<h5 class="mb-2">{{questionsData.option4}}</h5>-->
                        <div class="card col-md-4" style="width: 132px">
                            <img [src]="option4Image" style="max-height: 100px; max-width: 100px" alt="option 4 image">
                        </div>
                    </div>
                </div>
                <div class="mt-4 d-flex justify-content-center align-items-center mb-4">
                    <button type="submit" class="btn btn-success">Submit</button>
                </div>
            </form>
        </section>


        <!--After save-->
        <section class="section" *ngIf="waitForNextQuestion">
            <div class="row mt-3">
                <h5 class="text-center text-uppercase text-bold">Welcome to {{sessionName}}</h5>
            </div>
            <div class="row mt-3">
                <h6 class="text-center text-bold">Please wait for next question</h6>
            </div>
        </section>



        <!--session completed-->
        <section class="section" *ngIf="sessionCompleted">
            <div class="row mt-3">
                <h5 class="text-center text-uppercase text-bold">Thank You For Participate 🎉</h5>
            </div>
            <div class="row mt-3">
                <h6 class="text-center text-bold">It was a great session <br>
                    Please share your feedback @  <br>
                    <a href="https://interact.varnik.cloud">https://interact.varnik.cloud</a>
                </h6>
            </div>
        </section>
    </div>
</div>
<!--<app-footer></app-footer>-->
<!--<footer class="justify-content-center fixed-bottom" style="background-color: #abc8f2">
    &lt;!&ndash;<strong>
        <span>&copy; {{ currentYear }} </span>
        <a
                href="https://varnikcloud.com"
                rel="noopener noreferrer"
                style="margin: 0"
                target="_blank" class=""
        >VarNik Systems Services Private Limited</a
        >
        <span>.</span>
    </strong>
    <span> All rights reserved.</span>&ndash;&gt;

</footer>-->
<div class="footer">
    <div class="mt-3">
    <span>&copy; {{ currentYear }} </span>
    <a
            href="https://varnikcloud.com"
            rel="noopener noreferrer"
            style="margin: 0"
            target="_blank" class="text-dark"
    >VarNik Systems Services Private Limited</a
    >
    <span>.</span>
    <span> All rights reserved.</span>
    </div>
</div>




<!--<div *ngIf="waitForNextQuestion" class="container-fluid pt-3">
    <div class="p-3" style="background-color: #c8c8c9">
        <h2 class="text-center">Welcome to<br>{{sessionName}}</h2>
    </div>
    <div class="d-flex justify-content-center align-items-center mt-2" style="height: 500px">
        <h4 class="text-center">Please wait for<br>next question</h4>
    </div>
</div>-->


<!--Session Completed-->

<!--<div *ngIf="sessionCompleted" class="container-fluid pt-3">
    <div class="p-3" style="background-color: #c8c8c9">
        <h2 class="text-center">Thanks</h2>
    </div>
    <div class="d-flex justify-content-center align-items-center mt-2" style="height: 500px">
        <h4 class="text-center">It was a great session<br>Please share your feedbacks<br>@<br>
            <a href="https://interact.varnik.cloud">https://interact.varnik.cloud</a></h4>
    </div>
</div>-->
