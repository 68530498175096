import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {InteractSession} from '@modules/modal/interactSession';
import {AppService} from '@services/app.service';
import {Subject, takeUntil} from 'rxjs';
import {ToastrService} from "ngx-toastr";
import Chart from 'chart.js/auto';
import {DatePipe} from "@angular/common";

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

    getSessionDetails: InteractSession[] = [];
    activeSessions: InteractSession[] = [];
    upcomingSessions: InteractSession[] = [];
    expiredSessions: InteractSession[] = [];
    destroy$: Subject<boolean> = new Subject<boolean>();
    showShareOptions = false;
    public chart: any;


    constructor(private appService: AppService, private router: Router,
                private toaster: ToastrService, private datePipe: DatePipe) {

    }


    ngOnInit(): void {
        this.getSession();
    }

    getSession() {
        this.appService.getSessionDetails()
            .pipe(takeUntil(this.destroy$))
            .subscribe(data => {
                const filterByUser = data.filter(user => user.createdBy == localStorage.getItem('userId'))
                this.getSessionDetails = filterByUser;
                console.log("getSessionDetails::", this.getSessionDetails)
                const active: InteractSession[] = [];
                const upcoming: InteractSession[] = [];
                const expired: InteractSession[] = [];
                const currentDate = this.datePipe.transform(new Date().getTime(), "yyyy-MM-dd");
                const currentTime = this.datePipe.transform(new Date().getTime(), "HH:mm")
                for (let sort of this.getSessionDetails) {
                    const startDate = this.datePipe.transform(sort.sessionStartDate, "yyyy-MM-dd");
                    const endDate = this.datePipe.transform(sort.sessionEndDate, "yyyy-MM-dd");
                    if (startDate > currentDate) {
                        upcoming.push(sort)
                    } else if (endDate < currentDate) {
                        expired.push(sort)
                    } else if (startDate < currentDate && endDate > currentDate) {
                        active.push(sort)
                    } else if (startDate == currentDate && sort.sessionStartTime > currentTime) {
                        upcoming.push(sort)
                    } else if (startDate == currentDate && sort.sessionStartTime < currentTime) {
                        active.push(sort)
                    } else if (endDate == currentDate && sort.sessionEndTime < currentTime) {
                        expired.push(sort)
                    } else if (endDate == currentDate && sort.sessionEndTime > currentTime) {
                        active.push(sort)
                    } else if (startDate == currentDate && endDate == currentDate
                        && sort.sessionStartTime < currentTime && sort.sessionEndTime > currentTime) {
                        active.push(sort)
                    }
                }
                this.activeSessions = active;
                this.upcomingSessions = upcoming;
                this.expiredSessions = expired;
                this.createChart();
            }, (err: any) => {
                console.log("error:", err)
            })
    }

    newSession() {
        this.router.navigate(['/main/new-interact-session']);
    }

    navigate(state: string) {
        localStorage.setItem('sessionState', state);
        this.router.navigate(["/main/session-table"]);
    }

    close() {
        this.showShareOptions = false
    }

    createChart() {
        this.chart = new Chart("myChart", {
            type: 'pie', //this denotes tha type of chart

            data: {// values on X-Axis
                labels: ['Active', 'Upcoming', 'Expired'],
                datasets: [{
                    label: 'Count',
                    data: [this.activeSessions.length, this.upcomingSessions.length, this.expiredSessions.length],
                    backgroundColor: [
                        '#009900',
                        '#ffcc00',
                        '#b30000',
                    ],
                    hoverOffset: 4
                }],
            },
            options: {
                aspectRatio: 2.5
            }

        });
    }
}
