<head>
  <link href="https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;600;700;800&display=swap"
    rel="stylesheet">
</head>

<nav class="navbar navbar-expand-lg bg-light fixed-top">
  <div class="container-fluid">
    <a class="navbar-brand mr-auto" href="#">
      <img src="../../../assets/img/interact 3.png" height="50" alt="">
    </a>
    <button type="button" class="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
      <span class="navbar-toggler-icon"><i class="fa fa-bars" aria-hidden="true"></i></span>
    </button>
    <div class="collapse navbar-collapse justify-content-lg-end" id="navbarCollapse">
      <div class="navbar-nav text-bold">
        <a class="nav-link mx-2" href="#app-usecase">Use Cases</a>

        <a class="nav-link mx-2" href="#features">Features</a>
        <a class="nav-link mx-2" href="#how">Workflow</a>
        <a class="nav-link mx-2" href="#faq">FAQ</a>
      </div>
    </div>
  </div>
</nav>





<body>

  <div class="container ml-2" id="terms">
    <div class="row">
      <div class="col-lg-12 col-12">
        <h2>1. Acceptance of Terms</h2>
      </div>
      <div class="col-lg-12 col-12">
        <p>1.1 By accessing and using this website, you agree to be bound by these Terms and Conditions and our <a
            [routerLink]="['/privacy']">Privacy Policy</a>. If you do not agree, please do not use
          this website.</p>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-lg-12 col-12">
        <h2>2. Definitions</h2>
      </div>
      <div class="col-lg-12 col-12">
        <p>2.1 “Company” refers to VarNik Systems Services Pvt Ltd</p> <br>
        <p>2.2 “User” refers to any individual or entity accessing and using this website.</p>
      </div>
    </div>

    <div class="row mt-2">
      <div class="col-lg-12 col-12">
        <h2>3. Use of the Website</h2>
      </div>
      <div class="col-lg-12 col-12">
        <p>3.1 Users must be at least 18 years old to use this website.</p>
        <p>3.2 Users are responsible for maintaining the confidentiality of their account information.</p>
        <p>3.3 Users agree to provide accurate and complete information when using this website.</p>
        <p>3.4 The Company reserves the right to modify or discontinue any part of the website at any time without
          notice.</p>
      </div>
    </div>

    <div class="row mt-2">
      <div class="col-lg-12 col-12">
        <h2>4. Intellectual Property</h2>
      </div>
      <div class="col-lg-12 col-12">
        <p>4.1 All content on this website, including but not limited to text, graphics, logos, and images, is the
          property of
          the Company and is protected by copyright and other intellectual property laws.</p>
        <p>4.2 Users may not reproduce, distribute, or modify any content from this website without written permission
          from
          the Company.</p>
      </div>
    </div>

    <div class="row mt-2">
      <div class="col-lg-12 col-12">
        <h2>5. Privacy</h2>
      </div>
      <div class="col-lg-12 col-12">
        <p>5.1 User data is subject to our <a [routerLink]="['/privacy']">Privacy Policy</a>.</p>
      </div>
    </div>

    <div class="row mt-2">
      <div class="col-lg-12 col-12">
        <h2>6. Disclaimer of Warranties</h2>
        <p>6.1 The Company makes no warranties regarding the accuracy, completeness, or reliability of the information
          provided on this website.</p>
      </div>
      <div class="col-lg-12 col-12">
        <p>6.2 The Company disclaims any liability for any loss or damage resulting from the use of this website.</p>
      </div>
    </div>

    <div class="row mt-2">
      <div class="col-lg-12 col-12">
        <h2>7. Limitation of Liability</h2>
      </div>
      <div class="col-lg-12 col-12">
        <p>7.1 The Company shall not be liable for any direct, indirect, incidental, special, or consequential damages
          arising
          from the use of this website.</p>
      </div>
    </div>

    <div class="row mt-2">
      <div class="col-lg-12 col-12">
        <h2>8. Indemnification</h2>
      </div>
      <div class="col-lg-12 col-12">
        <p>8.1 Users agree to indemnify and hold the Company harmless from any claims, losses, or liabilities arising
          from
          their use of the website.</p>
      </div>
    </div>

    <div class="row mt-2">
      <div class="col-lg-12 col-12">
        <h2>9. Termination</h2>
      </div>
      <div class="col-lg-12 col-12">
        <p>9.1 The Company reserves the right to terminate a user’s access to the website at its discretion.</p>
      </div>
    </div>

    <div class="row mt-2">
      <div class="col-lg-12 col-12">
        <h2>10. Governing Law</h2>
      </div>
      <div class="col-lg-12 col-12">
        <p>10.1 These Terms and Conditions shall be governed by and construed in accordance with the laws of Indian
          Jurisdiction.</p>
      </div>
    </div>

    <div class="row mt-2">
      <div class="col-lg-12 col-12">
        <h2>11. Changes to Terms and Conditions</h2>
      </div>
      <div class="col-lg-12 col-12">
        <p>11.1 The Company reserves the right to update or modify these Terms and Conditions at any time. Users are
          encouraged to review this page periodically for changes.</p>
      </div>
    </div>

    <div class="row mt-2">
      <div class="col-lg-12 col-12">
        <h2>12. Contact Information</h2>
      </div>
      <div class="col-lg-12 col-12">
        <address>
          <p>12.1 For questions or concerns regarding these Terms and Conditions, please contact us at <a
              href="mailto:contact@msservicesinc.com">contact@msservicesinc.com</a></p>
        </address>
      </div>
    </div>

    <div class="row mt-2">
      <div class="col-lg-12 col-12">
        <p>By using this website, you acknowledge that you have read, understood, and agreed to these Terms and
          Conditions.</p>
      </div>
    </div>

    <div class="row mt-2">
      <div class="col-lg-12 col-12"></div>
      <div class="col-lg-12 col-12"></div>
    </div>
    <div class="row mt-2">
      <div class="col-lg-12 col-12"></div>
      <div class="col-lg-12 col-12"></div>
    </div>
  </div>
</body>

<footer class="text-center text-white" style="background-color: #0046AA;">
  <!-- Grid container -->
  <div class="text-center text-dark p-3" style="background-color: #0046AA;">
    <span style="color: white;">Copyright &copy; {{ currentYear }} </span>
    <a href="https://varnikcloud.com/" style="color: white;margin: 0; text-decoration: none" target="_blank"><span
        class="text-light"> VarNik Systems Services Pvt. Ltd</span></a>
    <span>. </span>
    <span style="color: white;"> All rights reserved.</span>
  </div>
  <div class="text-center" style="background-color: #0046AA;">
    <span class="text-black mr-2 text-end" style="color: white;" type="button" [routerLink]="['/terms']">Terms &
      Conditions <span>|</span>
      <span class="text-black ml-1 text-end" type="button" style="color: white;" [routerLink]="['/privacy']">Privacy
        Policy</span></span>
  </div>
</footer>