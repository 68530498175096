<!--<div *ngIf="waitForSession" class="container-fluid pt-3">
    <div class="p-3" style="background-color: #c8c8c9">
        <h2 class="text-center">Welcome to<br>{{sessionName}}</h2>
    </div>
    <div class="d-flex justify-content-center align-items-center mt-5">
        <h4 class="text-center">This is the QR for join the session</h4>
    </div>
    <div class="d-flex justify-content-center align-items-center mt-5">
        <canvas id="qrCanvas"></canvas>
    </div>
</div>

<div *ngIf="questionsShow" class="container-fluid pt-3">
    <div class="p-3" style="background-color: #c8c8c9">
        <h2 class="text-center" style="background-color: #c8c8c9">{{sessionName}}</h2>
    </div>
    <div class="mt-4 ml-4 d-flex justify-content-center align-items-center">
        <h4 class="mb-3">{{questionsData.questionName}}</h4>
    </div>
    <div class="row">
        <div class="col-md-5"></div>
        <div class="col-md-7">
            <div class="d-flex ml-4">
                <input type="radio" [value]="questionsData.option1" class="mr-2 mb-2">
                <h5 class="mb-2">{{questionsData.option1}}</h5>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-5"></div>
        <div class="col-md-7">
            <div class="d-flex ml-4">
                <input type="radio" [value]="questionsData.option2" class="mr-2 mb-2">
                <h5 class="mb-2">{{questionsData.option2}}</h5>
            </div>
        </div>
    </div>
    <div *ngIf="option3" class="row">
        <div class="col-md-5"></div>
        <div class="col-md-7">
            <div class="d-flex ml-4">
                <input type="radio" [value]="questionsData.option3" class="mr-2 mb-2">
                <h5 class="mb-2">{{questionsData.option3}}</h5>
            </div>
        </div>
    </div>
    <div *ngIf="option4" class="row">
        <div class="col-md-5"></div>
        <div class="col-md-7">
            <div class="d-flex ml-4">
                <input type="radio" [value]="questionsData.option4" class="mr-2 mb-2">
                <h5 class="mb-2">{{questionsData.option4}}</h5>
            </div>
        </div>
    </div>

    <div class="row mt-4"></div>
    <div class="row mt-4"></div>
    <div class="row mt-4"></div>
    <div class="row mt-4 d-flex justify-content-center">
        &lt;!&ndash;<div class="col-md-1"></div>&ndash;&gt;
        <div *ngIf="chartShow" class="card col-md-4 mt-4">
            &lt;!&ndash;<div class="mt-3 mb-3">
                <canvasjs-chart [options]="chartOptions"></canvasjs-chart>
            </div>&ndash;&gt;
            <div  class="chart-container mt-3">
                <h5 class="font-italic text-center">Total Answers: {{answersCount}}</h5>
                <canvas  id="barChart" formControlName="chart">{{ barCharts }}</canvas>
            </div>
        </div>
        <div class="col-md-1"></div>
        <div *ngIf="chartShow" class="card col-md-4 mt-4">
            &lt;!&ndash;<div class="mt-3 mb-3">
                <canvasjs-chart [options]="chartOptions"></canvasjs-chart>
            </div>&ndash;&gt;
            <div  class="chart-container mt-3">
                <h5 class="font-italic text-center">Total Answers: {{answersCount}}</h5>
                <canvas  id="pieChart" formControlName="chart">{{ pieCharts }}</canvas>
            </div>
        </div>
    </div>
</div>

<div *ngIf="sessionCompleted" class="container-fluid pt-3">
    <div class="p-3" style="background-color: #c8c8c9">
        <h2 class="text-center">Thanks</h2>
    </div>
    <div class="d-flex justify-content-center align-items-center mt-5">
        <h4 class="text-center">It was a great session<br>Please share your feedbacks</h4>
    </div>
    <div class="d-flex justify-content-center align-items-center mt-5">
        <canvas id="feedbackQr"></canvas>
    </div>
</div>-->

<div class="container-fluid" style="min-height: 91vh">
    <div class="row">
        <div class="container-fluid mt-3">
            <div class="header">
                <div class="row">
                    <div class="col-lg-3 col-12 text-center">
                        <img src="assets/img/interact%203.png" style="height: 50px" class="img-fluid mt-3">
                    </div>
                    <div class="col-lg-6 col-12">
                        <h3 class="text-center text-uppercase text-bold mt-4">Welcome to {{sessionName}}</h3>
                    </div>
                    <div class="col-lg-3 col-12">
                        <h5 class="text-center text-bold mt-4">Total Attendees- <span class="text-red">{{audienceCount}}</span></h5>
                    </div>
                </div>
            </div>
            <section class="content" *ngIf="waitForSession">
                <div class="row mt-5"></div>
                <div class="row mt-5"></div>
                <div class="row mt-3 text-center">
                    <h3 class="text-bold">Please scan the QR-Code for join the session</h3>
                </div>
                <div class="d-flex justify-content-center align-items-center mt-5">
                    <canvas id="qrCanvas"></canvas>
                </div>
            </section>

            <section class="content" *ngIf="questionsShow">
                <div class="container">
                    <div class="row mt-3">
                        <div class="col-12">
                            <h2 class="text-bold">{{questionsData.questionName}}</h2>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-xl-6 col-6">
                            <h5 class="mb-2">1. {{questionsData.option1}}</h5>
                        </div>
                        <div class="col-xl-6 col-6">
                            <h5 class="mb-2">2. {{questionsData.option2}}</h5>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-xl-6 col-6" *ngIf="option3">
                            <h5 class="mb-2">3. {{questionsData.option3}}</h5>
                        </div>
                        <div class="col-xl-6 col-6" *ngIf="option4">
                            <h5 class="mb-2">4. {{questionsData.option4}}</h5>
                        </div>
                    </div>
                </div>

                <div class="container-fluid  mt-5 mb-3">
                    <div class="row mt-4">
                        <!--<div class="col-md-1"></div>-->
                        <div *ngIf="chartShow" class="card col-lg-4 col-12 mt-4 bg-white">
                            <!--<div class="mt-3 mb-3">
                                <canvasjs-chart [options]="chartOptions"></canvasjs-chart>
                            </div>-->
                            <div  class="chart-container mt-3">
                                <h5 class="font-italic text-center">Total Answers: {{answersCount}}</h5>
                                <canvas  id="barChart">{{ barCharts }}</canvas>
                            </div>
                        </div>

                        <div *ngIf="chartShow" class="card col-lg-4 col-12 mt-4 bg-white">
                            <!--<div class="mt-3 mb-3">
                                <canvasjs-chart [options]="chartOptions"></canvasjs-chart>
                            </div>-->
                            <div  class="chart-container mt-3">
                                <h5 class="font-italic text-center">Total Answers: {{answersCount}}</h5>
                                <canvas  id="pieChart">{{ pieCharts }}</canvas>
                            </div>
                        </div>


                        <div *ngIf="chartShow" class="card col-lg-4 col-12 mt-4 bg-white">
                            <!--<div class="mt-3 mb-3">
                                <canvasjs-chart [options]="chartOptions"></canvasjs-chart>
                            </div>-->
                            <div  class="chart-container mt-3">
                                <h5 class="font-italic text-center">Total Answers: {{answersCount}}</h5>
                                <canvas  id="pieChartOption">{{ optionsChart }}</canvas>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="content mb-5" *ngIf="picQuestionsShow">
                <div class="container">
                    <div class="row mt-3">
                        <div class="col-12">
                            <h2 class="text-bold">{{questionsData.questionName}}</h2>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-xl-3 col-3">
                            <div class="row">
                            <div class="col-md-1 mb-2">1.</div>
                                <div class="card col-md-8">
                                <img [src]="option1Image" style="max-height: 150px; max-width: 150px" alt="option 1 image">
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-3">
                            <div class="row">
                                <div class="col-md-1 mb-2">2.</div>
                                <div class="card col-md-8">
                                    <img [src]="option2Image" style="max-height: 150px; max-width: 150px" alt="option 2 image"></div></div>
                        </div>
                        <div class="col-xl-3 col-3" *ngIf="option3">
                            <div class="row">
                                <div class="col-md-1 mb-2">3.</div>
                                <div class="card col-md-8">
                                    <img [src]="option3Image" style="max-height: 150px; max-width: 150px" alt="option 3 image">
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-3" *ngIf="option4">
                            <div class="row">
                                <div class="col-md-1 mb-2">4.</div>
                                <div class="card col-md-8">
                                    <img [src]="option4Image" style="max-height: 150px; max-width: 150px" alt="option 4 image">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="container-fluid  mt-5 mb-3">
                    <div class="row mt-4">
                        <!--<div class="col-md-1"></div>-->
                        <div *ngIf="chartShow" class="card col-lg-4 col-12 mt-4 bg-white">
                            <!--<div class="mt-3 mb-3">
                                <canvasjs-chart [options]="chartOptions"></canvasjs-chart>
                            </div>-->
                            <div  class="chart-container mt-3">
                                <h5 class="font-italic text-center">Total Answers: {{answersCount}}</h5>
                                <canvas  id="barChart">{{ barCharts }}</canvas>
                            </div>
                        </div>

                        <div *ngIf="chartShow" class="card col-lg-4 col-12 mt-4 bg-white">
                            <!--<div class="mt-3 mb-3">
                                <canvasjs-chart [options]="chartOptions"></canvasjs-chart>
                            </div>-->
                            <div  class="chart-container mt-3">
                                <h5 class="font-italic text-center">Total Answers: {{answersCount}}</h5>
                                <canvas  id="pieChart">{{ pieCharts }}</canvas>
                            </div>
                        </div>


                        <div *ngIf="chartShow" class="card col-lg-4 col-12 mt-4 bg-white">
                            <!--<div class="mt-3 mb-3">
                                <canvasjs-chart [options]="chartOptions"></canvasjs-chart>
                            </div>-->
                            <div  class="chart-container mt-3">
                                <h5 class="font-italic text-center">Total Answers: {{answersCount}}</h5>
                                <canvas  id="pieChartOption">{{ optionsChart }}</canvas>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="content" *ngIf="sessionCompleted">
                <div class="row mt-5">
                    <h3 class="text-center text-uppercase text-bold">Thank You For Attend this {{sessionName}}</h3>
                </div>
                <div class="row mt-3">
                    <h6 class="text-center text-bold">It was a great session <br>
                        Please share your feedback <br>
                        <div class="d-flex justify-content-center align-items-center mt-5">
<!--                            <canvas id="feedbackQr" [width]="300" [height]="300"></canvas>-->
                            <img [src]="feedbackQrUrl" style="height: 300px; width: 300px">
                        </div>
                    </h6>
                </div>
            </section>
        </div>
    </div>
</div>
<!--<app-footer></app-footer>-->
<div class="footer">
    <div class="mt-3">
        <span>&copy; {{ currentYear }} </span>
        <a
                href="https://varnikcloud.com"
                rel="noopener noreferrer"
                style="margin: 0"
                target="_blank" class="text-dark"
        >VarNik Systems Services Private Limited</a
        >
        <span>.</span>
        <span> All rights reserved.</span>
    </div>
</div>
<ngx-spinner bdColor="rgba(255, 255, 255, 1)" size="large" color="#FFFFFF"
             [fullScreen]="true">
    <div class="loader"></div>
</ngx-spinner>