import {Component, HostBinding, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {AppService} from '@services/app.service';
import {NgxSpinnerService} from "ngx-spinner";
import {Subject, takeUntil} from "rxjs";
import {LoginResponse} from "@services/loginResponse";
import {Router} from "@angular/router";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  @HostBinding('class') class = 'login-box';
  destroy$: Subject<boolean> = new Subject<boolean>();
  public userDetails: LoginResponse;
  public loginForm: UntypedFormGroup;
  public isAuthLoading = false;
  public isGoogleLoading = false;
  public isFacebookLoading = false;
  password: string;
  isPasswordVisible: boolean = false;
  firstValue: string = '';
  secondValue: string = '';
  thirdValue: string = '';
  fourthValue: string = '';
  otpModal = false;

  constructor(
    private renderer: Renderer2,
    private toastr: ToastrService,
    private appService: AppService,
    private spinner: NgxSpinnerService,
    private router: Router,
  ) {
  }

  otpForm = new UntypedFormGroup({
    phoneNumber: new UntypedFormControl("", Validators.required)
  })

  ngOnInit() {
    this.renderer.addClass(
      document.querySelector('app-root'),
      'login-page'
    );
    this.loginForm = new UntypedFormGroup({
      userName: new UntypedFormControl(null, Validators.required),
      password: new UntypedFormControl(null, Validators.required)
    });
  }

  public loginErrors = {
    userName: "",
    password:""
  }

  formValidation() {
    this.loginErrors.userName = "";
    this.loginErrors.password = "";
    let hasError = false;

    if (this.loginForm.get("userName")?.invalid) {
      this.loginErrors.userName = "Please Enter Your User Name";
      hasError = true;
    }
    if (this.loginForm.get("password")?.invalid) {
      if(this.loginForm.get('password').errors.required){
        this.loginErrors.password = "Please Enter Your Password";
        hasError = true;
      }
    }

    if(!hasError) {
      this.loginAuth();
    }
  }

  onSubmit() {
    this.formValidation();
  }

  ngOnDestroy() {
    this.renderer.removeClass(
      document.querySelector('app-root'),
      'login-page'
    );
  }

  togglePasswordVisibility() {
    const passwordShow = document.getElementById('password') as HTMLInputElement;
    if ('password' == passwordShow.type) {
      passwordShow.type = 'text';
    } else {
      passwordShow.type = 'password';
    }
    this.isPasswordVisible = !this.isPasswordVisible;
  }

  loginAuth() {
    this.spinner.show();
    this.appService.login(this.loginForm.value)
        .pipe(takeUntil(this.destroy$))
        .subscribe(data=>{
          console.log("Login User Details::", data);

          if(data != null) {
            this.userDetails = data;
            this.otpModal = true;
            this.otpForm.controls["phoneNumber"].setValue(this.loginForm.get('userName').value)
            return this.appService.sendPhoneNoOtp(this.otpForm.value)
                .pipe(takeUntil(this.destroy$))
                .subscribe(data => {
                  console.log("send otp :", data)
                  this.spinner.hide();
                }, (err: any) => {
                  console.log("something went wrong :", err)
                })
            /*localStorage.clear();
            this.router.navigate(['/main/dashboard']);
            localStorage.setItem('authToken', String(this.userDetails.authHeader));
            localStorage.setItem('userId', String(this.userDetails.loginUser.regId));
            this.toastr.success("Login Successfully...");*/
          }
        },(error: any)=> {
          this.spinner.hide();
          this.toastr.error("Username or Password is Wrong");
        })
  }

  //Only Enter Number Input box Event
  OnlyNumbers($event) {
    let regex: RegExp = new RegExp(/^[0-9]{1,}$/g);
    let specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'ArrowRight', 'ArrowLeft'];
    if (specialKeys.indexOf($event.key) !== -1) {
      return;
    } else {
      if (regex.test($event.key)) {
        return true;
      } else {
        return false;
      }
    }
  }

  moveFocus(nextInput: string) {
    const nextInputField = document.getElementById(nextInput) as HTMLInputElement;
    if (nextInputField) {
      nextInputField.focus();
    }
  }

  otpVerify() {
    let hasError = false;

    if (this.firstValue == "") {
      this.toastr.error("Please enter OTP")
      hasError = true;
    }
    if (this.secondValue == "") {
      this.toastr.error("Please enter OTP")
      hasError = true;
    }
    if (this.thirdValue == "") {
      this.toastr.error("Please enter OTP")
      hasError = true;
    }
    if (this.fourthValue == "") {
      this.toastr.error("Please enter OTP")
      hasError = true;
    }
    if (!hasError) {
      this.spinner.show();
      const otp = this.firstValue + this.secondValue + this.thirdValue + this.fourthValue
      console.log("otp verified..!", otp)
      return this.appService.verifyPhoneNoOtp(this.otpForm.get("phoneNumber").value, otp)
          .pipe(takeUntil(this.destroy$))
          .subscribe(data => {
            console.log("send otp :", data)
            this.spinner.hide()
            if (data.statusCode == 200) {
              this.toastr.success("OTP Verified..!")
              this.router.navigate(['/main/dashboard']);
              localStorage.setItem('authToken', String(this.userDetails.authHeader));
              localStorage.setItem('userId', String(this.userDetails.loginUser.regId));
              localStorage.setItem('userName', this.userDetails.registrationDetails.name);
              this.toastr.success("Login Successfully...");
            } else if (data.statusCode == 500) {
              this.toastr.error("OTP Not Matched")
            }
          }, (err: any) => {
            this.spinner.hide()
            console.log("something went wrong :", err)
          })
    }
  }
}
