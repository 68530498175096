<div class="container-fluid">
    <div class="row">
        <!--<h4 class="mt-3"><i class="bi bi-calendar2-week"></i><span class="ml-3" style="font-family: 'Open Sans'">Sessions</span></h4>-->
        <div class="col-md-6 d-flex align-items-start">
            <h4 class="mt-3"><i class="bi bi-calendar2-week"></i> <span class="ml-3 font-italic">Sessions</span></h4>
        </div>
    </div>

    <div class="row mt-3">
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-header">
                        <h3 class="card-title mt-2 text-bold" style="font-family: 'Open Sans'">All Sessions</h3>
                        <!--<div class="card-tools">
                        <button class="btn btn-success  " type="button" (click)="newSession()">Create Session</button>
                    </div>-->
                    </div>
                    <div class="card-body body table-responsive p-0" style="height: 350px;font-family: 'Open Sans';overflow-y: scroll">
                        <table class="table table-head-fixed text-nowrap">
                            <thead>
                            <tr class="text-bold">
                                <th>Session Name</th>
                                <th>Start Date</th>
                                <th>Start Time</th>
                                <th>End Date</th>
                                <th>End Time</th>
                                <th>Status</th>
                                <th>Session Start</th>
                                <th></th>
                            </tr>
                            <tbody class="" *ngFor="let get of this.sessionStatus">
                            <tr>
                                <!--<td>
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value=""
                                               [checked]="get.isChecked" (click)="selectCheckbox(get.sessionId, $event.target.checked)">
                                    </div>
                                </td>-->
                                <td>{{get.sessionName}}</td>
                                <td>{{formatDateFromEpoch(get.sessionStartDate)}}</td>
                                <td>{{get.sessionStartTime}}</td>
                                <td>{{formatDateFromEpoch(get.sessionEndDate)}}</td>
                                <td>{{get.sessionEndTime}}</td>
                                <td>{{get.sessionState}}</td>
                                <td><i class="fa fa-clipboard-check ms-2" type="button" title="click to copy URL" (click)="copyURL(get.sessionId)"  aria-hidden="true"></i>
                                    <i class="bi bi-share-fill ms-2 ml-4" type="button" title="click to share URL" (click)="toggleShareOptions(get.sessionId)"></i>
                                    <!--<i class="bi bi-cloud-download-fill ms-2 ml-4"></i>-->
                                    <i class="bi bi-collection-play-fill ms-2 ml-4" type="button" title="click to start {{get.sessionName}}" (click)="startSession(get.sessionId)"></i>
                                </td>
                                <td><button *ngIf="buttonShow" type="button" class="btn-info" (click)="editSession(get.sessionId)">Edit</button> </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <!-- /.card-body -->
                </div>
                <!-- /.card -->
            </div>
        </div>
    </div>
</div>

<ngx-spinner bdColor="rgba(255, 255, 255, 1)" size="large" color="#FFFFFF"
             [fullScreen]="true">
    <div class="loader"></div>
</ngx-spinner>

<div class="modal" tabindex="-1" role="dialog" [style.display]="showShareOptions ? 'block' : 'none'">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Social-media</h5>
                <button data-toggle="tooltip" data-placement="top" title="Close" type="button" class="close text-danger"
                        data-dismiss="modal" aria-label="Close" (click)="close()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="share-options ml-4">
                <p class="row">Link: <a href="{{ this.qrCodeData + this.sessionQrData }}" target="_blank">{{ this.qrCodeData + this.sessionQrData }}</a></p>
                <div class="social-links">
                    <a (click)="shareOnFacebook()"><i class="fab fa-facebook-f"></i></a>
                    <a (click)="shareOnTwitter()"><i class="fab fa-twitter"></i></a>
                    <a (click)="shareOnLinkedIn()"><i class="fab fa-linkedin-in"></i></a>
                    <a (click)="shareOnWhatsApp()"><i class="fab fa-whatsapp"></i></a>
                </div>
            </div>
            <div class="row mt-4"></div>
        </div>
    </div>
</div>