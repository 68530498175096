<!-- Brand Logo -->
<a [routerLink]="['/main/dashboard']" class="brand-link" style="background-color: #e8e8e8">
  <!--<pf-image
    alt="AdminLTE Logo"
    class="brand-image"
    height="33"
    rounded
    src="assets/img/logo.png"
    style="opacity: 0.8"
    width="33"
  ></pf-image>-->
  <span class="brand-text text-bold"><img src="assets/img/interact%203.png" style="height: 45px" class="img-fluid"></span>
</a>

<!-- Sidebar -->
<div class="sidebar">
  <!-- Sidebar user (optional) -->
  <div class="mt-3 pb-3 mb-3">
    <!--<div class="image">
      <pf-image
        [src]="user.picture"
        alt="User Image"
        class="img-circle"
        fallback-src="assets/img/default-profile.png"
        height="34"
        rounded
        width="34"
      ></pf-image>
    </div>
    <div class="info">
      <a [routerLink]="['/profile']" class="d-block">
        {{ user.email }}
      </a>
    </div>-->
  </div>

 <!-- <div class="form-inline">
    <app-sidebar-search></app-sidebar-search>
  </div>-->

  <!-- Sidebar Menu -->
  <nav class="mt-2" style="overflow-y: hidden">
    <ul
      class="nav nav-pills nav-sidebar flex-column nav-child-indent"
      data-accordion="false"
      data-widget="treeview"
      role="menu"
    >
      <app-menu-item
        *ngFor="let item of menu"
        [menuItem]="item"
      ></app-menu-item>
    </ul>
  </nav>
  <div class="">
    <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
      <li class="nav-item">
        <a class="nav-link dropdown-toggle" id="navbarDropdown" title="click to show Active sessions" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <i class="nav-icon fas fa-calendar text-green"></i>
          <p>
            Active Sessions
          </p>
        </a>
        <div *ngIf="activeSessionName.length > 0" class="dropdown-menu" style="background-color: #5e6268;">
          <a role="button" class="dropdown-item" [title]="item" *ngFor="let item of activeSessionName" (click)="getSessionId(item)">{{ item }}</a>
        </div>
      </li>
      <li class="nav-item">
        <a class="nav-link dropdown-toggle" id="navbarDropdownOne" title="click to show Upcoming sessions" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <i class="nav-icon fas fa-calendar text-yellow"></i>
          <p>
            Upcoming Sessions
          </p>
        </a>
        <div *ngIf="upcomingSessionName.length > 0" class="dropdown-menu" style="background-color: #5e6268;">
          <a role="button" class="dropdown-item" [title]="item" *ngFor="let item of upcomingSessionName" (click)="getSessionId(item)">{{ item }}</a>
        </div>
      </li>
      <li class="nav-item">
        <a class="nav-link dropdown-toggle" id="navbarDropdownTwo" title="click to show Expired sessions" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <i class="nav-icon fas fa-calendar text-red"></i>
          <p>
            Expired Sessions
          </p>
        </a>
        <div *ngIf="expiredSessionName.length > 0" class="dropdown-menu" style="background-color: #5e6268;">
          <a role="button" class="dropdown-item" [title]="item" *ngFor="let item of expiredSessionName" (click)="getSessionId(item)">{{ item }}</a>
        </div>
      </li>
    </ul>
  </div>
</div>
