import {Component, OnInit} from '@angular/core';
import {AppService} from "@services/app.service";
import {interval, Subject, Subscription, takeUntil} from "rxjs";
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {InteractSession} from "@modules/modal/interactSession";
import {ToastrService} from "ngx-toastr";
import {Router} from "@angular/router";
import {DatePipe} from "@angular/common";
import {QuestionResponse} from "@modules/modal/questionResponse";
import {InteractQuestions} from "@modules/modal/interactQuestions";
import {DateTime} from 'luxon';

@Component({
    selector: 'app-attendees-screens',
    templateUrl: './attendees-screens.component.html',
    styleUrls: ['./attendees-screens.component.scss']
})
export class AttendeesScreensComponent implements OnInit {
    public currentYear: string = DateTime.now().toFormat('y');
    questionResponse: QuestionResponse | undefined
    questionsData: InteractQuestions | undefined
    waitForSession = true;
    questionsShow = false;
    picQuestionsShow = false;
    waitForNextQuestion = false;
    sessionCompleted = false;
    // apiCallCount = 0;
    sessionName: string = "";
    sessionId: number = 0;
    option3 = false;
    option4 = false;
    option1Image: any;
    option2Image: any;
    option3Image: any;
    option4Image: any;

    private intervalSubscription: Subscription;

    destroy$: Subject<boolean> = new Subject<boolean>();

    constructor(private appService: AppService, private router: Router,
                private toaster: ToastrService, private datePipe: DatePipe) {
    }

    sessionQuestions = new UntypedFormGroup({
        sessionId: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        sessionName: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        questionId: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        question: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        isCorrect: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        answer: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        createdBy: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        createdAt: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        updatedBy: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        updatedAt: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        audienceOption: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    })

    ngOnInit() {
        this.sessionName = localStorage.getItem('sessionName')
        this.sessionId = Number(localStorage.getItem('sessionId'))
        this.getQuestion();
        this.intervalSubscription = interval(5000).subscribe(() => {
            this.getQuestion();
        });
    }

    ngOnDestroy() {
        // @ts-ignore
        this.destroy$.next();
        this.destroy$.complete();
        this.intervalSubscription.unsubscribe();
        localStorage.removeItem('questionId')
        localStorage.removeItem('answered')
    }

    getQuestion() {
        this.appService.getQuestion(this.sessionId)
            .pipe(takeUntil(this.destroy$))
            .subscribe(data => {
                console.log("question data :", data)
                if (data.optionType == "Text Option") {
                    this.questionsData = data;
                    if (this.questionsData.option3 != "") {
                        this.option3 = true;
                    } else if (this.questionsData.option3 == "") {
                        this.option3 = false;
                    }
                    if (this.questionsData.option4 != "") {
                        this.option4 = true;
                    } else if (this.questionsData.option4 == "") {
                        this.option4 = false;
                    }
                } else if (data.optionType == "Image Option") {
                    this.picShowQuestions(data);
                }
                const answered = localStorage.getItem('answered')
                if (this.questionsData.questionSeq != Number(localStorage.getItem('questionId'))) {
                    localStorage.removeItem('answered')
                    this.sessionQuestions.reset()
                    /*this.apiCallCount = Number(localStorage.getItem('qusCount'))
                    this.apiCallCount++
                    localStorage.setItem('qusCount', String(this.apiCallCount))*/
                    localStorage.setItem('questionId', String(this.questionsData.questionSeq))
                    this.waitForSession = false;
                    if (this.questionsData.optionType == "Text Option") {
                        this.questionsShow = true;
                        this.picQuestionsShow = false;
                    } else if (this.questionsData.optionType == "Image Option") {
                        this.picQuestionsShow = true;
                        this.questionsShow = false;
                    }
                    this.waitForNextQuestion = false;
                    this.sessionCompleted = false;
                } else if (this.questionsData.questionSeq == Number(localStorage.getItem('questionId')) && answered != "true") {
                    /*this.apiCallCount = Number(localStorage.getItem('qusCount'))*/
                    this.waitForSession = false;
                    if (this.questionsData.optionType == "Text Option") {
                        this.questionsShow = true;
                        this.picQuestionsShow = false;
                    } else if (this.questionsData.optionType == "Image Option") {
                        this.picQuestionsShow = true;
                        this.questionsShow = false;
                    }
                    this.waitForNextQuestion = false;
                    this.sessionCompleted = false;
                } else if (this.questionsData.questionSeq == Number(localStorage.getItem('questionId')) && answered == "true") {
                    this.waitForSession = false;
                    this.questionsShow = false;
                    this.picQuestionsShow = false;
                    this.waitForNextQuestion = true;
                    this.sessionCompleted = false;
                }
            }, (error: any) => {
                this.questionResponse = error
                console.log("error response:", this.questionResponse)
                if (this.questionResponse.error.statusCode == 404 && localStorage.getItem('questionId') != null) {
                    this.waitForSession = false;
                    this.questionsShow = false;
                    this.waitForNextQuestion = false;
                    this.sessionCompleted = true;
                    this.intervalSubscription.unsubscribe();
                    localStorage.removeItem('questionId')
                    // localStorage.removeItem('qusCount')
                    localStorage.removeItem('answered')
                } else if (this.questionResponse.error.statusCode == 404) {
                    this.waitForSession = true;
                    this.questionsShow = false;
                    this.waitForNextQuestion = false;
                    this.sessionCompleted = false;
                } else {
                    console.log("something wrong :", error)
                }
            })
        console.log("http request completed")
    }

    onSubmit() {
        let hasError = false
        if (this.sessionQuestions.get("answer").invalid) {
            this.toaster.error("Please select the answer")
            hasError = true;
        }

        if (!hasError) {
            this.saveAnswer()
        }
    }

    saveAnswer() {
        this.waitForSession = false;
        this.questionsShow = false;
        this.picQuestionsShow = false;
        this.waitForNextQuestion = true;
        this.sessionCompleted = false;

        if (this.questionsData.optionType == "Text Option") {
            if (this.sessionQuestions.get("answer").value == this.questionsData.option1) {
                this.sessionQuestions.controls["audienceOption"].setValue("A")
            } else if (this.sessionQuestions.get("answer").value == this.questionsData.option2) {
                this.sessionQuestions.controls["audienceOption"].setValue("B")
            } else if (this.sessionQuestions.get("answer").value == this.questionsData.option3) {
                this.sessionQuestions.controls["audienceOption"].setValue("C")
            } else if (this.sessionQuestions.get("answer").value == this.questionsData.option4) {
                this.sessionQuestions.controls["audienceOption"].setValue("D")
            }
        } else if (this.questionsData.optionType == "Image Option") {
            let option1 = this.questionsData.option1.match(/\/(\d+)_([a-zA-Z0-9_]+)(\.png|\.jpg|\.jpeg)$/);
            let option2 = this.questionsData.option2.match(/\/(\d+)_([a-zA-Z0-9_]+)(\.png|\.jpg|\.jpeg)$/);
            let option3 = this.questionsData.option3.match(/\/(\d+)_([a-zA-Z0-9_]+)(\.png|\.jpg|\.jpeg)$/);
            let option4 = this.questionsData.option4.match(/\/(\d+)_([a-zA-Z0-9_]+)(\.png|\.jpg|\.jpeg)$/);

            if (this.sessionQuestions.get("answer").value.replace(/\s+/g, '').toLowerCase() == option1[2]) {
                this.sessionQuestions.controls["audienceOption"].setValue("A")
            } else if (this.sessionQuestions.get("answer").value.replace(/\s+/g, '').toLowerCase() == option2[2]) {
                this.sessionQuestions.controls["audienceOption"].setValue("B")
            } else if (this.sessionQuestions.get("answer").value.replace(/\s+/g, '').toLowerCase() == option3[2]) {
                this.sessionQuestions.controls["audienceOption"].setValue("C")
            } else if (this.sessionQuestions.get("answer").value.replace(/\s+/g, '').toLowerCase() == option4[2]) {
                this.sessionQuestions.controls["audienceOption"].setValue("D")
            }
        }

        if (this.sessionQuestions.get("answer").value == this.questionsData.answer) {
            this.sessionQuestions.controls["isCorrect"].setValue(true)
        } else {
            this.sessionQuestions.controls["isCorrect"].setValue(false)
        }
        this.sessionQuestions.controls["sessionId"].setValue(this.questionsData.sessionId)
        this.sessionQuestions.controls["sessionName"].setValue(this.sessionName)
        this.sessionQuestions.controls["questionId"].setValue(this.questionsData.questionSeq)
        this.sessionQuestions.controls["question"].setValue(this.questionsData.questionName)
        this.sessionQuestions.controls["createdBy"].setValue(localStorage.getItem('audienceNo'))
        this.sessionQuestions.controls["updatedBy"].setValue("")
        console.log("answer :", this.sessionQuestions.value)
        this.appService.saveAnswer(this.sessionQuestions.value)
            .pipe(takeUntil(this.destroy$))
            .subscribe(data => {
                console.log("saveAnswer :", data)
                localStorage.setItem('answered', "true")
                this.sessionQuestions.reset()
            }, (err: any) => {
                console.log("something wrong :", err)
                this.sessionQuestions.reset()
            })
    }

    picShowQuestions(data: InteractQuestions) {
        this.questionsData = data;
        this.picQuestionsShow = true;
        this.appService.getFile(String(data.questionSeq), 'option1')
            .pipe(takeUntil(this.destroy$))
            .subscribe((blob) => {
                console.log("option 1 image :", blob);
                const reader = new FileReader();
                reader.onloadend = () => {
                    this.option1Image = reader.result;
                };
                reader.readAsDataURL(blob);
            }, (err: any) => {
                console.log("something went wrong :", err);
            });
        this.appService.getFile(String(data.questionSeq), 'option2')
            .pipe(takeUntil(this.destroy$))
            .subscribe((blob) => {
                console.log("option 1 image :", blob);
                const reader = new FileReader();
                reader.onloadend = () => {
                    this.option2Image = reader.result;
                };
                reader.readAsDataURL(blob);
            }, (err: any) => {
                console.log("something went wrong :", err);
            });
        if (data.option3 != "") {
            this.option3 = true;
            this.appService.getFile(String(data.questionSeq), 'option3')
                .pipe(takeUntil(this.destroy$))
                .subscribe((blob) => {
                    console.log("option 1 image :", blob);
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        this.option3Image = reader.result;
                    };
                    reader.readAsDataURL(blob);
                }, (err: any) => {
                    console.log("something went wrong :", err);
                });
        } else {
            this.option3 = false;
        }
        if (data.option4 != "") {
            this.option4 = true;
            this.appService.getFile(String(data.questionSeq), 'option4')
                .pipe(takeUntil(this.destroy$))
                .subscribe((blob) => {
                    console.log("option 1 image :", blob);
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        this.option4Image = reader.result;
                    };
                    reader.readAsDataURL(blob);
                }, (err: any) => {
                    console.log("something went wrong :", err);
                });
        } else {
            this.option4 = false;
        }
    }

}