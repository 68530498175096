<app-header></app-header>

<app-menu-sidebar class="sidebar-dark-primary" style="position: fixed"></app-menu-sidebar>

<div class="content-wrapper" style="min-height: 90vh">
  <router-outlet></router-outlet>
</div>

<!--<div class="image-gallery">
  <div class="image">
    <img src="assets/image1.jpg" alt="Image 1">
  </div>
  <div class="image">
    <img src="assets/image2.jpg" alt="Image 2">
  </div>
  <div class="image">
    <img src="assets/image3.jpg" alt="Image 3">
  </div>
  <div class="image">
    <img src="assets/image4.jpg" alt="Image 4">
  </div>
</div>-->



<app-footer></app-footer>
<app-control-sidebar></app-control-sidebar>
<div (click)="onToggleMenuSidebar()" id="sidebar-overlay"></div>
