import {AppState} from '@/store/state';
import {UiState} from '@/store/ui/state';
import {Component, HostBinding, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppService} from '@services/app.service';
import {Observable, Subject, takeUntil} from 'rxjs';
import {InteractSession} from "@modules/modal/interactSession";
import {Router} from "@angular/router";
import {DatePipe} from "@angular/common";

const BASE_CLASSES = 'main-sidebar elevation-4';

@Component({
    selector: 'app-menu-sidebar',
    templateUrl: './menu-sidebar.component.html',
    styleUrls: ['./menu-sidebar.component.scss']
})
export class MenuSidebarComponent implements OnInit {
    @HostBinding('class') classes: string = BASE_CLASSES;
    public ui: Observable<UiState>;
    public user;
    public menu = MENU;
    getSessionDetails: InteractSession[] = [];
    activeSessionName: string[] = [];
    upcomingSessionName: string[] = [];
    expiredSessionName: string[] = [];

    destroy$: Subject<boolean> = new Subject<boolean>();

    constructor(
        public appService: AppService, private router: Router,
        private store: Store<AppState>, private datePipe: DatePipe
    ) {
    }

    ngOnInit() {
        this.ui = this.store.select('ui');
        this.ui.subscribe((state: UiState) => {
            this.classes = `${BASE_CLASSES} ${state.sidebarSkin}`;
        });
        // this.user = this.appService.user;
        this.user = localStorage.getItem('userId');
        this.getSession();
    }

    getSession() {
        this.appService.getSessionDetails()
            .pipe(takeUntil(this.destroy$))
            .subscribe(data => {
                console.log("getSessionDetails::", data)
                const filterByUser = data.filter(user => user.createdBy == this.user)
                this.getSessionDetails = filterByUser;
                console.log("filter sessions :", this.getSessionDetails)
                const active: string[] = [];
                const upcoming: string[] = [];
                const expired: string[] = [];
                const currentDate = this.datePipe.transform(new Date().getTime(), "yyyy-MM-dd");
                const currentTime = this.datePipe.transform(new Date().getTime(), "HH:mm")
                for (let session of this.getSessionDetails) {
                    const startDate = this.datePipe.transform(session.sessionStartDate, "yyyy-MM-dd");
                    const endDate = this.datePipe.transform(session.sessionEndDate, "yyyy-MM-dd");
                    if (startDate > currentDate) {
                        upcoming.push(session.sessionName)
                    } else if (endDate < currentDate) {
                        expired.push(session.sessionName)
                    } else if (startDate < currentDate && endDate > currentDate) {
                        active.push(session.sessionName)
                    } else if (startDate == currentDate && session.sessionStartTime < currentTime) {
                        active.push(session.sessionName)
                    } else if (endDate == currentDate && session.sessionEndTime > currentTime) {
                        active.push(session.sessionName)
                    } else if (startDate == currentDate && session.sessionStartTime > currentTime) {
                        upcoming.push(session.sessionName)
                    } else if (endDate == currentDate && session.sessionEndTime < currentTime) {
                        expired.push(session.sessionName)
                    } else if (startDate == currentDate && endDate == currentDate
                        && session.sessionStartTime < currentTime && session.sessionEndTime > currentTime) {
                        active.push(session.sessionName)
                    }
                }
                this.activeSessionName = active;
                this.upcomingSessionName = upcoming;
                this.expiredSessionName = expired;
            }, (err: any) => {
                console.log("error:", err)
            })
    }

    getSessionId(name: string) {
        for (let item of this.getSessionDetails) {
            if (name == item.sessionName) {
                console.log("session id :", item.sessionId)
                localStorage.setItem('sideBarSessionId', String(item.sessionId))
                this.router.navigate(['/main/session-table']);
            }
        }
    }
}


export const MENU = [
    {
        name: 'Dashboard',
        iconClasses: 'fas fa-tachometer-alt',
        path: ['/main/dashboard'],
    },

];

