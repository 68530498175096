<div class="container-fluid">
    <div class="row">
        <div class="row mt-3 col-md-12">
            <h4 class=""><i class="bi bi-house"></i> <span class="ml-3 font-italic">Session Dashboard</span></h4>
        </div>
    </div>
        <div class="row mt-3 m-2 text-black">
            <div class="col-lg-8 col-12">
                <div class="row">
                    <div class="col-lg-3 col-3">
                        <h1><i class="bi bi-arrow-right-square-fill" [ngClass]="{ 'active': isInitiateIconActive }"></i></h1><h6
                            class="font-weight-bold">Initiate</h6>
                    </div>
                    <div class="col-lg-3 col-3">
                        <h1><i class="bi bi-arrow-right-square-fill" [ngClass]="{ 'active': isStartIconActive }"></i></h1><h6
                            class="font-weight-bold">Start</h6>
                    </div>
                    <div class="col-lg-3 col-3">
                        <h1><i class="bi bi-arrow-right-square-fill" [ngClass]="{ 'active': isProcessIconActive }"></i></h1><h6
                            class="font-weight-bold">Process</h6>
                    </div>
                    <div class="col-lg-3 col-3">
                        <h1><i class="bi bi-arrow-right-square-fill" [ngClass]="{ 'active': isEndIconActive }"></i></h1><h6
                            class="font-weight-bold">End</h6>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-12" *ngIf="registeredCard">
                <div class="card">
                    <div class="card-header">
                        <div class="row">
                        <h5 class="ml-2 font-weight-bolder mb-0 col-9">Registered People</h5>
                            <h5 class="col-2"><i class="bi bi-person-fill"></i>&nbsp;{{audienceCount}}</h5>
                        </div>
                    </div>
                    <div class="card-body body" style="max-height: 200px; overflow-y: scroll;">
                        <div *ngFor="let aud of audienceDetails; let i = index">
                        <p class="font-italic">{{i+1}}. {{aud.audienceName}} (reg ID: {{aud.audienceRegistrationSeq}})</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 text-center" [style.display]="this.isHideButton ?'block' : 'none'">
                <!--<button class="btn btn-success" (click)="changeInitiateState('start_session')">Initiate Session</button>-->
                <!-- Button trigger modal -->
                <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">
                    Initiate Session
                </button>

                <!-- Modal -->
                <div class="modal" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title fs-5" id="exampleModalLabel">Select Questions type</h5>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                <div class="row">
                                    <div class="col-6">
                                        <div class="form-check">
                                            <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" value="random" (change)="questionSequence($event)">
                                            <label class="form-check-label" for="flexRadioDefault1">
                                                Random
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="form-check">
                                            <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" value="sequence" (change)="questionSequence($event)">
                                            <label class="form-check-label" for="flexRadioDefault2">
                                                Sequence
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-success" data-bs-dismiss="modal" aria-label="Close" (click)="changeInitiateState('start_session')">Start</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    <div class="container-fluid mt-3" [style.display]="this.showStartQuestion? 'block' : 'none'">
        <div class="row mt-3 justify-content-around">
            <div class="col-12 col-lg-6">
                <div class="card" *ngIf="currentCard">
                    <div class="card-header">
                        <div class="row">
                        <h5 class="font-italic font-weight-bold col-md-8">Current Question</h5>
                        <!--<h6 class="font-italic col-md-4">Time:
                            <span class="text-red">{{formattedTime}}</span></h6>-->
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="m-2">
                            <h5 class="text-primary">{{currentQuestion}}</h5>
                            <div class= "mb-3">
                                <p>1. {{currentOption1}}</p>
                            </div>
                            <div class="mb-3">
                                <p>2. {{currentOption2}}</p>
                            </div>
                            <div *ngIf="option3" class="mb-3">
                                <p>3. {{currentOption3}}</p>
                            </div>
                            <div *ngIf="option4" class="mb-3">
                                <p>4. {{currentOption4}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-12" *ngIf="nextCard">
                <div class="card">
                    <div class="card-header">
                        <h5 class="font-italic font-weight-bold">Next Question</h5>
                        <!-- <h4 class="font-weight-bold col-md-6">Time:
                             <span class="text-red">{{formattedTime}}</span></h4>-->
                    </div>
                    <div class="card-body">
                        <div class="m-2">
                            <h5 class="text-blue">{{nextQuestion}}</h5>
                            <div class="form-check mb-3">
                                <p>1. {{nextOption1}}</p>
                            </div>
                            <div class="form-check mb-3">
                                <p>2. {{nextOption2}}</p>
                            </div>
                            <div *ngIf="option3Visible" class="form-check mb-3">
                                <p>3. {{nextOption3}}</p>
                            </div>
                            <div *ngIf="option4Visible" class="form-check mb-3">
                                <p>4. {{nextOption4}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-12" *ngIf="isRandomSeq">
                <div class="card">
                    <div class="card-header">
                        <h6 class="font-italic font-weight-bold">Your Session Questions</h6>
                    </div>
                    <!--<div class="card-body">
                        <div class="row">
                            <div style="overflow-y: auto; max-height: 300px" class="scroll">
                                <div class="container bg-light" type="button" *ngFor="let qus of questionsData">
                                    <div class="" [title]="[qus.option1, qus.option2, qus.option3, qus.option4]" (click)="questionStateUpdate(qus.questionSeq)">
                                        <h6 class="text-blue">{{qus.questionName}}</h6>
                                        &lt;!&ndash;<p>{{qus.option1}}</p>
                                        <p>{{qus.option2}}</p>
                                        <p>{{qus.option3}}</p>
                                        <p>{{qus.option4}}</p>&ndash;&gt;
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>-->
                    <div class="card-body scroll" style="overflow-y: auto; max-height: 300px">
                        <div class="row">
                            <div class="col-12">
                                <div>
                                    <div class="">
                                        <div class="row mt-1" *ngFor="let qus of questionsData; let i = index">
                                            <div class="accordion" id="accordionExample">
                                                <div class="accordion-item mb-3">
                                                    <h2 class="accordion-header">
                                                        <span class="accordion-button"  id="heading{{i}}" type="button"[attr.aria-expanded]="i === currentlyOpenItemIndex ? 'true' : 'false'"
                                                            (click)="toggleAccordion(i)">
                                                            <span (click)="questionStateUpdate(qus.questionSeq)">{{qus.questionName}}</span>
                                                        </span>
                                                    </h2>
                                                    <div [id]="'collapse' + i" class="accordion-collapse collapse"
                                                         [ngClass]="{'show': i === currentlyOpenItemIndex}">
                                                        <div class="accordion-body">
                                                            <div class="row">
                                                                <p class="text-muted col">1. {{qus.option1}}</p>
                                                                <p class="text-muted col">2. {{qus.option2}}</p>
                                                            </div>
                                                            <div class="row">
                                                                <p class="text-muted col" *ngIf="qus.option3 != ''">3. {{qus.option3}}</p>
                                                                <p class="text-muted col" *ngIf="qus.option4 != ''">4. {{qus.option4}}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-12 mt-4 mb-5  d-flex justify-content-center">
            <button *ngIf="endSession" type="button" class="btn btn-success" (click)="changeInitiateState('next_question')">
                {{isSessionEnd == false ? "Next Question" : "End Session"}}
            </button>
            <button *ngIf="endSessionRandom" type="button" class="btn btn-success" (click)="changeInitiateState('end_session')">
                End Session
            </button>
        </div>
    </div>
    <!--<div class="container-fluid mt-3" [style.display]="this.showStartQuestion? 'block' : 'none'">
        <div class="row mt-3 justify-content-around">
            <div *ngIf="currentCard" class="card col-md-5">
                <div class="row mt-2 card-header">
                    <h4 class="font-italic font-weight-bold col-md-8">Current Question</h4>
                    <h4 class="card-title font-weight-bold col-md-4">Time:
                        <span class="text-red">{{formattedTime}}</span></h4>
                </div>
                <div class="card-body m-2">
                    <h5 class="font-weight-bolder">{{currentQuestion}}</h5>
                    <div class="form-check mb-3">
                        <input class="form-check-input" type="radio">
                        <label class="form-check-label">{{currentOption1}}</label>
                    </div>
                    <div class="form-check mb-3">
                        <input class="form-check-input" type="radio"/>
                        <label class="form-check-label">{{currentOption2}}</label>
                    </div>
                    <div *ngIf="option3" class="form-check mb-3">
                        <input class="form-check-input" type="radio"/>
                        <label class="form-check-label">{{currentOption3}}</label>
                    </div>
                    <div *ngIf="option4" class="form-check mb-3">
                        <input class="form-check-input" type="radio"/>
                        <label class="form-check-label">{{currentOption4}}</label>
                    </div>
                </div>
            </div>
&lt;!&ndash;            <div class="col-md-1"></div>&ndash;&gt;
            <div *ngIf="nextCard" class="card col-md-5">
                <div class="card-header row mt-2">
                    <h4 class="font-italic font-weight-bold">Next Question</h4>
                </div>
                <div class="card-body m-2">
                    <h5 class="font-weight-bolder">{{nextQuestion}}</h5>
                    <div class="form-check mb-3">
                        <input class="form-check-input" type="radio" name="exampleForm" id="radioExample1"/>
                        <label class="form-check-label" for="radioExample1">{{nextOption1}}
                        </label>
                    </div>
                    <div class="form-check mb-3">
                        <input class="form-check-input" type="radio" name="exampleForm" id="radioExample2"/>
                        <label class="form-check-label" for="radioExample2">{{nextOption2}}
                        </label>
                    </div>
                    <div *ngIf="option3Visible" class="form-check mb-3">
                        <input class="form-check-input" type="radio" name="exampleForm" id="radioExample3"/>
                        <label class="form-check-label" for="radioExample3">{{nextOption3}}
                        </label>
                    </div>
                    <div *ngIf="option4Visible" class="form-check mb-3">
                        <input class="form-check-input" type="radio" name="exampleForm" id="radioExample4"/>
                        <label class="form-check-label" for="radioExample3">{{nextOption4}}
                        </label>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-3">
                    &lt;!&ndash;<div *ngIf="timeCard" class="col-md-3">
                        <div class="card text-center">
                            <div class="card-body">
                                <h2 class="card-title font-weight-bold">Time:</h2>
                                <h4 class="card-text font-weight-bold text-red">{{formattedTime}}</h4>
                            </div>
                        </div>
                    </div>&ndash;&gt;
                    <div class="col-md-12 mt-4 d-flex justify-content-center">
                        <button type="button" class="btn btn-success" (click)="changeInitiateState('next_question')">
                            {{isSessionEnd == false ? "Next Question" : "End Session"}}
                        </button>
                    </div>
        </div>
        <div class="row mt-3"></div>
    </div>-->
</div>