import {Component, OnInit, Renderer2} from "@angular/core";
import {interval, Subject, Subscription, takeUntil} from "rxjs";
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {ToastrService} from "ngx-toastr";
import {AppService} from "@services/app.service";
import {ActivatedRoute, Router} from "@angular/router";
import {NgxSpinnerService} from "ngx-spinner";
import {State} from "@modules/modal/state";
import {InteractQuestions} from "@modules/modal/interactQuestions";
import Swal from "sweetalert2";
import {AudienceRegister} from "@modules/modal/audienceRegister";
import {AddQuestion} from "@modules/modal/addQuestion";

@Component({
    selector: 'app-session',
    templateUrl: './session.component.html',
    styleUrls: ['./session.component.scss']
})

export class SessionComponent implements OnInit {
    destroy$: Subject<boolean> = new Subject<boolean>();
    isInitiateIconActive = false;
    isStartIconActive = false;
    isProcessIconActive = false;
    isEndIconActive = false;
    isHideButton = false;
    timeLeft: number = 0;
    formattedTime: string = '00:00'
    interval: any;
    stateStatus: State | undefined;
    questionsData: InteractQuestions[] = [];
    showStartQuestion = false;
    currentQuestion: string = "";
    currentOption1: string = "";
    currentOption2: string = "";
    currentOption3: string = "";
    currentOption4: string = "";
    currentQuestionSeq: number = 0;
    nextQuestion: string = ""
    nextOption1: string = ""
    nextOption2: string = ""
    nextOption3: string = ""
    nextOption4: string = ""
    isSessionEnd = false;
    nextCard = true;
    currentCard = true;
    timeCard = true;
    option3 = false;
    option4 = false;
    option3Visible = false;
    option4Visible = false;
    registeredCard = false;
    endSession = true;
    endSessionRandom = false;
    audienceDetails: AudienceRegister[] = [];
    audienceCount: number = 0;
    questionSeqType: string = "";
    isRandomSeq = false;
    showOptions = false;
    currentlyOpenItemIndex: number = -1;

    private intervalSubscription: Subscription;

    constructor(private renderer: Renderer2,
                private toastr: ToastrService,
                private appService: AppService,
                private router: Router,
                private spinner: NgxSpinnerService,
                private route: ActivatedRoute) {
    }

    sessionState = new UntypedFormGroup({
        session_id: new UntypedFormControl(null, Validators.required),
        action: new UntypedFormControl(null, Validators.required),
    });

    questionState = new UntypedFormGroup({
        questionSeq: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        questionName: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        questionType: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        option1: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        option2: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        option3: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        option4: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        answer: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        sessionId: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        questionState: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        sessionDate: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        createdAt: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        createdBy: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        updatedAt: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        updatedBy: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    });

    ngOnInit() {
        this.isInitiateIconActive = true;
        this.isHideButton = true;
        this.getQuestion();
        this.intervalSubscription = interval(5000).subscribe(() => {
            this.getRegisteredDetails();
        });
    }

    ngOnDestroy(): void {
        this.clearTimer();
        this.intervalSubscription.unsubscribe();
    }

    toggleAccordion(index: number) {
        if (index === this.currentlyOpenItemIndex) {
            this.currentlyOpenItemIndex = -1; // Close the current item if it's already open
        } else {
            this.currentlyOpenItemIndex = index; // Open the clicked item
        }
    }


    questionSequence(event) {
        this.questionSeqType = event.target.value;
        console.log("value::", this.questionSeqType)
        if(this.questionSeqType == 'random') {
            this.isRandomSeq = true;
            this.endSessionRandom = true;
            console.log("random card :", this.isRandomSeq)
            this.currentCard = false;
            this.nextCard = false;
            this.endSession = false;
        }
    }

    changeInitiateState(state: string) {
        this.clearTimer();
        if (state == "next_question") {
            this.isProcessIconActive = true;
            this.isStartIconActive = false;
        }
        if (state == "start_session") {
            this.isStartIconActive = true;
            this.isInitiateIconActive = false;
            this.isHideButton = false;
            this.registeredCard = true;
        }
        this.sessionState.controls["session_id"].setValue(Number(localStorage.getItem('sessionId')));
        this.sessionState.controls["action"].setValue(state);
        this.showStartQuestion = true;
        if (this.questionSeqType == "sequence") {
            this.appService
                .startSessionStatus(this.sessionState.value)
                .pipe(takeUntil(this.destroy$))
                .subscribe(data => {
                    console.log("set state::", data)
                    this.getQuestion();
                    this.startTimer();
                    /*if (state == "next_question" && this.nextCard == false) {
                        this.currentCard = false;
                        this.timeCard = false;
                        this.endSession = false;
                        this.sessionEnd();
                        this.clearTimer();
                    }*/
                    // @ts-ignore
                    this.stateStatus = data;
                }, (err: any) => {
                    console.log("something went wrong:", err)
                    if (err.status == 404) {
                        this.currentCard = false;
                        this.timeCard = false;
                        this.endSession = false;
                        this.isProcessIconActive = false;
                        this.intervalSubscription.unsubscribe();
                        this.sessionEnd();
                        this.clearTimer();
                        this.router.navigate(['/main/dashboard'])
                    }
                });
        } else if (this.questionSeqType == "random" && state == "end_session") {
            /*this.appService
                .startSessionStatus(this.sessionState.value)
                .pipe(takeUntil(this.destroy$))
                .subscribe(data => {
                    console.log("set state::", data)
                }, (err: any) => {
                    console.log("something went wrong:", err)
                });*/
            this.sessionEndConfirm();
        }
    }

    getQuestion() {
        this.appService.getAllQuestions(Number(localStorage.getItem('sessionId')))
            .pipe(takeUntil(this.destroy$))
            .subscribe(data => {
                console.log("question data :", data)
                this.questionsData = data;
                if(this.questionSeqType == 'sequence') {
                    for (let qus of this.questionsData) {
                        if (qus.questionState == "Active" && qus.optionType == "Text Option") {
                            this.currentQuestion = qus.questionName;
                            this.currentOption1 = qus.option1
                            this.currentOption2 = qus.option2
                            if (qus.option3 != "") {
                                this.currentOption3 = qus.option3
                                this.option3 = true;
                            } else if (qus.option3 == "") {
                                this.option3 = false;
                            }
                            if (qus.option4 != "") {
                                this.currentOption4 = qus.option4
                                this.option4 = true;
                            } else if (qus.option4 == "") {
                                this.option4 = false;
                            }
                            this.currentQuestionSeq = qus.questionSeq
                        } else if (qus.questionState == "Active" && qus.optionType == "Image Option") {
                            this.currentQuestion = qus.questionName;
                            this.currentOption1 = "Image Option 1"
                            this.currentOption2 = "Image Option 2"
                            if (qus.option3 != "") {
                                this.currentOption3 = "Image Option 3"
                                this.option3 = true;
                            } else if (qus.option3 == "") {
                                this.option3 = false;
                            }
                            if (qus.option4 != "") {
                                this.currentOption4 = "Image Option 4"
                                this.option4 = true;
                            } else if (qus.option4 == "") {
                                this.option4 = false;
                            }
                            this.currentQuestionSeq = qus.questionSeq
                        }
                        if (qus.questionState == "InActive" && qus.questionSeq == Number(this.currentQuestionSeq + 1) && qus.optionType == "Text Option") {
                            this.nextQuestion = qus.questionName;
                            this.nextOption1 = qus.option1
                            this.nextOption2 = qus.option2
                            if (qus.option3 != "") {
                                this.nextOption3 = qus.option3
                                this.option3Visible = true;
                            } else if (qus.option3 == "") {
                                this.option3Visible = false;
                            }
                            if (qus.option4 != "") {
                                this.nextOption4 = qus.option4
                                this.option4Visible = true;
                            } else if (qus.option4 == "") {
                                this.option4Visible = false;
                            }
                        } else if (qus.questionState == "InActive" && qus.questionSeq == Number(this.currentQuestionSeq + 1) && qus.optionType == "Image Option") {
                            this.nextQuestion = qus.questionName;
                            this.nextOption1 = "Image Option 1"
                            this.nextOption2 = "Image Option 2"
                            if (qus.option3 != "") {
                                this.nextOption3 = "Image Option 3"
                                this.option3Visible = true;
                            } else if (qus.option3 == "") {
                                this.option3Visible = false;
                            }
                            if (qus.option4 != "") {
                                this.nextOption4 = "Image Option 4"
                                this.option4Visible = true;
                            } else if (qus.option4 == "") {
                                this.option4Visible = false;
                            }
                        }
                    }
                    if (this.currentQuestion == this.nextQuestion) {
                        this.nextCard = false;
                        this.isSessionEnd = true;
                        this.isEndIconActive = true;
                        this.isProcessIconActive = false;
                    }
                    if (this.nextQuestion == "") {
                        this.nextCard = false;
                        this.isSessionEnd = true;

                    }
                } else if (this.questionSeqType == "random") {
                    for (let qus of this.questionsData) {
                        if (qus.questionState == "Active") {
                            this.currentQuestion = qus.questionName
                            this.currentOption1 = qus.option1
                            this.currentOption2 = qus.option2
                            if (qus.option3 != "") {
                                this.currentOption3 = qus.option3
                                this.option3 = true;
                            } else if (qus.option3 == "") {
                                this.option3 = false;
                            }
                            if (qus.option4 != "") {
                                this.currentOption4 = qus.option4
                                this.option4 = true;
                            } else if (qus.option4 == "") {
                                this.option4 = false;
                            }
                            this.currentQuestionSeq = qus.questionSeq
                        }
                    }
                }
            }, (error: any) => {
                console.log("something went wrong")
            })
    }

    sessionEnd() {
        Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Your session is Ended',
            showConfirmButton: false,
            timer: 1500
        })
    }

    sessionEndConfirm() {
        Swal.fire({
            title: 'Are you sure?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        }).then((result) => {
            if (result.isConfirmed) {
                this.appService
                    .startSessionStatus(this.sessionState.value)
                    .pipe(takeUntil(this.destroy$))
                    .subscribe(data => {
                        console.log("set state::", data)
                        this.sessionEnd();
                        this.router.navigate(['/main/dashboard'])
                    }, (err: any) => {
                        console.log("something went wrong:", err)
                    });
            }
        })
    }

    startTimer(): void {
        this.interval = setInterval(() => {
                this.timeLeft++;
                this.formattedTime = this.formatTimer(this.timeLeft)
        }, 1000);
    }

    formatTimer(seconds: number): string {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;

        const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes.toString();
        const formattedSeconds = remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds.toString();

        return `${formattedMinutes}:${formattedSeconds}`;
    }

    clearTimer(): void {
        clearInterval(this.interval);
        this.timeLeft = 0;
    }

    getRegisteredDetails() {
        return this.appService.getRegisteredDetails(Number(localStorage.getItem('sessionId')))
            .pipe(takeUntil(this.destroy$))
            .subscribe(data => {
                console.log("registered people :", data)
                const currentTime = new Date().getTime()
                const onHourBeforeTime = currentTime - 1800000;
                const filterValues = data.filter(time => time.createdAt <= currentTime && time.createdAt >= onHourBeforeTime)
                console.log("filtered data :", filterValues)
                this.audienceDetails = filterValues;
                this.audienceCount = this.audienceDetails.length;
            }, (error: any) => {
                console.log("something went wrong :", error)
            })
    }

    questionStateUpdate(id : number) {
        for (let qus of this.questionsData) {
            if (id == qus.questionSeq) {
                this.questionState.controls["questionName"].setValue(qus.questionName)
                this.questionState.controls["questionSeq"].setValue(qus.questionSeq)
                this.questionState.controls["questionState"].setValue('Active')
                this.questionState.controls["createdAt"].setValue(qus.createdAt)
                this.questionState.controls["createdBy"].setValue(qus.createdBy)
                this.questionState.controls["updatedAt"].setValue(qus.updatedAt)
                this.questionState.controls["updatedBy"].setValue(qus.updatedBy)
                this.questionState.controls["sessionId"].setValue(qus.sessionId)
                this.questionState.controls["sessionDate"].setValue(qus.sessionDate)
                this.questionState.controls["answer"].setValue(qus.answer)
                this.questionState.controls["questionType"].setValue(qus.questionType)
                this.questionState.controls["option1"].setValue(qus.option1)
                this.questionState.controls["option2"].setValue(qus.option2)
                this.questionState.controls["option3"].setValue(qus.option3)
                this.questionState.controls["option4"].setValue(qus.option4)
                return this.appService.updateExistQuestion(this.questionState.value)
                    .pipe(takeUntil(this.destroy$))
                    .subscribe(data => {
                        console.log("State updated :", data)
                        this.currentCard = true;
                        this.getQuestion();
                    }, (err: any) => {
                        console.log("something went wrong :", err)
                    })
            }
        }
    }

}