import {Component, OnInit} from '@angular/core';
import {AppService} from "@services/app.service";
import {Subject, takeUntil} from "rxjs";
import {FormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {InteractSession} from "@modules/modal/interactSession";
import {ToastrService} from "ngx-toastr";
import {Router} from "@angular/router";
import {DatePipe} from "@angular/common";
import {NgxSpinnerService} from "ngx-spinner";
import {NewQuestions} from "@modules/modal/newQuestions";
import Swal from "sweetalert2";
import {AddQuestion} from "@modules/modal/addQuestion";
import {data} from "browserslist";

@Component({
    selector: 'app-create-interact-sessions',
    templateUrl: './create-interact-sessions.component.html',
    styleUrls: ['./create-interact-sessions.component.scss']
})
export class CreateInteractSessionsComponent implements OnInit {
    currentInteractSession: InteractSession | undefined;
    saveNewQuestion: NewQuestions[] = [];
    minDate: string | null;
    showTwoOptions = false;
    showThreeOptions = false;
    showFourOptions = false;
    options: string[] = [];
    sessionId: number = 0
    addQuestionsData:AddQuestion[] = [];
    showQuestionsData:AddQuestion[] = [];
    buttonShow = false;
    sessionCreate = true;
    addIcon = false;
    isUpdate = false;
    isManage = false;
    isHide = true;
    updateSession: InteractSession[] = [];
    questionBoxShow = false;
    pictureBoxShow = false;
    titleEdit = false;
    option1FileList: File[] = [];
    option2FileList: File[] = [];
    option3FileList: File[] = [];
    option4FileList: File[] = [];
    option1: any;
    option2: any;
    option3: any;
    option4: any;
    listOfFiles: any[] = [];
    optionTypePop = false;
    optionType: string;
    updateOptionType: string;
    option1ExistValue: string;
    option2ExistValue: string;
    option3ExistValue: string;
    option4ExistValue: string;

    destroy$: Subject<boolean> = new Subject<boolean>();

    constructor(private appService: AppService, private router: Router,
                private toaster: ToastrService, private datePipe: DatePipe,
                private spinner: NgxSpinnerService, private formBuilder: FormBuilder) {
        this.minDate = this.datePipe.transform(new Date(), 'YYYY-MM-dd');
    }

    interactSessions = new UntypedFormGroup({
        sessionId: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        sessionName: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        sessionStartDate: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        sessionEndDate: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        sessionStartTime: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        sessionEndTime: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        sessionState: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        sessionQrCode: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        createdAt: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        createdBy: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        updatedAt: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        updatedBy: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    });

    newQuestions = new UntypedFormGroup({
        questionSeq: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        questionName: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        questionType: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        option1: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        option2: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        option3: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        option4: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        answer: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        sessionId: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        questionState: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        sessionDate: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        createdAt: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        createdBy: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        updatedAt: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        updatedBy: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        optionType: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    });

    updatePicQuestions = new UntypedFormGroup({
        questionSeq: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        questionName: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        questionType: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        option1: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        option2: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        option3: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        option4: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        answer: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        sessionId: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        questionState: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        sessionDate: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        createdAt: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        createdBy: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        updatedAt: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        updatedBy: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        optionType: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    });

    questionErrors = {
        questionName: '',
        questionType: '',
        option1: '',
        option2: '',
        option3: '',
        option4: '',
        answer: '',
        sessionId: ''
    }

    sessionErrors = {
        sessionName: '',
        sessionStartDate: '',
        sessionEndDate: '',
        sessionStartTime: '',
        sessionEndTime: ''
    }

   /* onSubmitQuestion() {
        console.log("check",)
        this.QuestionValidation();
    }*/

    onSubmit() {
        this.formValidation();
    }

    formValidation() {
        this.sessionErrors.sessionName = ""
        this.sessionErrors.sessionStartDate = ""
        this.sessionErrors.sessionEndDate = ""
        this.sessionErrors.sessionStartTime = ""
        this.sessionErrors.sessionEndTime = ""
        let hasError = false;

        if (this.interactSessions.get('sessionName').invalid) {
            this.sessionErrors.sessionName = "Session Name is required"
            hasError = true;
        }

        if (this.interactSessions.get('sessionStartDate').invalid) {
            this.sessionErrors.sessionStartDate = "Session Start Date is required"
            hasError = true;
        }

        if (this.interactSessions.get('sessionEndDate').invalid) {
            this.sessionErrors.sessionEndDate = "Session End Date is required"
            hasError = true;
        }

        if (this.interactSessions.get('sessionStartTime').invalid) {
            this.sessionErrors.sessionStartTime = "Start Time is required"
            hasError = true;
        }

        if (this.interactSessions.get('sessionEndTime').invalid) {
            this.sessionErrors.sessionEndTime = "End Time is required"
            hasError = true;
        }

        if (!hasError) {
            this.saveNewInteractSession();
        }
    }

    QuestionValidation() {
        this.questionErrors.questionName = ""
        this.questionErrors.questionType = ""
        this.questionErrors.option1 = ""
        this.questionErrors.option2 = ""
        this.questionErrors.option3 = ""
        this.questionErrors.option4 = ""
        this.questionErrors.answer = ""
        let hasQuestionError = false;

        if (this.newQuestions.get('questionName').invalid) {
            this.questionErrors.questionName = "Question Name is required";
            hasQuestionError = true;
            console.log("question name value :", this.newQuestions.get('questionName').value)
        }
        if (this.newQuestions.get('questionType').invalid) {
            this.questionErrors.questionType = "Question Type is required";
            hasQuestionError = true;
        }
        if (this.newQuestions.get('answer').invalid) {
            this.questionErrors.answer = "Answer is required";
            hasQuestionError = true;
        }
        if (this.newQuestions.get('option1').invalid) {
            this.questionErrors.option1 = "Option1 is required";
            hasQuestionError = true;
        }
        if (Number(this.newQuestions.get('questionType').value) > 1 && this.newQuestions.get('option2').invalid) {
            this.questionErrors.option2 = "option2 is required";
            hasQuestionError = true;
        }
        if (Number(this.newQuestions.get('questionType').value) > 2 && this.newQuestions.get('option3').invalid) {
            this.questionErrors.option3 = "option3 is required";
            hasQuestionError = true;
        }
        if (Number(this.newQuestions.get('questionType').value) > 3 && this.newQuestions.get('option4').invalid) {
            this.questionErrors.option4 = "option4 is required";
            hasQuestionError = true;
        }

        if (!hasQuestionError) {
            if (this.questionBoxShow) {
                this.addQuestionOneByOne();
            } else if (this.pictureBoxShow) {
                this.addPicQuestion();
            }
        }
    }

    ngOnInit(): void {
        if (localStorage.getItem('sessionId') != null && localStorage.getItem('editFunction') == "true") {
            this.isUpdate = true;
            this.titleEdit = true;
            const sessionId = localStorage.getItem('sessionId');
            this.getSession(Number(sessionId));
            this.getQuestions(Number(sessionId));
        }
        if (localStorage.getItem('sessionUpdated') == "true") {
            this.sessionCreate = false;
            this.questionBoxShow = true;
            this.interactSessions.disable();
            this.newQuestions.disable();
        } /*else if (localStorage.getItem('sessionId') != null) {
            this.sessionCreate = false;
            this.questionBoxShow = true;
            this.interactSessions.disable();
        }*/
    }

    ngOnDestroy(): void {
        localStorage.removeItem('sessionId');
        localStorage.removeItem('sessionUpdated');
        localStorage.removeItem('editFunction');
    }

    getSession(sessionId: number) {
        return this.appService.getSessionDetails()
            .pipe(takeUntil(this.destroy$))
            .subscribe(data => {
                console.log("session Details :", data)
                const filterBySession = data.filter(item => item.sessionId == sessionId)
                this.updateSession = filterBySession;
                for (let ses of this.updateSession) {
                    this.interactSessions.controls['sessionId'].setValue(ses.sessionId)
                    this.interactSessions.controls['sessionName'].setValue(ses.sessionName)
                    this.interactSessions.controls['sessionStartTime'].setValue(ses.sessionStartTime)
                    this.interactSessions.controls['sessionEndTime'].setValue(ses.sessionEndTime)
                    this.interactSessions.controls['sessionState'].setValue(ses.sessionState)
                    this.interactSessions.controls['sessionQrCode'].setValue(ses.sessionQrCode)
                    this.interactSessions.controls['createdAt'].setValue(ses.createdAt)
                    this.interactSessions.controls['createdBy'].setValue(ses.createdBy)
                    this.interactSessions.controls['updatedBy'].setValue(ses.updatedBy)
                    const getStartDate = new Date(ses.sessionStartDate)
                    const formatStart = this.datePipe.transform(getStartDate, "yyyy-MM-dd")
                    this.interactSessions.controls['sessionStartDate'].setValue(formatStart)
                    const getEndDate = new Date(ses.sessionEndDate)
                    const formatEnd = this.datePipe.transform(getEndDate, "yyyy-MM-dd")
                    this.interactSessions.controls['sessionEndDate'].setValue(formatEnd)
                }
            }, (err: any) => {
                console.log("something went wrong :", err)
            })
    }

    getQuestions(sessionId: number) {
        this.showQuestionsData = [];
        return this.appService.getAllQuestions(sessionId)
            .pipe(takeUntil(this.destroy$))
            .subscribe(data => {
                console.log("get Questions :", data)
                this.showQuestionsData = data;
            }, (err: any) => {
                console.log("something went wrong :", err)
            })
    }

    addQuestionOneByOne() {
        const addSingleQuestion: AddQuestion = new AddQuestion();
        addSingleQuestion.questionName = this.newQuestions.get("questionName").value;
        addSingleQuestion.questionType = this.newQuestions.get("questionType").value;
        addSingleQuestion.option1 = this.newQuestions.get("option1").value;
        addSingleQuestion.option2 = this.newQuestions.get("option2").value;
        addSingleQuestion.optionType = "Text Option";
        console.log("option type::", addSingleQuestion.optionType)
        if (this.newQuestions.get("option3").value == null) {
            addSingleQuestion.option3 = ""
        } else {
            addSingleQuestion.option3 = this.newQuestions.get("option3").value;
        }
        if (this.newQuestions.get("option4").value == null) {
            addSingleQuestion.option4 = ""
        } else {
            addSingleQuestion.option4 = this.newQuestions.get("option4").value;
        }
        addSingleQuestion.answer = this.newQuestions.get("answer").value;
        addSingleQuestion.sessionId = Number(localStorage.getItem('sessionId'));
        addSingleQuestion.createdBy = localStorage.getItem('userId');

        const questionObject = {
            questionName: addSingleQuestion.questionName,
            questionType: addSingleQuestion.questionType,
            option1: addSingleQuestion.option1,
            option2: addSingleQuestion.option2,
            option3: addSingleQuestion.option3,
            option4: addSingleQuestion.option4,
            answer: addSingleQuestion.answer,
            sessionId: addSingleQuestion.sessionId,
            sessionDate: addSingleQuestion.sessionDate,
            questionSeq: addSingleQuestion.questionSeq,
            createdAt: addSingleQuestion.createdAt,
            createdBy: addSingleQuestion.createdBy,
            updatedAt: addSingleQuestion.updatedAt,
            updatedBy: addSingleQuestion.updatedBy,
            questionState: addSingleQuestion.questionState,
            optionType: addSingleQuestion.optionType
        };

        this.addQuestionsData.push(questionObject);
        this.showQuestionsData.push(questionObject);
        console.log("Question add::", this.addQuestionsData);
        this.saveQuestionAlertPopup();
    }

    addPicQuestion() {
        this.addQuestionsData = [];
        const addSingleQuestion: AddQuestion = new AddQuestion();
        addSingleQuestion.questionName = this.newQuestions.get("questionName").value;
        addSingleQuestion.questionType = this.newQuestions.get("questionType").value;
        addSingleQuestion.optionType = "Image Option";
        if (this.option3FileList.length <= 0) {
            addSingleQuestion.option3 = ""
        }
        if (this.option4FileList.length <= 0) {
            addSingleQuestion.option4 = ""
        }
        addSingleQuestion.answer = this.newQuestions.get("answer").value;
        addSingleQuestion.sessionId = Number(localStorage.getItem('sessionId'));
        addSingleQuestion.createdBy = localStorage.getItem('userId');

        const questionObject = {
            questionName: addSingleQuestion.questionName,
            questionType: addSingleQuestion.questionType,
            option1: addSingleQuestion.option1,
            option2: addSingleQuestion.option2,
            option3: addSingleQuestion.option3,
            option4: addSingleQuestion.option4,
            answer: addSingleQuestion.answer,
            sessionId: addSingleQuestion.sessionId,
            sessionDate: addSingleQuestion.sessionDate,
            questionSeq: addSingleQuestion.questionSeq,
            createdAt: addSingleQuestion.createdAt,
            createdBy: addSingleQuestion.createdBy,
            updatedAt: addSingleQuestion.updatedAt,
            updatedBy: addSingleQuestion.updatedBy,
            questionState: addSingleQuestion.questionState,
            optionType: addSingleQuestion.optionType
        };

        this.addQuestionsData.push(questionObject);
        this.savePicQuestionAlertPopup();
    }

    saveNewInteractSession() {
        if (!this.isUpdate) {
            this.interactSessions.controls["createdBy"].setValue(localStorage.getItem('userId'))
            // this.interactSessions.controls["updatedBy"].setValue(localStorage.getItem('userId'))
            const start = this.interactSessions.get('sessionStartDate').value
            var startDate = new Date(start);
            const formatStartDate = startDate.getTime();
            const end = this.interactSessions.get('sessionEndDate').value
            var endDate = new Date(end);
            const formatEndDate = endDate.getTime();

            this.interactSessions.controls["sessionStartDate"].setValue(formatStartDate)
            this.interactSessions.controls["sessionEndDate"].setValue(formatEndDate)
            this.spinner.show()
            console.log("form values :", this.interactSessions.value)
            this.appService.saveNewInteractSessions(this.interactSessions.value)
                .pipe(takeUntil(this.destroy$))
                .subscribe(data => {
                    console.log("save interact session:", data)
                    this.sessionCreate = false;
                    this.optionTypePop = true;
                    this.spinner.hide()
                    this.notification();
                    this.currentInteractSession = data;
                    this.isHide = false;
                    this.isManage = true;
                    this.showData();
                    localStorage.setItem('sessionId', String(this.currentInteractSession.sessionId));
                    this.sessionId = Number(localStorage.getItem('sessionId'))
                }, (err: any) => {
                    console.log("error:", err)
                    this.interactSessions.reset();
                    this.spinner.hide()
                    this.toaster.error("Something went wrong, Please try again later")
                })
        } else if (this.isUpdate) {
            this.interactSessions.controls["updatedBy"].setValue(localStorage.getItem('userId'))
            const start = this.interactSessions.get('sessionStartDate').value
            var startDate = new Date(start);
            const formatStartDate = startDate.getTime();
            const end = this.interactSessions.get('sessionEndDate').value
            var endDate = new Date(end);
            const formatEndDate = endDate.getTime();

            this.interactSessions.controls["sessionStartDate"].setValue(formatStartDate)
            this.interactSessions.controls["sessionEndDate"].setValue(formatEndDate)
            this.spinner.show()
            console.log("form values :", this.interactSessions.value)
            this.appService.updateExistSession(this.interactSessions.value)
                .pipe(takeUntil(this.destroy$))
                .subscribe(data => {
                    console.log("save interact session:", data)
                    this.sessionCreate = false;
                    localStorage.setItem('sessionUpdated', 'true')
                    this.spinner.hide()
                    this.notification();
                    this.currentInteractSession = data;
                    this.showData();
                    this.sessionId = this.currentInteractSession.sessionId
                }, (err: any) => {
                    console.log("error:", err)
                    this.interactSessions.reset();
                    this.spinner.hide()
                    this.toaster.error("Something went wrong, Please try again later")
                })
        }
    }

    addNewQuestions() {
        if (!this.isUpdate) {
            this.spinner.show();
            console.log("question::", this.addQuestionsData);
            this.appService
                .saveQuestions(this.addQuestionsData)
                .pipe(takeUntil(this.destroy$))
                .subscribe(data => {
                    console.log("question::", data);
                    this.showQuestionsData = data;
                    this.newQuestions.reset();
                    this.showTwoOptions = false;
                    this.showThreeOptions = false;
                    this.showFourOptions = false;
                    this.router.navigate(["/main/dashboard"])
                    this.spinner.hide()
                    this.questionAddSuccesspopUp();
                    this.newQuestions.reset();
                    // @ts-ignore
                    this.saveNewQuestion = data;
                }, (error: any) => {
                    this.spinner.hide()
                    console.log("error:", error)
                    this.toaster.error("Something went wrong, Please try again later")
                })
        } else if (this.isUpdate) {
            this.newQuestions.controls["updatedBy"].setValue(localStorage.getItem('userId'))
            this.newQuestions.controls["optionType"].setValue(this.updateOptionType);
            this.spinner.show();
            console.log("update question values :", this.newQuestions.value)
            return this.appService.updateExistQuestion(this.newQuestions.value)
                .pipe(takeUntil(this.destroy$))
                .subscribe(data => {
                    console.log("updated Values :", data)
                    this.spinner.hide()
                    this.updatePopup();
                }, (err: any) => {
                    console.log("something went wrong :", err)
                    this.spinner.hide()
                })
        }
    }

    savePicQuestion() {
        if (!this.isUpdate) {
            this.spinner.show();
            console.log("question::", this.addQuestionsData);
            this.appService
                .saveQuestions(this.addQuestionsData)
                .pipe(takeUntil(this.destroy$))
                .subscribe(data => {
                    console.log("question::", data);
                    this.addQuestionsData = data;
                    this.showQuestionsData = data;
                    var resData: AddQuestion | undefined;
                    resData = data[0];
                    /*for (let qus of this.addQuestionsData) {
                        var questionId = qus.questionSeq[0]
                    }*/
                    console.log("questionId :", resData.questionSeq)
                    const option1FormData = new FormData();
                    for (const file of this.option1FileList) {
                        option1FormData.append('file', file, file.name);
                    }
                    this.appService.fileUpload(option1FormData, resData.questionSeq, 'option1')
                        .pipe(takeUntil(this.destroy$))
                        .subscribe(data => {
                            console.log("file upload :", data)
                        }, (err: any) => {
                            console.log("something went wrong :", err)
                        })
                    const option2FormData = new FormData();
                    for (const file of this.option2FileList) {
                        option2FormData.append('file', file, file.name);
                    }
                    this.appService.fileUpload(option2FormData, resData.questionSeq, 'option2')
                        .pipe(takeUntil(this.destroy$))
                        .subscribe(data => {
                            console.log("file upload :", data)
                        }, (err: any) => {
                            console.log("something went wrong :", err)
                        })
                    if (this.option3FileList.length > 0) {
                        const option3FormData = new FormData();
                        for (const file of this.option3FileList) {
                            option3FormData.append('file', file, file.name);
                        }
                        this.appService.fileUpload(option3FormData, resData.questionSeq, 'option3')
                            .pipe(takeUntil(this.destroy$))
                            .subscribe(data => {
                                console.log("file upload :", data)
                                this.option3FileList = [];
                            }, (err: any) => {
                                console.log("something went wrong :", err)
                            })
                    }
                    if (this.option4FileList.length > 0) {
                        const option4FormData = new FormData();
                        for (const file of this.option4FileList) {
                            option4FormData.append('file', file, file.name);
                        }
                        this.appService.fileUpload(option4FormData, resData.questionSeq, 'option4')
                            .pipe(takeUntil(this.destroy$))
                            .subscribe(data => {
                                console.log("file upload :", data)
                                this.option4FileList = [];
                            }, (err: any) => {
                                console.log("something went wrong :", err)
                            })
                    }
                    this.spinner.hide();
                    this.addPicQuestionPopup();
                }, (err: any) => {
                    this.spinner.hide();
                    console.log("something went wrong :", err)
                })
        } else if (this.isUpdate) {
            this.updatePicQuestions.controls["updatedBy"].setValue(localStorage.getItem('userId'))
            this.updatePicQuestions.controls["optionType"].setValue(this.updateOptionType);
            this.spinner.show();
            this.updatePicQuestions.controls["questionName"].setValue(this.newQuestions.get('questionName').value)
            this.updatePicQuestions.controls["questionSeq"].setValue(this.newQuestions.get('questionSeq').value)
            this.updatePicQuestions.controls["questionState"].setValue(this.newQuestions.get('questionState').value)
            this.updatePicQuestions.controls["createdAt"].setValue(this.newQuestions.get('createdAt').value)
            this.updatePicQuestions.controls["createdBy"].setValue(this.newQuestions.get('createdBy').value)
            this.updatePicQuestions.controls["updatedAt"].setValue(this.newQuestions.get('updatedAt').value)
            this.updatePicQuestions.controls["updatedBy"].setValue(this.newQuestions.get('updatedBy').value)
            this.updatePicQuestions.controls["sessionId"].setValue(this.newQuestions.get('sessionId').value)
            this.updatePicQuestions.controls["sessionDate"].setValue(this.newQuestions.get('sessionDate').value)
            this.updatePicQuestions.controls["answer"].setValue(this.newQuestions.get('answer').value)
            this.updatePicQuestions.controls["questionType"].setValue(this.newQuestions.get('questionType').value)
            this.updatePicQuestions.controls["optionType"].setValue(this.newQuestions.get('optionType').value);
            this.updatePicQuestions.controls["option1"].setValue(this.option1ExistValue);
            this.updatePicQuestions.controls["option2"].setValue(this.option2ExistValue);
            this.updatePicQuestions.controls["option3"].setValue(this.option3ExistValue);
            this.updatePicQuestions.controls["option4"].setValue(this.option4ExistValue);
            console.log("update question values :", this.updatePicQuestions.value)
            console.log("file :", this.option4FileList.length)
            this.appService.updateExistQuestion(this.updatePicQuestions.value)
                .pipe(takeUntil(this.destroy$))
                .subscribe(data => {
                    console.log("updated Values :", data)
                    if (this.option1FileList.length > 0) {
                        const option1FormData = new FormData();
                        for (const file of this.option1FileList) {
                            option1FormData.append('file', file, file.name);
                        }
                        this.appService.fileUpload(option1FormData, data.questionSeq, 'option1')
                            .pipe(takeUntil(this.destroy$))
                            .subscribe(data => {
                                console.log("file upload :", data)
                                this.option1FileList = [];
                            }, (err: any) => {
                                console.log("something went wrong :", err)
                            })
                    }
                    if (this.option2FileList.length > 0) {
                        const option2FormData = new FormData();
                        for (const file of this.option2FileList) {
                            option2FormData.append('file', file, file.name);
                        }
                        this.appService.fileUpload(option2FormData, data.questionSeq, 'option2')
                            .pipe(takeUntil(this.destroy$))
                            .subscribe(data => {
                                console.log("file upload :", data)
                                this.option2FileList = [];
                            }, (err: any) => {
                                console.log("something went wrong :", err)
                            })
                    }
                    if (this.option3FileList.length > 0) {
                        const option3FormData = new FormData();
                        for (const file of this.option3FileList) {
                            option3FormData.append('file', file, file.name);
                        }
                        this.appService.fileUpload(option3FormData, data.questionSeq, 'option3')
                            .pipe(takeUntil(this.destroy$))
                            .subscribe(data => {
                                console.log("file upload :", data)
                                this.option3FileList = [];
                            }, (err: any) => {
                                console.log("something went wrong :", err)
                            })
                    }
                    if (this.option4FileList.length > 0) {
                        const option4FormData = new FormData();
                        for (const file of this.option4FileList) {
                            option4FormData.append('file', file, file.name);
                        }
                        this.appService.fileUpload(option4FormData, data.questionSeq, 'option4')
                            .pipe(takeUntil(this.destroy$))
                            .subscribe(data => {
                                console.log("file upload :", data)
                                this.option4FileList = [];
                            }, (err: any) => {
                                console.log("something went wrong :", err)
                            })
                    }
                    this.option1 = "";
                    this.option1FileList = [];
                    this.option2 = "";
                    this.option2FileList = [];
                    this.option3 = "";
                    this.option3FileList = [];
                    this.option4 = "";
                    this.option4FileList = [];
                    this.spinner.hide()
                    this.updatePopup();
                }, (err: any) => {
                    console.log("something went wrong :", err)
                    this.spinner.hide()
                })
        }
    }

    showData() {
        this.interactSessions.disable()
        const getStartDate = new Date(this.currentInteractSession.sessionStartDate)
        const formatStart = this.datePipe.transform(getStartDate, "yyyy-MM-dd")
        this.interactSessions.controls['sessionStartDate'].setValue(formatStart)
        const getEndDate = new Date(this.currentInteractSession.sessionEndDate)
        const formatEnd = this.datePipe.transform(getEndDate, "yyyy-MM-dd")
        this.interactSessions.controls['sessionEndDate'].setValue(formatEnd)
        this.interactSessions.controls['sessionStartTime'].setValue(this.currentInteractSession.sessionStartTime)
        this.interactSessions.controls['sessionEndTime'].setValue(this.currentInteractSession.sessionEndTime)
        this.interactSessions.controls['sessionName'].setValue(this.currentInteractSession.sessionName)
    }

    showTwoInputText(event) {
        const value = event.target.value;
        console.log("value::", value)
        if (value == "2") {
            this.showTwoOptions = true;
            this.showThreeOptions = false;
            this.showFourOptions = false;
        }
        if (value == "3") {
            this.showTwoOptions = true;
            this.showThreeOptions = true;
            this.showFourOptions = false;
        }
        if (value == "4") {
            this.showTwoOptions = true;
            this.showThreeOptions = true;
            this.showFourOptions = true;
        }
    }

    showTwoInputImage(event) {
        const value = event.target.value;
        console.log("value::", value)
        if (value == "2") {
            this.showTwoOptions = true;
            this.showThreeOptions = false;
            this.showFourOptions = false;
            this.options = [];
            this.options.push("Option 1")
            this.options.push("Option 2")
        }
        if (value == "3") {
            this.showTwoOptions = true;
            this.showThreeOptions = true;
            this.showFourOptions = false;
            this.options = [];
            this.options.push("Option 1")
            this.options.push("Option 2")
            this.options.push("Option 3")
        }
        if (value == "4") {
            this.showTwoOptions = true;
            this.showThreeOptions = true;
            this.showFourOptions = true;
            this.options = [];
            this.options.push("Option 1")
            this.options.push("Option 2")
            this.options.push("Option 3")
            this.options.push("Option 4")
        }
    }

    addOptionsAnswer(event: any) {
        this.options = []
        const type = this.newQuestions.get('questionType').value
        if (type == "2") {
            this.options.push(this.newQuestions.get('option1').value)
            this.options.push(this.newQuestions.get('option2').value)
        } else if (type == "3") {
            this.options.push(this.newQuestions.get('option1').value)
            this.options.push(this.newQuestions.get('option2').value)
            this.options.push(this.newQuestions.get('option3').value)
        } else if (type == "4") {
            this.options.push(this.newQuestions.get('option1').value)
            this.options.push(this.newQuestions.get('option2').value)
            this.options.push(this.newQuestions.get('option3').value)
            this.options.push(this.newQuestions.get('option4').value)
        }
    }

    onFileChangeOption1(event: any) {
        this.option1FileList = [];
        for (var i = 0; i <= event.target.files.length - 1; i++) {
            var selectedFile = event.target.files[i];
            console.log("select file size ::" + selectedFile);
            /*var fileSizeInMb = Math.round((selectedFile.size * 100) / (1024 * 1024)) / 100;
            console.log("select file convert size ::" + fileSizeInMb);*/
            var fileSizeInBytes = selectedFile.size;
            console.log("select file convert size ::" + fileSizeInBytes);
            if (fileSizeInBytes <= 102400) {
                if (event.target.files && event.target.files[0]) {
                    var reader = new FileReader();
                    reader.onload = (event: any) => {
                        this.option1 = event.target.result;
                    };
                    reader.readAsDataURL(event.target.files[0]);
                }
                this.option1FileList.push(selectedFile);
                // this.listOfFiles.push(selectedFile.name);
                console.log("File list::", this.option1FileList.length)
            } else {
                console.log("File size is large:", this.option1FileList.length)
                Swal.fire({
                    title: 'File size is large. Please upload below 100KB',
                    showClass: {
                        popup: 'animate_animated animate_fadeInDown'
                    },
                    hideClass: {
                        popup: 'animate_animated animate_fadeOutUp'
                    }
                })
            }
        }
    }

    onFileChangeOption2(event: any) {
        this.option2FileList = [];
        for (var i = 0; i <= event.target.files.length - 1; i++) {
            var selectedFile = event.target.files[i];
            var fileSizeInBytes = selectedFile.size;
            if (fileSizeInBytes <= 102400) {
                if (event.target.files && event.target.files[0]) {
                    var reader = new FileReader();
                    reader.onload = (event: any) => {
                        this.option2 = event.target.result;
                    };
                    reader.readAsDataURL(event.target.files[0]);
                }
                this.option2FileList.push(selectedFile);
            } else {
                Swal.fire({
                    title: 'File size is large. Please upload below 100KB',
                    showClass: {
                        popup: 'animate_animated animate_fadeInDown'
                    },
                    hideClass: {
                        popup: 'animate_animated animate_fadeOutUp'
                    }
                })
            }
        }
    }

    onFileChangeOption3(event: any) {
        this.option3FileList = [];
        for (var i = 0; i <= event.target.files.length - 1; i++) {
            var selectedFile = event.target.files[i];
            var fileSizeInBytes = selectedFile.size;
            if (fileSizeInBytes <= 102400) {
                if (event.target.files && event.target.files[0]) {
                    var reader = new FileReader();
                    reader.onload = (event: any) => {
                        this.option3 = event.target.result;
                    };
                    reader.readAsDataURL(event.target.files[0]);
                }
                this.option3FileList.push(selectedFile);
            } else {
                Swal.fire({
                    title: 'File size is large. Please upload below 100KB',
                    showClass: {
                        popup: 'animate_animated animate_fadeInDown'
                    },
                    hideClass: {
                        popup: 'animate_animated animate_fadeOutUp'
                    }
                })
            }
        }
    }

    onFileChangeOption4(event: any) {
        this.option4FileList = [];
        for (var i = 0; i <= event.target.files.length - 1; i++) {
            var selectedFile = event.target.files[i];
            var fileSizeInBytes = selectedFile.size;
            if (fileSizeInBytes <= 102400) {
                if (event.target.files && event.target.files[0]) {
                    var reader = new FileReader();
                    reader.onload = (event: any) => {
                        this.option4 = event.target.result;
                    };
                    reader.readAsDataURL(event.target.files[0]);
                }
                this.option4FileList.push(selectedFile);
            } else {
                Swal.fire({
                    title: 'File size is large. Please upload below 100KB',
                    showClass: {
                        popup: 'animate_animated animate_fadeInDown'
                    },
                    hideClass: {
                        popup: 'animate_animated animate_fadeOutUp'
                    }
                })
            }
        }
    }

    getAnswer(event: any) {
        if (!this.isUpdate) {
            this.addIcon = true;
        }
    }

    fetchQuestion(id: number) {
        if (this.isUpdate) {
            this.newQuestions.enable();
            this.buttonShow = true;
            for (let qus of this.showQuestionsData) {
                if (id == qus.questionSeq) {
                    this.newQuestions.controls["questionName"].setValue(qus.questionName)
                    this.newQuestions.controls["questionSeq"].setValue(qus.questionSeq)
                    this.newQuestions.controls["questionState"].setValue(qus.questionState)
                    this.newQuestions.controls["createdAt"].setValue(qus.createdAt)
                    this.newQuestions.controls["createdBy"].setValue(qus.createdBy)
                    this.newQuestions.controls["updatedAt"].setValue(qus.updatedAt)
                    this.newQuestions.controls["updatedBy"].setValue(qus.updatedBy)
                    this.newQuestions.controls["sessionId"].setValue(qus.sessionId)
                    this.newQuestions.controls["sessionDate"].setValue(qus.sessionDate)
                    this.newQuestions.controls["answer"].setValue(qus.answer)
                    this.newQuestions.controls["questionType"].setValue(qus.questionType)
                    this.newQuestions.controls["optionType"].setValue(qus.optionType);
                    this.updateOptionType = qus.optionType;
                    console.log("option type::", this.updateOptionType);
                    const value = qus.questionType
                    if (value == "2" && qus.optionType == "Image Option") {
                        this.twoPicGet(qus);
                        this.showTwoOptions = true;
                        this.showThreeOptions = false;
                        this.showFourOptions = false;
                        this.options = [];
                        this.options.push('Option 1')
                        this.options.push('Option 2')
                    } else if (value == "2" && qus.optionType == "Text Option") {
                        this.showTwoOptions = true;
                        this.showThreeOptions = false;
                        this.showFourOptions = false;
                    }
                    if (value == "3" && qus.optionType == "Image Option") {
                        this.twoPicGet(qus);
                        this.thirdPicGet(qus);
                        this.showTwoOptions = true;
                        this.showThreeOptions = true;
                        this.showFourOptions = false;
                        this.options = [];
                        this.options.push('Option 1')
                        this.options.push('Option 2')
                        this.options.push('Option 3')
                    } else if (value == "3" && qus.optionType == "Text Option") {
                        this.showTwoOptions = true;
                        this.showThreeOptions = true;
                        this.showFourOptions = false;
                    }
                    if (value == "4" && qus.optionType == "Image Option") {
                        this.twoPicGet(qus);
                        this.thirdPicGet(qus);
                        this.forthPicGet(qus);
                        this.showTwoOptions = true;
                        this.showThreeOptions = true;
                        this.showFourOptions = true;
                        this.options = [];
                        this.options.push('Option 1')
                        this.options.push('Option 2')
                        this.options.push('Option 3')
                        this.options.push('Option 4')
                    } else if (value == "4" && qus.optionType == "Text Option") {
                        this.showTwoOptions = true;
                        this.showThreeOptions = true;
                        this.showFourOptions = true;
                    }
                    if (qus.optionType == "Text Option") {
                        this.newQuestions.controls["option1"].setValue(qus.option1)
                        this.newQuestions.controls["option2"].setValue(qus.option2)
                        this.newQuestions.controls["option3"].setValue(qus.option3)
                        this.newQuestions.controls["option4"].setValue(qus.option4)
                        this.addOptionsAnswer(event)
                    } else if (qus.optionType == "Image Option") {
                        this.option1ExistValue = qus.option1;
                        this.option2ExistValue = qus.option2;
                        this.option3ExistValue = qus.option3;
                        this.option4ExistValue = qus.option4;
                    }
                    console.log("fetched options :", this.newQuestions.value)
                }
            }
        }
    }

    notification() {
        if (!this.isUpdate) {
            Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'Session Created Successfully',
                showConfirmButton: false,
                timer: 1500
            })
        } else if (this.isUpdate) {
            Swal.fire({
                position: 'center',
                icon: 'warning',
                title: 'You want to edit/add questions?',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.toaster.success("Session Updated Successfully");
                    this.newQuestions.disable();
                    var optionType: string[] = [];
                    for (let qus of this.showQuestionsData) {
                        optionType.push(qus.optionType);
                    }
                    console.log("option type :", optionType)
                    if (optionType[0] == "Text Option") {
                        this.questionBoxShow = true;
                        this.pictureBoxShow = false;
                    } else if (optionType[0] == "Image Option") {
                        this.pictureBoxShow = true;
                        this.questionBoxShow = false;
                    }
                } else {
                    this.router.navigate(['/main/dashboard'])
                }
            })
        }
    }


    saveQuestionAlertPopup(){
        Swal.fire({
            title: 'If you want to add more questions, click Yes button',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'Save all questions'
        }).then((result) => {
            if (result.isConfirmed) {
                this.toaster.success("Question added Successfully")
                this.newQuestions.reset();
                this.options = [];
                this.showTwoOptions = false;
                this.showThreeOptions = false;
                this.showFourOptions = false;
                this.addIcon = false;
            } else {
                this.addNewQuestions();
                this.newQuestions.reset();
                this.options = [];
                this.newQuestions.disable();
                this.addIcon = false;
            }
        })
    }

    savePicQuestionAlertPopup(){
        Swal.fire({
            title: 'You want to save this question',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        }).then((result) => {
            if (result.isConfirmed) {
                this.savePicQuestion();
            }
        })
    }

    addPicQuestionPopup() {
        Swal.fire({
            title: 'Do you want to add more question?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        }).then((result) => {
            if (result.isConfirmed) {
                this.toaster.success("Question added Successfully")
                this.newQuestions.reset();
                this.options = [];
                this.option1 = "";
                this.option2 = "";
                this.option3 = "";
                this.option4 = "";
                this.showTwoOptions = false;
                this.showThreeOptions = false;
                this.showFourOptions = false;
                this.addIcon = false;
            } else {
                this.router.navigate(['/main/dashboard'])
                this.toaster.success("Question added Successfully")
            }
        })
    }

    questionAddSuccesspopUp() {
        Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Your session, all the questions are added',
            showConfirmButton: false,
            timer: 3000
        })
    }

    updatePopup() {
        /*Swal.fire({
            title: 'If you want to update any other questions?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        }).then((result) => {
            if (result.isConfirmed) {
                this.toaster.success("Question updated Successfully")
                this.getQuestions(Number(localStorage.getItem('sessionId')));
                this.newQuestions.reset();
                this.options = [];
                this.showTwoOptions = false;
                this.showThreeOptions = false;
                this.showFourOptions = false;
            } else{
                this.router.navigate(["/main/dashboard"])
                this.toaster.success("Question updated Successfully")
            }
        })*/
        this.toaster.success("Question updated Successfully")
        this.getQuestions(Number(localStorage.getItem('sessionId')));
        this.newQuestions.reset();
        this.newQuestions.disable();
        this.options = [];
        this.showTwoOptions = false;
        this.showThreeOptions = false;
        this.showFourOptions = false;
        this.buttonShow = false;
    }

    addUpdateQuestions() {
        this.newQuestions.enable();
        this.isUpdate = false;
    }

    close() {
        if (this.newQuestions.get('optionType').invalid) {
            this.toaster.warning("Please select option type");
        } else {
            if (this.newQuestions.get('optionType').value == "Text Option") {
                this.optionTypePop = false;
                this.questionBoxShow = true;
                this.optionType =  this.newQuestions.get('optionType').value;
            } else if (this.newQuestions.get('optionType').value == "Image Option") {
                this.optionTypePop = false;
                this.pictureBoxShow = true;
                this.optionType =  this.newQuestions.get('optionType').value;
            }
        }
    }

    twoPicGet(qus: AddQuestion) {
        this.appService.getFile(String(qus.questionSeq), 'option1')
            .pipe(takeUntil(this.destroy$))
            .subscribe((blob) => {
                console.log("option 1 image :", blob);
                const reader = new FileReader();
                reader.onloadend = () => {
                    this.option1 = reader.result;
                };
                reader.readAsDataURL(blob);
            }, (err: any) => {
                console.log("something went wrong :", err);
            });
        this.appService.getFile(String(qus.questionSeq), 'option2')
            .pipe(takeUntil(this.destroy$))
            .subscribe((blob) => {
                console.log("option 1 image :", blob);
                const reader = new FileReader();
                reader.onloadend = () => {
                    this.option2 = reader.result;
                };
                reader.readAsDataURL(blob);
            }, (err: any) => {
                console.log("something went wrong :", err);
            });
    }

    thirdPicGet(qus: AddQuestion) {
        this.appService.getFile(String(qus.questionSeq), 'option3')
            .pipe(takeUntil(this.destroy$))
            .subscribe((blob) => {
                console.log("option 1 image :", blob);
                const reader = new FileReader();
                reader.onloadend = () => {
                    this.option3 = reader.result;
                };
                reader.readAsDataURL(blob);
            }, (err: any) => {
                console.log("something went wrong :", err);
            });
    }

    forthPicGet(qus: AddQuestion) {
        this.appService.getFile(String(qus.questionSeq), 'option4')
            .pipe(takeUntil(this.destroy$))
            .subscribe((blob) => {
                console.log("option 1 image :", blob);
                const reader = new FileReader();
                reader.onloadend = () => {
                    this.option4 = reader.result;
                };
                reader.readAsDataURL(blob);
            }, (err: any) => {
                console.log("something went wrong :", err);
            });
    }

}