import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {interval, Subject, Subscription, takeUntil} from "rxjs";
import {AppService} from "@services/app.service";
import {InteractSession} from "@modules/modal/interactSession";
import {NgxSpinnerService} from "ngx-spinner";
import * as QRCode from 'qrcode';
import {InteractQuestions} from "@modules/modal/interactQuestions";
import {QuestionResponse} from "@modules/modal/questionResponse";
import Chart from 'chart.js/auto';
import {AudienceAnswer} from "@modules/modal/audienceAnswer";
import {DateTime} from 'luxon';
import {AudienceRegister} from "@modules/modal/audienceRegister";
import {reject} from "q";

@Component({
    selector: 'app-projector-display',
    templateUrl: './projector-display.component.html',
    styleUrls: ['./projector-display.component.scss']
})
export class ProjectorDisplayComponent implements OnInit {
    public currentYear: string = DateTime.now().toFormat('y');
    sessionName: string = "";
    sessionId: number = 0;
    getSessionDetails: InteractSession[] = [];
    qrCodeData: string = 'http://interact.varnik.cloud/#/audience-register?session=';
    feedbackQr: string = "https://interact.varnik.cloud"
    sessionQrData: string = '';
    questionsData: InteractQuestions | undefined
    waitForSession = true;
    questionsShow = false;
    waitForNextQuestion = false;
    sessionCompleted = false;
    // apiCallCount = 0;
    questionResponse: QuestionResponse | undefined;
    option3 = false
    option4 = false;
    barCharts: any;
    pieCharts: any;
    optionsChart: any;
    attendeesAnswer: AudienceAnswer[] = [];
    answersCount: number = 0;
    rightAnswers: number = 0;
    wrongAnswers: number = 0;
    optionA: number = 0 ;
    optionB: number = 0 ;
    optionC: number = 0 ;
    optionD: number = 0 ;
    chartShow = false;
    audienceDetails: AudienceRegister[] = [];
    audienceCount: number = 0;
    option1Image: any;
    option2Image: any;
    option3Image: any;
    option4Image: any;
    picQuestionsShow = false;
    feedbackQrUrl: string;

    destroy$: Subject<boolean> = new Subject<boolean>();
    private intervalSubscription: Subscription;

    constructor(private route: ActivatedRoute, private appService: AppService,
                private spinner: NgxSpinnerService) {
    }

    ngOnInit() {
        this.spinner.show();
        this.getSession();
        this.intervalSubscription = interval(5000).subscribe(() => {
            this.getQuestion();
            this.getRegisteredDetails();
        });
    }

    getSession() {
        this.appService.getSessionDetails()
            .pipe(takeUntil(this.destroy$))
            .subscribe(data => {
                console.log("getSessionDetails::", data)
                this.getSessionDetails = data;
                this.route.queryParams.subscribe(params => {
                    const sessionId = params["id"]
                    for (let session of this.getSessionDetails) {
                        if (sessionId == session.sessionId) {
                            this.sessionName = session.sessionName
                            this.sessionQrData = session.sessionQrCode
                            this.sessionId = session.sessionId
                            this.getQuestion();
                            this.generateQRCode();
                        }
                    }
                })
                this.spinner.hide();
            }, (err: any) => {
                console.log("error:", err)
                this.spinner.hide();
            })
    }

    generateQRCode() {
        const qrCanvas = document.getElementById('qrCanvas') as HTMLCanvasElement;
        const finalQrCodeData = this.qrCodeData + this.sessionQrData;
        QRCode.toCanvas(qrCanvas, finalQrCodeData, (error) => {
            if (error) {
                console.error('QR code generation failed:', error);
            }
        });
    }

    getQuestion() {
        this.appService.getQuestion(this.sessionId)
            .pipe(takeUntil(this.destroy$))
            .subscribe(data => {
                console.log("question data :", data)
                if (data.optionType == "Text Option") {
                    this.questionsData = data;
                    if (this.questionsData.option3 != "") {
                        this.option3 = true;
                        // this.showHide3 =true;
                    } else if (this.questionsData.option3 == "") {
                        this.option3 = false;
                    }
                    if (this.questionsData.option4 != "") {
                        this.option4 = true;
                        // this.showHide4 = true;
                    } else if (this.questionsData.option4 == "") {
                        this.option4 = false;
                    }
                } else if (data.optionType == "Image Option") {
                    this.picShowQuestions(data);
                }
                if (this.questionsData.questionSeq != Number(localStorage.getItem('questionId'))) {
                    /*this.apiCallCount = Number(localStorage.getItem('qusCount'))
                    this.apiCallCount++
                    localStorage.setItem('qusCount', String(this.apiCallCount))*/
                    localStorage.setItem('questionId', String(this.questionsData.questionSeq))
                    this.waitForSession = false;
                    if (this.questionsData.optionType == "Text Option") {
                        this.questionsShow = true;
                        this.picQuestionsShow = false;
                    } else if (this.questionsData.optionType == "Image Option") {
                        this.picQuestionsShow = true;
                        this.questionsShow = false;
                    }
                    this.waitForNextQuestion = false;
                    this.sessionCompleted = false;
                    this.getAnswersDetails();
                } else if (this.questionsData.questionSeq == Number(localStorage.getItem('questionId'))) {
                    // this.apiCallCount = Number(localStorage.getItem('qusCount'))
                    this.waitForSession = false;
                    if (this.questionsData.optionType == "Text Option") {
                        this.questionsShow = true;
                        this.picQuestionsShow = false;
                    } else if (this.questionsData.optionType == "Image Option") {
                        this.picQuestionsShow = true;
                        this.questionsShow = false;
                    }
                    this.waitForNextQuestion = false;
                    this.sessionCompleted = false;
                    this.getAnswersDetails();
                }
            }, (error: any) => {
                this.questionResponse = error
                console.log("error response:", this.questionResponse)
                if (this.questionResponse.error.statusCode == 404 && localStorage.getItem('questionId') != null) {
                    this.waitForSession = false;
                    this.questionsShow = false;
                    this.picQuestionsShow = false;
                    this.waitForNextQuestion = false;
                    this.sessionCompleted = true;
                    this.generateFeedbackQr();
                    this.intervalSubscription.unsubscribe();
                    localStorage.removeItem('questionId')
                    // localStorage.removeItem('qusCount')
                } else if (this.questionResponse.error.statusCode == 404) {
                    this.waitForSession = true;
                    this.questionsShow = false;
                    this.picQuestionsShow = false;
                    this.waitForNextQuestion = false;
                    this.sessionCompleted = false;
                } else {
                    console.log("something wrong :", error)
                }
            })
        console.log("http request completed")
    }

    /*generateFeedbackQr() {
        const qrCanvas = document.getElementById('feedbackQr') as HTMLCanvasElement;
        const finalQrCodeData = this.feedbackQr;
        QRCode.toCanvas(qrCanvas, finalQrCodeData, (error) => {
            if (error) {
                console.error('QR code generation failed:', error);
            }
        });
    }*/

    generateFeedbackQr() {
        // const qrCanvas = document.getElementById('feedbackQr') as HTMLCanvasElement;
        const finalQrCodeData = this.feedbackQr;
        QRCode.toDataURL(finalQrCodeData, (error, qrData) => {
            if (error) {
                console.error('QR code generation failed:', error);
            } else {
                this.feedbackQrUrl = qrData;
                console.log("qr Data url :", this.feedbackQrUrl)
            }
        });
    }

    /*chartOptions = {
        theme: "light",
        title:{
            text: "Answer Analysis"
        },
        animationEnabled: true,
        toolTip: {
            shared: true
        },
        legend: {
            horizontalAlign: "right",
            verticalAlign: "center",
            reversed: true
        },
        axisY: {
            // includeZero: true
        },
        data: [{
            type: "stackedColumn",
            dataPoints: [
                { label: "Correct Answer", y: 17, color:"green" },
                { label: "Wrong Answer", y: 50, color: "red" }
            ]
        }]
    }*/

    createChart(){
        this.chartShow = true
        var chartExist = Chart.getChart("barChart"); // <canvas> id
        if (chartExist != undefined){
            this.barCharts.destroy();
            // this.charts.data = []
        }
        this.barCharts = new Chart("barChart", {
            type: 'bar',

            data: {// values on X-Axis
                labels: ['Attendees Selected Options'],
                datasets: [
                    {
                        label: "A",
                        data: [this.optionA],
                        backgroundColor: 'blue'
                    },
                    {
                        label: "B",
                        data: [this.optionB],
                        backgroundColor: 'orange'
                    },
                    {
                        label: "C",
                        data: [this.optionC],
                        backgroundColor: 'purple'
                    },
                    {
                        label: "D",
                        data: [this.optionD],
                        backgroundColor: 'red'
                    }
                ]
            },
            options: {
                aspectRatio:2.0,
            }
        });

        var chartExist1 = Chart.getChart("pieChart"); // <canvas> id
        if (chartExist1 != undefined){
            this.pieCharts.destroy();
            // this.charts.data = []
        }
        this.pieCharts = new Chart("pieChart", {
            type: 'pie',

            data: {// values on X-Axis
                labels: ['Right Answers', 'Wrong Answers'],
                datasets: [{
                    label: 'Count',
                    data: [this.rightAnswers, this.wrongAnswers],
                    backgroundColor: [
                        '#20e020',
                        '#ee2323',
                    ],
                    hoverOffset: 4
                }],
            },
            options: {
                aspectRatio:2.5,
            }
        });

        var chartExist2 = Chart.getChart("pieChartOption"); // <canvas> id
        if (chartExist2 != undefined){
            this.optionsChart.destroy();
            // this.charts.data = []
        }
        this.optionsChart = new Chart("pieChartOption", {
            type: 'pie',

            data: {// values on X-Axis
                labels: ['A', 'B', 'C', 'D'],
                datasets: [{
                    label: 'Count',
                    data: [this.optionA, this.optionB, this.optionC, this.optionD],
                    backgroundColor: [
                        '#ff8044',
                        '#45ffbb',
                        '#4eddf6',
                        '#af7fff',
                    ],
                    hoverOffset: 4
                }],
            },
            options: {
                aspectRatio:2.5,
            }
        });
    }


    getAnswersDetails() {
        this.attendeesAnswer = [];
        return this.appService.getAttendeesAnswer(this.sessionId)
            .pipe(takeUntil(this.destroy$))
            .subscribe(data => {
                console.log("attendees answer :", data)
                const questionData = data.filter(qus => qus.questionId == Number(localStorage.getItem('questionId')))
                console.log("filter Data :", questionData)
                /*const currentTime = new Date().getTime();
                const halfAnHourBefore = currentTime - 1800000;*/
                const correctAns: any[] = [];
                const optionA: any[] = [];
                const optionB: any[] = [];
                const optionC: any[] = [];
                const optionD: any[] = [];
                const wrongAns: any[] = [];
                for (let ans of questionData) {
                    this.attendeesAnswer.push(ans)
                    if (ans.isCorrect) {
                        correctAns.push(ans)
                    }
                    if (!ans.isCorrect) {
                        wrongAns.push(ans)
                    }
                    if (ans.audienceOption == "A") {
                        optionA.push(ans)
                    }
                    if (ans.audienceOption == "B") {
                        optionB.push(ans)
                    }
                    if (ans.audienceOption == "C") {
                        optionC.push(ans)
                    }
                    if (ans.audienceOption == "D") {
                        optionD.push(ans)
                    }
                }
                this.rightAnswers = correctAns.length;
                this.wrongAnswers = wrongAns.length;
                this.optionA = optionA.length;
                this.optionB = optionB.length;
                this.optionC = optionC.length;
                this.optionD = optionD.length;

                this.answersCount = this.attendeesAnswer.length;
                console.log("right answer :", this.rightAnswers)
                console.log("wrong answer :", this.wrongAnswers)
                console.log("A :", this.optionA)
                console.log("B :", this.optionB)
                console.log("C :", this.optionC)
                console.log("D :", this.optionD)
                console.log("questions answer :", this.attendeesAnswer.length)
                this.createChart();
            }, (err: any) => {
                console.log("something went wrong :", err)
            })
    }

    getRegisteredDetails() {
        return this.appService.getRegisteredDetails(Number(localStorage.getItem('sessionId')))
            .pipe(takeUntil(this.destroy$))
            .subscribe(data => {
                console.log("registered people :", data)
                const currentTime = new Date().getTime()
                const onHourBeforeTime = currentTime - 1800000;
                const filterValues = data.filter(time => time.createdAt <= currentTime && time.createdAt >= onHourBeforeTime)
                console.log("filtered data :", filterValues)
                this.audienceDetails = filterValues;
                this.audienceCount = this.audienceDetails.length;
            }, (error: any) => {
                console.log("something went wrong :", error)
            })
    }

    picShowQuestions(data: InteractQuestions) {
        this.questionsData = data;
        this.picQuestionsShow = true;
        this.appService.getFile(String(data.questionSeq), 'option1')
            .pipe(takeUntil(this.destroy$))
            .subscribe((blob) => {
                console.log("option 1 image :", blob);
                const reader = new FileReader();
                reader.onloadend = () => {
                    this.option1Image = reader.result;
                };
                reader.readAsDataURL(blob);
            }, (err: any) => {
                console.log("something went wrong :", err);
            });
        this.appService.getFile(String(data.questionSeq), 'option2')
            .pipe(takeUntil(this.destroy$))
            .subscribe((blob) => {
                console.log("option 1 image :", blob);
                const reader = new FileReader();
                reader.onloadend = () => {
                    this.option2Image = reader.result;
                };
                reader.readAsDataURL(blob);
            }, (err: any) => {
                console.log("something went wrong :", err);
            });
        if (data.option3 != "") {
            this.option3 = true;
            this.appService.getFile(String(data.questionSeq), 'option3')
                .pipe(takeUntil(this.destroy$))
                .subscribe((blob) => {
                    console.log("option 1 image :", blob);
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        this.option3Image = reader.result;
                    };
                    reader.readAsDataURL(blob);
                }, (err: any) => {
                    console.log("something went wrong :", err);
                });
        } else {
            this.option3 = false;
        }
        if (data.option4 != "") {
            this.option4 = true;
            this.appService.getFile(String(data.questionSeq), 'option4')
                .pipe(takeUntil(this.destroy$))
                .subscribe((blob) => {
                    console.log("option 1 image :", blob);
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        this.option4Image = reader.result;
                    };
                    reader.readAsDataURL(blob);
                }, (err: any) => {
                    console.log("something went wrong :", err);
                });
        } else {
            this.option4 = false;
        }
    }

}